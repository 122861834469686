import React from "react";

export default class ReturnList extends React.Component {

    render() {
        return (
            <div>
                <table className="table lgtrn-table">
                    <thead>
                        <tr>
                            <th scope="col" className="pl-0 pb-0">Заказ Но.</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.props.data.map((order, index) => {
                                let colorClass = ''
                                if (this.props.colored) {
                                    colorClass = ' lgtrn-color-success'
                                }
                                if (this.props.colored && (order['Статус разгрузки'] === 'DeliveredPartly' || order['Статус разгрузки'] === 'ReceivedPartly')) {
                                    colorClass = ' lgtrn-color-warning'
                                }
                                if (this.props.colored && (order['Статус разгрузки'] === 'DeliveredAct' || order['Статус разгрузки'] === 'ReceivedAct')) {
                                    colorClass = ' lgtrn-color-warning'
                                }
                                if (this.props.colored && (order['Статус разгрузки'] === 'DeliveredReturn' || order['Статус разгрузки'] === 'Received')) {
                                    colorClass = ' lgtrn-color-danger'
                                }
                                return <tr key={index}>
                                    <td className={'font-weight-bold font-size-14' + colorClass}>
                                        {order['Заказ']}
                                        {this.props.colored && (order['Статус разгрузки'] === 'DeliveredReturn' || order['Статус разгрузки'] === 'Received') &&
                                            <img src="./img/return.svg" alt="return" className="img-fluid ml-2" />
                                        }
                                    </td>
                                </tr>
                            })
                        }
                        
                    </tbody>
                </table>
            </div>
        )
    }
}
