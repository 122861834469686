import React from "react";

import WhiteBox from '../design/WhiteBox'
import WhiteFill from '../design/WhiteFill'
import Attention from '../elements/Attention'
import CallPhone from '../elements/CallPhone'
import JobHeader from '../elements/JobHeader'
import PreviewWindow from './PreviewWindow'
import ReturnReasonsDrawyer from '../drawyers/ReturnReasonsDrawyer'
import UploadWindow from './UploadWindow'
import * as JobStub from '../../utils/JobStub'
import * as CommonUtils from '../../utils/CommonUtils'

export default class DivergenceWindow extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedFiles: null,
            uploadedFiles: this.getUploadedFiles(),
            uploadWindowOpen: false,
            previewFile: null,
            partlyOrders: this.getDefaultPartlyOrders(),
            returnOrders: this.getDefaultReturnOrders(),
            actOrders: this.getDefaultActOrders(),
            detailOrders: this.getDefaultDetailOrders(),
            returnReasons: {full: [], part: []},
            returnReasonsDrawerOpen: false,
            returnOrderNumber: '',
            returnOrderType: '',
        }
    }

    componentDidMount = () => {
        new Promise((resolve) => {
            if (JobStub.isStubJob(CommonUtils.getJobGUID())) {
                return resolve(JobStub.fetchReturnReasons())
            } else {
                return resolve(CommonUtils.callSource('api/util/return-reasons'))
            }
        })
        .then((reasons) => {
            reasons = this.prepareReturnReasons(reasons)
            this.setState({
                returnReasons: reasons
            })
        })
    }

    getTip = () => {
        return JobStub.isStubJob() ? 'Недоступно для демоверсии' : null
    }

    onAddClick = (e) => {
        if (JobStub.isStubJob()) {
            e.preventDefault()
        }
    }

    returnReasonsDrawerToggle = (number, type) => {
        this.setState({
            returnOrderNumber: number,
            returnOrderType: type,
            returnReasonsDrawerOpen: !this.state.returnReasonsDrawerOpen
        })
    }

    prepareReturnReasons(returnReasons) {
        let ret = {full: [], part: []}
        returnReasons.forEach(reason => {
            if (reason['Возврат'] === 'Полный') {
                ret.full.push(reason)
            }
            if (reason['Возврат'] === 'Частичный') {
                ret.part.push(reason)
            }
        })
        return ret
    }

    getDefaultPartlyOrders = () => {
        let ret = []
        let flags = this.getOrderFlags()
        if (flags) {
            ret = flags['partly']
        }
        return ret
    }

    getDefaultReturnOrders = () => {
        let ret = []
        let flags = this.getOrderFlags()
        if (flags) {
            ret = flags['return']
        }
        return ret
    }

    getDefaultActOrders = () => {
        let ret = []
        let flags = this.getOrderFlags()
        if (flags) {
            ret = flags['act']
        }
        return ret
    }

    getDefaultDetailOrders = () => {
        let ret = {}
        let flags = this.getOrderFlags()
        let ol = this.getOrdersList()
        if (flags) {
            ret = flags['detail']
            if (!ret) {
                ret = {}
                ol.forEach((order) => {
                    ret[order] = null
                })
            }
        } else {
            ol.forEach((order) => {
                ret[order] = null
            })
        }
        return ret
    }

    onCloseClick = (e) => {
        e.preventDefault()
        this.props.toggle()
    }
    
    onFileChange = (e) => {
        this.setState({
            selectedFiles: e.target.files
        }, function () {
            this.uploadFile(e)
        })
    }

    fullFileUrl = (url) => {
        let server_url = '/'
        if (window.location.host === 'localhost:3000') {
            server_url = 'http://localhost:8080/'
        }
        return server_url + url
    }

    getUploadedFilesKey = () => {
        return this.props.jobNo + '_' + this.props.data['Получатель Код'] + '_UploadedFiles'
    }

    getUploadedFiles = () => {
        let storage = window.sessionStorage
        let files = storage.getItem(this.getUploadedFilesKey()) || false
        if (files) {
            return JSON.parse(files)
        }
        return []
    }

    setUploadedFiles = (files) => {
        let storage = window.sessionStorage
        if (files) {
            storage.setItem(this.getUploadedFilesKey(), JSON.stringify(files))
        } else {
            storage.removeItem(this.getUploadedFilesKey())
        }
    }

    getOrderFlagsKey = () => {
        return this.props.jobNo + '_' + this.props.data['Получатель Код'] + '_OrderFlags'
    }

    getOrderFlags = () => {
        let storage = window.sessionStorage
        let flags = storage.getItem(this.getOrderFlagsKey()) || false
        if (flags) {
            return JSON.parse(flags)
        }
        return null
    }

    setOrderFlags = (flags) => {
        let storage = window.sessionStorage
        if (flags) {
            storage.setItem(this.getOrderFlagsKey(), JSON.stringify(flags))
        } else {
            storage.removeItem(this.getOrderFlagsKey())
        }
    }

    uploadFile = (e) => {
        e.preventDefault()
        const formData = new FormData()
        for (let selectedFile of this.state.selectedFiles) {
            formData.append("uploaded_files", selectedFile)
        }
        
        let job_guid = CommonUtils.getJobGUID()
        CommonUtils.callSource('api/upload/' + job_guid, formData)
        .then((result) => {
            let uploadedFiles = this.state.uploadedFiles
            for (let index in result) {
                uploadedFiles.push(result[index])
            }
            this.setState({uploadedFiles: uploadedFiles})
            this.setUploadedFiles(uploadedFiles)
        })
    }

    uploadWindowShow = (e) => {
        this.setState({uploadWindowOpen: true})
    }

    uploadWindowHide = (e) => {
        this.setState({uploadWindowOpen: false}, () => {
            this.props.toggle()
            this.setUploadedFiles(false)
        });
    }

    previewWindowShow = (e) => {
        let index = e.currentTarget.dataset.key
        let previewFile = this.state.uploadedFiles[index]
        previewFile.index = index
        this.setState({previewFile: previewFile})
    }

    previewWindowHide = () => {
        this.setState({previewFile : null})
    }

    deleteUploadedFile = (index) => {
        let uploadedFiles = this.state.uploadedFiles
        uploadedFiles.splice(index, 1)
        this.setState({uploadedFiles: uploadedFiles})
        this.setUploadedFiles(uploadedFiles)
    }

    deleteAllFiles = (e) => {
        e.preventDefault()
        this.setState({uploadedFiles: []})
        this.setUploadedFiles(false)
    }

    returnReasonsAdd = (orderNumber, orderType, places, items, reasonCode) => {
        let partlyOrders = this.state.partlyOrders
        let returnOrders = this.state.returnOrders
        let actOrders = this.state.actOrders
        let detailOrders = this.state.detailOrders
        if (orderType === 'part' || orderType === 'part_return') {
            partlyOrders.push(orderNumber)
            let index2 = returnOrders.indexOf(orderNumber)
            if (index2 > -1) {
                returnOrders.splice(index2, 1)
            }
            let index3 = actOrders.indexOf(orderNumber)
            if (index3 > -1) {
                actOrders.splice(index3, 1)
            }
        } else {
            returnOrders.push(orderNumber)
            let index2 = partlyOrders.indexOf(orderNumber)
            if (index2 > -1) {
                partlyOrders.splice(index2, 1)
            }
            let index3 = actOrders.indexOf(orderNumber)
            if (index3 > -1) {
                actOrders.splice(index3, 1)
            }
        }
        detailOrders[orderNumber] = {
            orderType: orderType,
            places: places,
            items: items,
            reasonCode: reasonCode,
        }
        this.setState({returnOrders: returnOrders, partlyOrders: partlyOrders, actOrders: actOrders, detailOrders: detailOrders})
    }

    onPartlyChange = (e, needReason) => {
        let partlyOrders = this.state.partlyOrders
        let returnOrders = this.state.returnOrders
        let actOrders = this.state.actOrders
        let detailOrders = this.state.detailOrders
        let index = partlyOrders.indexOf(e.target.value)
        if (e.target.checked) {
            if (needReason) {
                this.returnReasonsDrawerToggle(e.target.value, 'part')
            } else {
                this.returnReasonsDrawerToggle(e.target.value, 'part_return')
            }
        } else {
            if (index > -1) {
                partlyOrders.splice(index, 1)
            }
            detailOrders[e.target.value] = null
        }
        this.setState({returnOrders: returnOrders, partlyOrders: partlyOrders, actOrders: actOrders, detailOrders: detailOrders})
    }

    onReturnChange = (e, needReason) => {
        let returnOrders = this.state.returnOrders
        let partlyOrders = this.state.partlyOrders
        let actOrders = this.state.actOrders
        let detailOrders = this.state.detailOrders
        let index = returnOrders.indexOf(e.target.value)
        if (e.target.checked) {
            if (needReason) {
                this.returnReasonsDrawerToggle(e.target.value, 'full')
            } else {
                if (index === -1) {
                    returnOrders.push(e.target.value)
                }
                let index2 = partlyOrders.indexOf(e.target.value)
                if (index2 > -1) {
                    partlyOrders.splice(index2, 1)
                }
                let index3 = actOrders.indexOf(e.target.value)
                if (index3 > -1) {
                    actOrders.splice(index3, 1)
                }
            }
        } else {
            if (index > -1) {
                returnOrders.splice(index, 1)
            }
            detailOrders[e.target.value] = null
        }
        this.setState({returnOrders: returnOrders, partlyOrders: partlyOrders, actOrders: actOrders, detailOrders: detailOrders})
    }

    onActChange = (e) => {
        let returnOrders = this.state.returnOrders
        let partlyOrders = this.state.partlyOrders
        let actOrders = this.state.actOrders
        let detailOrders = this.state.detailOrders
        let index = actOrders.indexOf(e.target.value)
        if (e.target.checked) {
            if (index === -1) {
                actOrders.push(e.target.value)
            }
            let index2 = partlyOrders.indexOf(e.target.value)
            if (index2 > -1) {
                partlyOrders.splice(index2, 1)
            }
            let index3 = returnOrders.indexOf(e.target.value)
            if (index3 > -1) {
                returnOrders.splice(index3, 1)
            }
        } else {
            if (index > -1) {
                actOrders.splice(index, 1)
            }
            detailOrders[e.target.value] = null
        }
        this.setState({returnOrders: returnOrders, partlyOrders: partlyOrders, actOrders: actOrders, detailOrders: detailOrders})
    }

    partlyChecked = (order) => {
        return (this.state.partlyOrders.indexOf(order) > -1)
    }

    returnChecked = (order) => {
        return (this.state.returnOrders.indexOf(order) > -1)
    }

    actChecked = (order) => {
        return (this.state.actOrders.indexOf(order) > -1)
    }

    noOrderChecked = () => {
        return (this.state.partlyOrders.length === 0 && this.state.returnOrders.length === 0 && this.state.actOrders.length === 0)
    }

    getOrdersList = () => {
        let ordersList = []
        this.props.data.ext.forEach((ext) => {
            ordersList.push(ext['Заказ'])
        })
        return ordersList
    }

    delivered = (e) => {
        e.preventDefault()
        let fullOrders = this.getOrdersList()
        this.state.partlyOrders.forEach(order => {
            let index = fullOrders.indexOf(order)
            if (index > -1) {
                fullOrders.splice(index, 1)
            }
        })
        this.state.returnOrders.forEach(order => {
            let index = fullOrders.indexOf(order)
            if (index > -1) {
                fullOrders.splice(index, 1)
            }
        })
        this.state.actOrders.forEach(order => {
            let index = fullOrders.indexOf(order)
            if (index > -1) {
                fullOrders.splice(index, 1)
            }
        })
        let orders = {
            'full': fullOrders,
            'partly': this.state.partlyOrders,
            'return': this.state.returnOrders,
            'act': this.state.actOrders,
            'detail': this.state.detailOrders,
        }
        this.setOrderFlags(orders)
        let images = this.state.uploadedFiles.slice()
        
        this.props.deliveredPart(
            this.props.data['Пункт погрузки'], 
            this.props.data['Номер по сдаче'], 
            this.props.data['Получатель Код'], 
            this.props.data['Тип Строки'],
            this.props.planningFinished,
            orders,
            images,
            (images.length ? this.uploadWindowShow : null)
        )
    }

    render() {
        let supportMail = this.props.supportMail
        let oDContart = this.props.oDContart
        if (this.state.previewFile) {
            return  <PreviewWindow
                src={this.fullFileUrl(this.state.previewFile.url)} 
                title={this.state.previewFile.filename} 
                index={this.state.previewFile.index}
                toggle={this.previewWindowHide}
                delete={this.deleteUploadedFile}
                imqStatus={this.props.imqStatus}
            />
        }
        if (this.state.uploadWindowOpen) {
            return <UploadWindow 
                data={this.props.data}
                statusData={this.props.statusData}
                supportMail={supportMail}
                oDContart={oDContart}
                toggle={this.uploadWindowHide}
                uploadedFiles={this.state.uploadedFiles}
            />
        }
        let subTitle = 'Получатель '
        let thPartly = 'Частичный возврат'
        let thReturn = 'Полный возврат'
        let thAct = 'Акт'
        let btnText = 'Запросить подтверждение'
        if (this.props.data['Тип Строки'] === 'Возврат') {
            subTitle = 'Получение возврата '
            thPartly = 'Принят частично'
            thReturn = 'Не принят'
            thAct = 'Акт'
        }
        subTitle += this.props.data['Получатель'];
        return (
            <div>
                <div className="d-flex flex-column lgtrn-vh-100">
                    <div className="flex-grow-1 d-flex flex-column">
                        <JobHeader title={this.props.statusData['Номер Рейса']} supportMail={supportMail} />
                        <WhiteBox>
                            <div className="row mt-3">
                                <div className="col">
                                    <div className="back-panel lgtrn-text-danger" onClick={this.onCloseClick}>
                                        <span>Назад</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <h3 className="panel-header mb-0">Расхождения в заказе</h3>
                                </div>
                            </div>
                        </WhiteBox>

                        <WhiteBox>
                            <div className="font-weight-bold font-size-13 mb-3">{subTitle}</div>
                        </WhiteBox>
                        <Attention noBorder={true}>
                            <span>{this.props.statusData['Предупреждение']}</span>
                        </Attention>

                        <WhiteBox>
                            <div className="card lgtrn-card mb-3">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="font-size-11 mb-2 col-8">Добавьте фото документа/груза</div>
                                        {this.state.uploadedFiles.length > 0 &&
                                            <div className="font-size-11 mb-2 col pl-0 text-right"><a className="color-red" href="/#" onClick={this.deleteAllFiles}>Удалить все</a></div>
                                        }
                                    </div>
                                    <div>
                                        <div className="addfile-button">
                                            <img src="./img/add-file.svg" alt="add" />
                                            <input type="file" outocomplete="off" name="file" onChange={this.onFileChange} data-tip={this.getTip()} onClick={this.onAddClick} multiple />
                                        </div>
                                        <div className="d-flex uploaded-tape">
                                            {
                                                this.state.uploadedFiles.map((file, index) =>
                                                    <div className="uploaded-image" data-key={index} key={index} onClick={this.previewWindowShow} style={{"backgroundImage": 'url(' + this.fullFileUrl(file.url) + ')'}}>
                                                        <img alt="preview" src="./img/view-file.svg" className="view-file-icon" />
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </WhiteBox>
                        
                        <WhiteBox>
                            <table className="table lgtrn-table mb-3">
                                <thead>
                                    <tr>
                                        <th scope="col" className="pl-0 pb-0 font-size-11 lgtrn-order-header">№ заказа</th>
                                        <th scope="col" className="pb-0 font-size-11 w27">{thAct}</th>
                                        <th scope="col" className="pb-0 font-size-11 w27">{thPartly}</th>
                                        <th scope="col" className="pb-0 font-size-11 w27">{thReturn}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.getOrdersList().map((order, index) =>
                                            <tr key={index}>
                                                <td className="font-weight-bold font-size-14 pl-0">
                                                    {order}
                                                </td>
                                                <td>
                                                    <label className="checkbox-container">
                                                        <input type="checkbox" value={order} id={'order_act' + index} onChange={(e) => {this.onActChange(e)}} checked={this.actChecked(order)} />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </td>
                                                <td>
                                                    <label className="checkbox-container">
                                                        <input type="checkbox" value={order} id={'order_partly' + index} onChange={(e) => {this.onPartlyChange(e, this.props.data['Тип Строки'] !== 'Возврат')}} checked={this.partlyChecked(order)} />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </td>
                                                <td>
                                                    <label className="checkbox-container">
                                                        <input type="checkbox" value={order} id={'order_return' + index} onChange={(e) => {this.onReturnChange(e, this.props.data['Тип Строки'] !== 'Возврат')}} checked={this.returnChecked(order)} />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </td>
                                            </tr>
                                        )
                                    }
                                    
                                </tbody>
                            </table>
                        </WhiteBox>

                        <WhiteFill />
                    </div>
                    <WhiteBox>
                        <div className="mb-3">
                            <button className="btn lgtrn-btn-danger btn-block text-uppercase lgtrn-btn" disabled={this.props.imqStatus !== null || this.noOrderChecked()} onClick={this.delivered}>{btnText}</button>
                        </div>
                    </WhiteBox>
                    <CallPhone oDContart={oDContart} />
                </div>

                <ReturnReasonsDrawyer
                    show={this.state.returnReasonsDrawerOpen} 
                    toggle={this.returnReasonsDrawerToggle}
                    orderNumber={this.state.returnOrderNumber}
                    orderType={this.state.returnOrderType}
                    returnReasons={this.state.returnReasons}
                    add={this.returnReasonsAdd}
                />
            </div>
        )
    }
}