import * as JobStub from '../utils/JobStub'
import * as CommonUtils from '../utils/CommonUtils'

function prepareDataReturn(ret, statusData, extData) {
    for (let key in statusData) {
        if (statusData.hasOwnProperty(key)) {
            ret[key] = statusData[key]
        }
    }
    if (extData) {
        extData.forEach(function (dt) {
            dt.ext = []
            ret.clients[dt['Получатель Код']] = dt
        })
        extData.forEach(function (ext) {
            let val = JSON.parse(JSON.stringify(ext))
            ret.clients[ext['Получатель Код']].ext.push(val)
        })
    }
}

function prepareDataCommon(ret, statusData, extData, extData2) {
    for (let key in statusData[0]) {
        if (statusData[0].hasOwnProperty(key)) {
            ret[key] = statusData[0][key]
        }
    }
    statusData.forEach(function (client) {
        client.ext = []
        ret.clients[client['Получатель Код'] + '_' + client['Тип Строки']] = client

        if (client['Предупреждение']) {
            if (!ret['Предупреждение']) {
                ret['Предупреждение'] = client['Предупреждение']
            } else if (ret['Предупреждение'] !== client['Предупреждение']) {
                ret['Предупреждение2'] = client['Предупреждение']
            }
        }
    })
    if (extData) {
        extData.forEach(function (ext) {
            let idSuffix = ext['Тип Строки'] ? ext['Тип Строки'] : 'Клиент'
            ret.clients[ext['Получатель Код'] + '_' + idSuffix].ext.push(ext)
        })
    }
    if (extData2) {
        extData2.forEach(function (ext) {
            ret.clients[ext['Получатель Код'] + '_' + ext['Тип Строки']].ext.push(ext)
        })
    }
}

export function prepareData(statusData, extData, extData2, jobStatus) {
    if (!Array.isArray(statusData) && jobStatus !== 'CargoReturn' && jobStatus !== 'ConfirmReturn' && jobStatus !== 'Completed') {
        return statusData
    }
    if (jobStatus === 'Completed' && extData) {
        statusData.ext = extData
        return statusData
    }
    let ret = {}
    ret.clients = {}
    if (jobStatus === 'CargoReturn' || jobStatus === 'ConfirmReturn') {
        prepareDataReturn(ret, statusData, extData)
    } else {
        prepareDataCommon(ret, statusData, extData, extData2)
    }
    return ret
}

export function fetchStatus(home) {
    return new Promise(resolve => {
        let job_guid = CommonUtils.getJobGUID()
        CommonUtils.callSource('api/stage/status', {
            job_guid: job_guid
        }, home)
        .then((result) => {
            let actual_response = JSON.stringify(result);
            home.setState({
                jobStatus: result.data.jobStatus,
                jobNo: result.data.jobNo,
                imqStatus: result.data.imqStatus,
                planningFinished: result.data.planningFinished,
                oDContart: result.data.oDContart || home.state.oDContart,
                supportMail: result.data.supportMail,
                statusData: prepareData(result.data.statusData, result.data.extData, result.data.extData2, result.data.jobStatus),
                statusDataError:  result.data.statusDataError || false,
            })

            let storage = window.sessionStorage
            storage.setItem('actual_guid', job_guid)
            storage.setItem('actual_jobNo', result.data.jobNo)
            storage.setItem('actual_response', actual_response)
            if (result.data.jobNo) {
                document.title = result.data.jobNo + ' - Driver Logitern'
            }

            let storage_webpack_hash = storage.getItem('actual_webpack_hash')
            if (storage_webpack_hash && result.data.webpack_hash && storage_webpack_hash !== result.data.webpack_hash) {
                if (['test', 'prod'].includes(process.env.APP_ENV)) {
                    alert('Вышла новая версия сайта. Необходимо обновиться для корректной работы.')
                    window.location.reload()
                }
                storage.setItem('actual_webpack_hash', result.data.webpack_hash)
            } else if (!storage_webpack_hash) {
                storage.setItem('actual_webpack_hash', result.data.webpack_hash)
            }
            resolve()
        })
    })
}

export function fetchStubStatus(home, onHintDrawyerHide) {
    let result = JobStub.fetchStatus(home, onHintDrawyerHide)
    home.setState({
        jobStatus: result.data.jobStatus,
        jobNo: result.data.jobNo,
        imqStatus: result.data.imqStatus,
        statusData: prepareData(result.data.statusData, result.data.extData, result.data.extData2, result.data.jobStatus),
        isLoaded: true,
    })
}