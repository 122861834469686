export function clearPhone(phone) {
    const allowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '+', ','];
    let ret = '';

    for (let char of phone) {
        if (allowed.includes(char)) {
            ret += char;
        }
    }

    if (ret.substring(0, 1) === '8') {
        ret = '+7' + ret.substring(1);
    }

    return ret;
}

export function clearAddPhone(addPhone) {
    return addPhone.replace(/[дД][оО][бБ]\.?/gi, '').trim();
}

export function formatPhone(phone) {
    const separator = ' Доб. ';
    const [base, add] = phone.split(separator);
    const clearedBase = clearPhone(base.trim());
    const clearedAdd = add ? clearAddPhone(add) : null;
    const full = base + separator + clearedAdd;

    return [full, base, clearedBase, clearedAdd];
}

/**
 * @param string phonesStr 
 * @returns string[][] массив кортежей из полного номера, основного, очищенного основного и добавочного 
 */
export function formatPhonesString(phonesStr) {
    const separator = ', ';

    const separated = phonesStr
        .split(separator)
        .map(el => el.trim());

    for (let i = 1; i < separated.length; i++) {
        const value = separated[i];

        if (!value) {
            separated.splice(i, 1);
            i--;
        } else {
            const isAddNumber = /^\d{1,6}$/gi.test(value);
    
            if (isAddNumber) {
                separated.splice(i, 1);
                separated[i - 1] += ', ' + value;
                i--;
            }
        }
    }

    return separated.map(el => formatPhone(el));
}
