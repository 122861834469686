import React from "react";

import * as HintMessages from '../../utils/HintMessages'

export default class HintDrawyer extends React.Component {

    onCloseClick = (e) => {
        e.preventDefault()
        HintMessages.hintDrawyerToggle(this.props.home)
    }

    render() {
        let drawyerClasses = 'side-drawer container'
        if (this.props.home.state.hintDrawyerText) {
            drawyerClasses = drawyerClasses + ' open'
        }
        let backdrop = ''
        if (this.props.home.state.hintDrawyerText) {
            backdrop = <div className="backdrop bg-transparent" onClick={this.onCloseClick} />
        }

        return (
            <div>
                {backdrop}
                <div className={drawyerClasses}><div className="row"><div className="mt-5 mb-80-px col-12 offset-0 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
                    <div className="arrow-down border-radius-top-20 border-radius-bottom-20 bg-white hint-shadow-purple color-purple font-size-15">
                        <div className="arrow down"></div>
                        {this.props.title && 
                            <div className="row mb-3 mt-3">
                                <div className="col-10">
                                    <h3 className="panel-header mb-0">{this.props.title}</h3>
                                </div>
                                <div className="col-2 text-right">
                                    <a href="/#" className="close-panel" onClick={this.onCloseClick}>&times;</a>
                                </div>
                            </div>
                        }
                        <div className="row">
                            <div className="col text-center">
                                <div className="py-4 px-2 cursor-pointer" onClick={this.onCloseClick}>
                                    {this.props.home.state.hintDrawyerText}
                                </div>
                            </div>
                        </div>
                    </div>
                </div></div></div>
            </div>
        )
    }
}