import React from "react";

import WhiteBox from '../design/WhiteBox'

export default class JobInfo extends React.Component {
    
    renderContent = () => {
        let titleBlock = ''
        let smallSpaceTitle = ' mb-3'
        if (this.props.smallSpaceTitle) {
            smallSpaceTitle = ' mb-1'
        }
        if (this.props.title) {
            titleBlock = (
                <div className={'row ' + smallSpaceTitle }><div className="col">
                    <span className={'lgtrn-status' + (this.props.smallTitle ? ' font-size-13' : '')}>{this.props.title}</span>
                </div></div>
            )
        }
        let label1Width = 3
        if (this.props.label1Width) {
            label1Width = this.props.label1Width
        }
        let label2Width = 3
        if (this.props.label2Width) {
            label2Width = this.props.label2Width
        }
        let spaceAfter = ' mb-2'
        if (this.props.spaceAfter) {
            spaceAfter = ' mb-4'
        }
        let fontBold = ''
        if (this.props.fontBold) {
            fontBold = ' font-weight-bold'
        }

        return (
            <>
                {titleBlock}

                <div className="container-fluid">
                    <div className={'row'+spaceAfter}>
                        <div className="col pr-1">
                            <div className="row border-bottom mr-1 mb-1">
                                <div className={'lgtrn-info-label-1 pl-0 pr-1 col-' + label1Width}>{this.props.label1}</div>
                                <div className={'lgtrn-info-text-1 text-right pt-1 pb-1 pl-1 pr-1 col-' + (12-label1Width) + fontBold}>{this.props.value1}</div>
                            </div>
                        </div>
                        <div className="col pl-1">
                        <div className="row border-bottom ml-1 mb-1">
                                <div className={'lgtrn-info-label-1 pl-0 pr-1 col-' + label2Width}>{this.props.label2}</div>
                                <div className={'lgtrn-info-text-1 text-right pt-1 pb-1 pl-1 pr-1 col-' + (12-label2Width) + fontBold}>{this.props.value2}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    renderWhiteBox = () => {
        return (
            <WhiteBox>
                {this.renderContent()}
            </WhiteBox>
        )
    }

    render() {
        return (
            <>
                {(this.props.noWhiteBox) ? this.renderContent() : this.renderWhiteBox()}
            </>
        )
    }
}
