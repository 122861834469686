import React from "react";
import './App.css';
import ErrorBoundary from "./components/ErrorBoundary";
import Home from './components/Home'

export default class App extends React.Component {

    calculateVH = () => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    componentDidMount = () => {
        this.calculateVH()
        window.addEventListener('resize', this.calculateVH, true)
    }

    render() {
        return (
            <div className="App">
                <ErrorBoundary>
                    <Home />
                </ErrorBoundary>
            </div>
        )
    }
}
