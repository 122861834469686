import React from "react";

import WhiteBox from '../design/WhiteBox'
import WhiteFill from '../design/WhiteFill'
import CallPhone from '../elements/CallPhone'
import JobHeader from '../elements/JobHeader'
import CargoList from '../elements/CargoList'
import EmployeeList from '../elements/EmployeeList'
import DivergenceWindow from './DivergenceWindow'
import * as JobStub from '../../utils/JobStub'
import * as CommonUtils from '../../utils/CommonUtils'
import * as ServerMessages from '../../utils/ServerMessages'
import * as StatusFetching from '../../utils/StatusFetching'
import * as HintMessages from '../../utils/HintMessages'

export default class CargoWindow extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            divergenceWindowOpen: false,
        }
    }

    divergenceWindowShow = () => {
        this.setState({
            divergenceWindowOpen: true,
        }, () => {
            if (JobStub.isStubJob()) {
                let message = 'DivergenceWindow'
                if (JobStub.getStage() === 'Stage6_3_3') {
                    message = 'DivergenceWindow3'
                }
                HintMessages.hintDrawyerToggle(this.props.home, JobStub.getHints(message), JobStub.hintTimeout)
            }
        })
        this.setDivergenceWindowOpen(true)
    }

    divergenceWindowHide = () => {
        this.setState({
            divergenceWindowOpen: false,
        })
        this.setDivergenceWindowOpen(false)
    }

    deliveredFull = () => {
        if (JobStub.isStubJob()) {
            JobStub.callStubSource('/stage6/delivered-full')
            .then(() => {
                StatusFetching.fetchStubStatus(this.props.home)
            })
            return
        }

        if (this.props.planningFinished === 0) {
            let text = <span>Внимание!<br />В рейсе необходимо установить отметку &laquo;Планирование завершено&raquo;.<br />Свяжитесь с диспетчером.</span>
            ServerMessages.messageDrawerToggle(this.props.home, text)
            return
        }

        CommonUtils.callSource('api/stage/stage6/delivered-full', {
            job_guid: this.props.jobGUID,
            job_no: this.props.jobNo,
            point: this.props.data['Пункт погрузки'],
            order:  this.props.data['Номер по сдаче'],
            code: this.props.data['Получатель Код'],
            type: this.props.data['Тип Строки'],
        }, this.props.home)
        .then(() => {
            StatusFetching.fetchStatus(this.props.home)
        })
    }

    getDivergenceWindowKey = () => {
        return this.props.jobNo + '_' + this.props.data['Получатель Код'] + '_DivergenceWindowOpen'
    }

    getDivergenceWindowOpen = () => {
        let storage = window.sessionStorage
        return storage.getItem(this.getDivergenceWindowKey()) || false
    }

    setDivergenceWindowOpen = (flag) => {
        let storage = window.sessionStorage
        if (flag) {
            storage.setItem(this.getDivergenceWindowKey(), flag)
        } else {
            storage.removeItem(this.getDivergenceWindowKey())
        }
    }

    rejectClient = () => {
        if (JobStub.isStubJob()) {
            JobStub.callStubSource('/stage6/reject')
            .then(() => {
                StatusFetching.fetchStubStatus(this.props.home)
            })
            return
        }

        CommonUtils.callSource('api/stage/stage6/reject', {
            job_guid: this.props.jobGUID,
            job_no: this.props.jobNo,
            point: this.props.data['Пункт погрузки'], 
            order: this.props.data['Номер по сдаче'], 
            code: this.props.data['Получатель Код'],
            type: this.props.data['Тип Строки'],
        }, this.props.home)
        .then(() => {
            StatusFetching.fetchStatus(this.props.home)
        })
    }

    render() {
        let supportMail = this.props.supportMail
        let oDContart = this.props.oDContart
        if (this.getDivergenceWindowOpen()) {
            return (
                <DivergenceWindow 
                    toggle={this.divergenceWindowHide} 
                    statusData={this.props.statusData}
                    data={this.props.data}
                    jobNo={this.props.jobNo}
                    orders = {this.state.orders}
                    imqStatus={this.props.imqStatus}
                    supportMail={supportMail}
                    oDContart={oDContart}
                    deliveredPart={this.props.deliveredPart}
                    planningFinished={this.props.planningFinished}
                />
            )
        }
        let title = 'Сдача груза'
        let subTitle = 'Получатель '
        let actionText = 'сдан'
        let backText = 'Назад'
        if(this.props.data['Тип Строки'] === 'Возврат') {
            title = 'Приемка груза'
            subTitle = 'Получение возврата '
            actionText = 'принят'
        }
        subTitle += this.props.data['Получатель'];
        return (
            <div>
                <div className="d-flex flex-column lgtrn-vh-100">
                    <div className="flex-grow-1 d-flex flex-column">
                        <JobHeader title={this.props.statusData['Номер Рейса']} supportMail={supportMail} />
                        <WhiteBox>
                            <div className="row mt-3">
                                <div className="col">
                                    <div className="back-panel lgtrn-text-danger" onClick={this.rejectClient}>
                                        <span>{backText}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-3 mt-3">
                                <div className="col">
                                    <h3 className="panel-header mb-0">{title}</h3>
                                </div>
                            </div>
                        </WhiteBox>

                        <WhiteBox>
                            <div className="font-weight-bold font-size-13 mb-3">{subTitle}</div>
                        </WhiteBox>
                        
                        {this.props.data['Тип'] === 'Клиент' &&
                            <WhiteBox>
                                <EmployeeList employee={this.props.data['Представитель']} passport={this.props.data['Паспорт']} />            
                            </WhiteBox>
                        }

                        <WhiteBox>
                            <div className="mb-4">
                                <CargoList data={this.props.data['ext']} />
                            </div>
                        </WhiteBox>
                        
                        <WhiteFill />
                    </div>
                    <WhiteBox>
                        <div className="mb-3">
                            <button className="btn lgtrn-btn-success btn-block text-uppercase lgtrn-btn" onClick={this.deliveredFull} disabled={this.props.imqStatus !== null}>Груз {actionText} без расхождений</button>
                            <button className="btn lgtrn-btn-danger btn-block text-uppercase lgtrn-btn" onClick={this.divergenceWindowShow} disabled={this.props.imqStatus !== null}>Груз {actionText} с расхождениями</button>
                        </div>
                    </WhiteBox>
                    <CallPhone oDContart={oDContart} />
                </div>
            </div>
        )
    }
}