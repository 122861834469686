import React from "react";
import * as JobStub from "../../utils/JobStub"
import ReactTooltip from 'react-tooltip'

export default class JobHeader extends React.Component {
    isDesktop = () => {
        return /Mobi/.test(navigator.userAgent) !== true
    }

    getTip = () => {
        return JobStub.isStubJob() ? 'Недоступно для демоверсии' : null
    } 

    onMailClick = (e) => {
        if (JobStub.isStubJob()) {
            e.preventDefault()
        }
    }
    
    render() {
        let supportMail = this.props.supportMail
        let preTitle = 'Рейс'
        let subject = this.props.title
        if (this.props.isNotStage) {
            preTitle = ''
        }
        else {
            subject = 'Ошибка в рейсе ' + subject
        }
        subject = 'driver.logitern (ID 998): ' + subject
        return (
            <>
                <div className="container-fluid lgtrn-bg-danger text-white"><div className="container">
                    <div className="row">
                        <div className="col-12 offset-0 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
                            <img className="lgtrn-logo" src="./img/logo.png" alt="Application logo"/>
                            <span className="lgtrn-header">{preTitle} {this.props.title}</span>
                            <div className="lghtr-header-links">
                                {!this.props.isNotStage &&
                                    <a className="lgtrn-help" target="_blank" href="./files/DriverPortalManual.pdf">
                                        <img src="./img/question.svg" alt="Help"/>
                                    </a>
                                }
                                {this.isDesktop() && 
                                    <a className="lgtrn-email" href={`mailto:${supportMail}?subject=${subject}`} data-tip={this.getTip()} onClick={this.onMailClick}>
                                        <img src="./img/envelope.svg" alt="Mail"/>
                                    </a>
                                }
                            </div>
                        </div>
                    </div>
                </div></div>
                <ReactTooltip effect="solid" />
            </>
        )
    }
}
