import React from "react";

import WhiteSpace from '../design/WhiteSpace'
import WhiteFill from '../design/WhiteFill'
import WhiteBox from '../design/WhiteBox'
import JobHeader from '../elements/JobHeader'
import JobInfo from '../elements/JobInfo'
import JobAddress from '../elements/JobAddress'
import CallPhone from '../elements/CallPhone'
import DeclineDrawyer from '../drawyers/DeclineDrawyer'
import * as JobStub from '../../utils/JobStub'
import * as CommonUtils from '../../utils/CommonUtils'
import * as StatusFetching from '../../utils/StatusFetching'
import * as JobStubActions from '../../utils/JobStubActions'

export default class Stage1 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            declineDrawerOpen: false,
        }
    }

    declineDrawerToggle = () => {
        this.setState({
            declineDrawerOpen: !this.state.declineDrawerOpen
        })
    }

    acceptStage = () => {
        if (JobStub.isStubJob()) {
            JobStub.callStubSource('/stage1/accept')
            .then(() => {
                StatusFetching.fetchStubStatus(this.props.home)
            })
            return
        }

        CommonUtils.callSource('api/stage/stage1/accept', {
            job_guid: this.props.jobGUID,
            job_no: this.props.jobNo,
        }, this.props.home)
        .then(() => {
            StatusFetching.fetchStatus(this.props.home)
        })
    }

    declineStage = (reason) => {
        if (JobStub.isStubJob()) {
            JobStub.callStubSource('/stage1/decline')
            .then(() => {
                StatusFetching.fetchStubStatus(this.props.home, JobStubActions.acceptStage0)
            })
            return
        }

        CommonUtils.callSource('api/stage/stage1/decline', {
            job_guid: this.props.jobGUID,
            job_no: this.props.jobNo,
            reason: reason
        }, this.props.home)
        .then(() => {
            StatusFetching.fetchStatus(this.props.home)
        })
    }

    render() {
        let statusData = this.props.statusData
        let imqStatus = this.props.imqStatus
        let supportMail = this.props.supportMail
        let oDContart = this.props.oDContart
        return <div>
            {this.props.hover}
            <div className="d-flex flex-column lgtrn-vh-100">
                <div className="flex-grow-1 d-flex flex-column">
                    
                    <JobHeader title={statusData['Номер Рейса']} supportMail={supportMail} />
                    <WhiteSpace />
                    <JobInfo 
                        title="Прибытие на погрузку, план" 
                        label1="Дата"
                        label2="Время"
                        value1={statusData['Дата Загрузки']} 
                        value2={statusData['Время Загрузки']} 
                        spaceAfter="true"
                    />
                    <JobAddress title="Адрес" address={statusData['Адрес Загрузки']} />
                    <WhiteFill />

                </div>
                <WhiteBox>
                    <div className="row mb-3"><div className="col text-center">
                        <img src="./img/stage_started.svg" alt="location" className="img-fluid" />
                    </div></div>
                    <div className="row mb-3"><div className="col">
                        <button className="btn lgtrn-btn-success btn-block text-uppercase lgtrn-btn" onClick={this.acceptStage} disabled={imqStatus !== null}>Подтвердить</button>
                        <button className="btn lgtrn-btn-danger btn-block text-uppercase lgtrn-btn lgtrn-btn-lsp-wide" onClick={this.declineDrawerToggle} disabled={imqStatus !== null}>Отклонить</button>
                    </div></div>
                </WhiteBox>
                <CallPhone oDContart={oDContart} />
            </div>
            <DeclineDrawyer 
                show={this.state.declineDrawerOpen} 
                toggle={this.declineDrawerToggle} 
                decline={this.declineStage}
            />
        </div>
    }
}
