import React from "react";

import WhiteSpace from '../design/WhiteSpace'
import WhiteFill from '../design/WhiteFill'
import WhiteBox from '../design/WhiteBox'
import JobHeader from '../elements/JobHeader'
import CallPhone from '../elements/CallPhone'
import * as JobStub from '../../utils/JobStub'
import * as CommonUtils from '../../utils/CommonUtils'
import * as StatusFetching from '../../utils/StatusFetching'

export default class ConfirmReceiveWindow extends React.Component {
    
    confirm = (e) => {
        e.preventDefault()
        let fullOrders = []
        let partlyOrders = []
        let returnOrders = []
        let actOrders = []

        this.props.data.ext.forEach((order) => {
            if (this.orderIsPartly(order)) {
                partlyOrders.push(order['Заказ'])
            } else if (this.orderIsReturn(order)) {
                returnOrders.push(order['Заказ'])
            } else if (this.orderIsAct(order)) {
                actOrders.push(order['Заказ'])
            } else {
                fullOrders.push(order['Заказ'])
            }
        })

        let orders = {
            'full': fullOrders,
            'partly': partlyOrders,
            'return': returnOrders,
            'act': actOrders,
        }
        let images = []
        
        this.props.deliveredPart(
            this.props.data['Пункт погрузки'], 
            this.props.data['Номер по сдаче'], 
            this.props.data['Получатель Код'], 
            this.props.data['Тип Строки'],
            this.props.planningFinished,
            orders,
            images,
            null
        )
    }

    rejectRecieve = () => {
        if (JobStub.isStubJob()) {
            JobStub.callStubSource('/stage6/reject-receive')
            .then(() => {
                StatusFetching.fetchStubStatus(this.props.home)
            })
            return
        }

        CommonUtils.callSource('api/stage/stage6/reject-receive', {
            job_guid: this.props.jobGUID,
            job_no: this.props.jobNo,
            point: this.props.data['Пункт погрузки'], 
            order: this.props.data['Номер по сдаче'], 
            code: this.props.data['Получатель Код'],
            type: this.props.data['Тип Строки'],
        }, this.props.home)
        .then(() => {
            StatusFetching.fetchStatus(this.props.home)
        })
    }

    orderIsPartly = (order) => {
        return order['Статус разгрузки'] === 'DeliveredPartly' || order['Статус разгрузки'] === 'ReceivedPartly'
    }

    orderIsReturn = (order) => {
        return order['Статус разгрузки'] === 'DeliveredReturn' || order['Статус разгрузки'] === 'NotReceived'
    }

    orderIsAct = (order) => {
        return order['Статус разгрузки'] === 'DeliveredAct' || order['Статус разгрузки'] === 'ReceivedAct'
    }

    render() {
        let statusData = this.props.statusData
        let imqStatus = this.props.imqStatus
        let supportMail = this.props.supportMail
        let oDContart = this.props.oDContart
        let client = this.props.data

        let btnClass = 'lgtrn-btn-secondary'
        let receiveNotAccepted = true
        if (client['Сдача_Прием Подтверждено']) {
            btnClass = 'lgtrn-btn-success'
            receiveNotAccepted = false
        }

        let subTitle = 'Получатель '
        let thPartly = 'Частичный возврат'
        let thReturn = 'Полный возврат'
        let thAct = 'Акт'
        let btnText = 'Сдать груз'
        if (this.props.data['Тип Строки'] === 'Возврат') {
            subTitle = 'Получение возврата '
            thPartly = 'Принят частично'
            thReturn = 'Не принят'
            thAct = 'Акт'
            btnText = 'Принять груз'
        }
        subTitle += client['Получатель'];

        return (
            <div>  
                {this.props.hover}
                <div className="d-flex flex-column lgtrn-vh-100">
                    <div className="flex-grow-1 d-flex flex-column">
                        
                        <JobHeader title={statusData['Номер Рейса']} supportMail={supportMail} />
                        <WhiteBox>
                            {receiveNotAccepted &&
                                <div className="row mt-3">
                                    <div className="col">
                                        <div className="back-panel lgtrn-text-danger" onClick={this.rejectRecieve}>
                                            <span>Назад</span>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="row">
                                <div className="col">
                                    <h3 className="panel-header mb-0">Расхождения в заказе</h3>
                                </div>
                            </div>
                        </WhiteBox>
                        <WhiteBox>
                            <div className="font-weight-bold font-size-13 mb-3">{subTitle}</div>
                        </WhiteBox>

                        {receiveNotAccepted && 
                            <WhiteBox>
                                <div className="row mt-3 mb-3"><div className="col text-center">
                                    <img src="./img/stage_wait.svg" alt="location" className="img-fluid" />
                                </div></div>
                                <div className="row mb-4"><div className="col">
                                    <div className="lgtrn-text-success text-center lgtrn-message">{statusData['Text1']}</div>
                                </div></div>
                            </WhiteBox>
                        }

                        <WhiteBox>
                            <table className="table lgtrn-table mb-3">
                                <thead>
                                    <tr>
                                        <th scope="col" className="pl-0 pb-0 font-size-11 lgtrn-order-header">№ заказа</th>
                                        <th scope="col" className="pb-0 font-size-11 w27">{thAct}</th>
                                        <th scope="col" className="pb-0 font-size-11 w27">{thPartly}</th>
                                        <th scope="col" className="pb-0 font-size-11 w27">{thReturn}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    client.ext.map((order, index) =>
                                        <tr key={index}>
                                            <td className="font-weight-bold font-size-14 pl-0">
                                                {order['Заказ']}
                                            </td>
                                            <td>
                                                <input type="checkbox" value={order['Заказ']} id={'order_received_act' + index} disabled checked={this.orderIsAct(order)} />
                                            </td>
                                            <td>
                                                <input type="checkbox" value={order['Заказ']} id={'order_received_partly' + index} disabled checked={this.orderIsPartly(order)} />
                                            </td>
                                            <td>
                                                <input type="checkbox" value={order['Заказ']} id={'order_not_received' + index} disabled checked={this.orderIsReturn(order)} />
                                            </td>
                                        </tr>
                                    )
                                }
                                    
                                </tbody>
                            </table>
                        </WhiteBox>

                        <WhiteSpace />
                        
                        <WhiteFill />

                    </div>
                    <WhiteBox>
                        <div className="row mb-3"><div className="col">
                            <button className={'btn lgtrn-btn-success btn-block text-uppercase lgtrn-btn ' + btnClass} onClick={this.confirm} disabled={imqStatus !== null || receiveNotAccepted}>{btnText}</button>
                        </div></div>
                    </WhiteBox>
                    <CallPhone oDContart={oDContart} />
                </div>
            </div>
        )
    }
}
