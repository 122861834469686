import * as JobStub from '../utils/JobStub'
import * as StatusFetching from '../utils/StatusFetching'

export function acceptDemoStart(home, hideHints) {
    JobStub.callStubSource('/demo-start/accept', hideHints)
    .then(() => {
        StatusFetching.fetchStubStatus(home)
    })
}

export function acceptDemoFinish(home) {
    JobStub.callStubSource('/demo-finish/accept')
    .then(() => {
        StatusFetching.fetchStubStatus(home)
    })
}

export function acceptStage0(home) {
    JobStub.callStubSource('/stage0/accept')
    .then(() => {
        StatusFetching.fetchStubStatus(home)
    })
}

export function acceptStage3_1(home) {
    JobStub.callStubSource('/stage3/accept1')
    .then(() => {
        StatusFetching.fetchStubStatus(home, acceptStage3_2)
    })
}

export function acceptStage3_2(home) {
    JobStub.callStubSource('/stage3/accept2')
    .then(() => {
        StatusFetching.fetchStubStatus(home, acceptStage3_3)
    })
}

export function acceptStage3_3(home) {
    JobStub.callStubSource('/stage3/accept3')
    .then(() => {
        StatusFetching.fetchStubStatus(home)
    })
}

export function acceptStage6_2(home) {
    JobStub.callStubSource('/stage6_2/accept')
    .then(() => {
        StatusFetching.fetchStubStatus(home)
    })
}

export function acceptStage6_4(home) {
    JobStub.callStubSource('/stage6_4/accept')
    .then(() => {
        StatusFetching.fetchStubStatus(home)
    })
}

export function acceptStage8_2(home) {
    JobStub.callStubSource('/stage8_2/accept')
    .then(() => {
        StatusFetching.fetchStubStatus(home)
    })
}
