import React from "react";

export default class WhiteFill extends React.Component {
    
    render() {
        return (
            <div className="container d-flex flex-collumn flex-grow-1">
                <div className="row d-flex flex-collumn flex-grow-1">
                    <div className="col-12 offset-0 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4 bg-white">
                    </div>
                </div>
            </div>
        )
    }
}
