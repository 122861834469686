import React, { Fragment } from "react";

import Collapsible from '../design/Collapsible'
import WhiteSpace from '../design/WhiteSpace'
import WhiteBreakBottom from '../design/WhiteBreakBottom'
import WhiteBreakTop from '../design/WhiteBreakTop'
import JobHeader from '../elements/JobHeader'
import JobInfo from '../elements/JobInfo'
import JobInfoInline from '../elements/JobInfoInline'
import JobAddress from '../elements/JobAddress'
import CallPhone from '../elements/CallPhone'
import Attention from '../elements/Attention'
import CargoList from '../elements/CargoList'
import EmployeeList from '../elements/EmployeeList'
import EmployeeWindow from '../windows/EmployeeWindow'
import CargoWindow from '../windows/CargoWindow'
import ConfirmReceiveWindow from '../windows/ConfirmReceiveWindow'
import * as JobStub from '../../utils/JobStub'
import * as CommonUtils from '../../utils/CommonUtils'
import * as ServerMessages from '../../utils/ServerMessages'
import * as HintMessages from '../../utils/HintMessages'
import * as StatusFetching from '../../utils/StatusFetching'
import * as JobStubActions from '../../utils/JobStubActions'

export default class Stage6 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            employeeWindowOpen: false,
            currentClient: null,
        }
    }

    employeeWindowShow = (client) => {
        this.setState({
            employeeWindowOpen: true,
            currentClient: client,
        }, () => {
            if (JobStub.isStubJob()) {
                HintMessages.hintDrawyerToggle(this.props.home, JobStub.getHints('EmployeeWindow'), JobStub.hintTimeout)
            }
        })
    }

    employeeWindowHide = () => {
        this.setState({
            employeeWindowOpen: false,
            currentClient: null,
        })
    }

    confirmEmployee = (client) => {
        if (JobStub.isStubJob()) {
            JobStub.callStubSource('/stage6/confirm', client['Получатель Код'])
            .then(() => {
                StatusFetching.fetchStubStatus(this.props.home)
            })
            return
        }

        CommonUtils.callSource('api/stage/stage6/confirm', {
            job_guid: this.props.jobGUID,
            job_no: this.props.jobNo,
            point: client['Пункт погрузки'], 
            order: client['Номер по сдаче'], 
            code: client['Получатель Код'],
            type: client['Тип Строки'],
        }, this.props.home)
        .then(() => {
            StatusFetching.fetchStatus(this.props.home)
        })
    }

    getFirstClient = (clients) => {
        let ret = null
        for (let clientCode in clients) {
            ret = clients[clientCode]
            break
        }
        return ret
    }

    collapseClient = (client, eхpanded) => {
        if (eхpanded.value === true) {
            return true
        }
        if (
            client['Статус разгрузки'] === 'Delivered' 
            || client['Статус разгрузки'] === 'DeliveredPartly' 
            || client['Статус разгрузки'] === 'DeliveredReturn' 
            || client['Статус разгрузки'] === 'DeliveredAct'
            || client['Статус разгрузки'] === 'Received' 
            || client['Статус разгрузки'] === 'ReceivedPartly' 
            || client['Статус разгрузки'] === 'NotReceived' 
            || client['Статус разгрузки'] === 'ReceivedAct'
        ) {
            return true
        }
        eхpanded.value = true
        return false
    }

    deliveredPart = (point, order, code, type, planningFinished, orders, images, onSuccess) => {
        if (JobStub.isStubJob()) {
            JobStub.callStubSource('/stage6/delivered-part', orders)
            .then(() => {
                let nextAction = null
                let currentStage = JobStub.getStage()
                if (currentStage === 'Stage6_1_4' || currentStage === 'Stage6_2_4_413' || currentStage === 'Stage6_2_4_476' || currentStage === 'Stage6_3_4') {
                    nextAction = JobStubActions.acceptStage6_4
                }
                StatusFetching.fetchStubStatus(this.props.home, nextAction)
            })
            return
        }

        if (planningFinished === 0) {
            let text = <span>Внимание!<br />В рейсе необходимо установить отметку &laquo;Планирование завершено&raquo;.<br />Свяжитесь с диспетчером.</span>
            ServerMessages.messageDrawerToggle(this.props.home, text)
            return
        }

        CommonUtils.callSource('api/stage/stage6/delivered-part', {
            job_guid: this.props.jobGUID,
            job_no: this.props.jobNo,
            point: point,
            order: order,
            code: code,
            type: type,
            orders: orders,
            images: images,
        }, this.props.home)
        .then(() => {
            if (onSuccess) {
                onSuccess()
            }
            StatusFetching.fetchStatus(this.props.home)
        })
    }

    render() {
        let statusData = this.props.statusData
        let imqStatus = this.props.imqStatus
        let supportMail = this.props.supportMail
        let oDContart = this.props.oDContart
        let firstClient = this.getFirstClient(statusData.clients)
        if (this.state.employeeWindowOpen) {
            return (
                <EmployeeWindow 
                    toggle={this.employeeWindowHide}
                    statusData={statusData}
                    data={this.state.currentClient}
                    imqStatus={imqStatus}
                    supportMail={supportMail}
                    oDContart={oDContart}
                    jobNo={this.props.jobNo}
                    jobGUID={this.props.jobGUID}
                    home={this.props.home}
                />
            )
        }
        if (statusData['Статус разгрузки'] === 'BeginUnLoad') {
            return (
                <CargoWindow 
                    data={firstClient}
                    statusData={statusData}
                    jobNo={statusData['Номер Рейса']}
                    imqStatus={imqStatus}
                    supportMail={supportMail}
                    oDContart={oDContart}
                    planningFinished={this.props.planningFinished}
                    jobGUID={this.props.jobGUID}
                    home={this.props.home}
                    deliveredPart={this.deliveredPart}
                />
            )
        }
        if (this.props.jobStatus === 'ConfirmReceive' || firstClient['Сдача_Прием Подтверждено'] === 1) {
            return (
                <ConfirmReceiveWindow 
                    data={firstClient}
                    statusData={statusData}
                    jobNo={statusData['Номер Рейса']}
                    imqStatus={imqStatus}
                    supportMail={supportMail}
                    oDContart={oDContart}
                    planningFinished={this.props.planningFinished}
                    jobGUID={this.props.jobGUID}
                    home={this.props.home}
                    deliveredPart={this.deliveredPart}
                />
            )
        }
        let clientEхpanded = {value: false}
        return (
            <div>  
                {this.props.hover}
                <div className="d-flex flex-column lgtrn-vh-100">
                    <div className="flex-grow-1 d-flex flex-column">
                        
                        <JobHeader title={statusData['Номер Рейса']} supportMail={supportMail} />
                        <WhiteSpace />
                        <Collapsible title="Информация о заказе" collapsed={true}>
                            <JobInfo 
                                label1="Номер по сдаче"
                                label1Width="8"
                                label2="Тип"
                                value1={statusData['Номер по сдаче']} 
                                value2={statusData['Тип']} 
                                noWhiteBox="true"
                            />
                            <JobInfo 
                                label1="План прибытия"
                                label1Width="8"
                                label2="План разгрузки"
                                label2Width="8"
                                value1={statusData['Время план прибытия']} 
                                value2={statusData['Время план разгрузки']} 
                                fontBold="true"
                                noWhiteBox="true"
                            />
                            <JobInfo 
                                label1="Доставить с"
                                label1Width="8"
                                label2="Доставить до"
                                label2Width="8"
                                value1={statusData['Доставить с']} 
                                value2={statusData['Доставить по']} 
                                spaceAfter="true"
                                noWhiteBox="true"
                            />
                            <JobAddress title="Адрес" address={statusData['Адрес']} noWhiteBox="true" />
                            {statusData['Тип'] === 'Клиент' &&
                                <JobInfoInline
                                    label="Контакт"
                                    value={statusData['Контакт']} 
                                    noWhiteBox="true"
                                />
                            }
                            <JobInfoInline
                                label="Телефон"
                                value={statusData['Телефон']} 
                                noWhiteBox="true"
                                format="number"
                            />
                            {statusData['Тип'] === 'Клиент' &&
                                <JobInfoInline
                                    label="Точка выгрузки"
                                    value={statusData['Точка Выгрузки']} 
                                    spaceAfter="true"
                                    noWhiteBox="true"
                                />
                            }
                            {statusData['Тип'] === 'ТК' &&
                                <h3 className="lgtrn-company-header text-center mb-4 mt-3">{statusData['Точка Выгрузки']}</h3>
                            }
                        </Collapsible>
                        <Attention>
                            <span>
                                {statusData['Предупреждение']}
                            </span>
                        </Attention>
                        {typeof statusData['Предупреждение2'] !== 'undefined' && <Attention>
                            <span>
                                {statusData['Предупреждение2']}
                            </span>
                        </Attention>}
                        <WhiteBreakBottom />

                        {
                            Object.keys(statusData.clients).map((clientCode, index) => {
                                let client = statusData.clients[clientCode]
                                let title = client['Получатель']
                                if (client['Тип Строки'] === 'Возврат') {
                                    title = 'Получение возврата ' + title
                                } else {
                                    title = 'Получатель ' + title
                                }
                                return <Fragment key={index}>
                                    <WhiteBreakTop />
                                    <Collapsible 
                                        title={<div>{title}</div>}
                                        subtitle={client['Тип Строки'] === 'Клиент' ? '' : client['Точка Выгрузки Название']}
                                        key={index}
                                        collapsed={this.collapseClient(client, clientEхpanded)}
                                    >
                                        {client['Тип Строки'] === 'ТК' &&
                                            <JobInfoInline
                                                label="Адрес"
                                                value={client['Получатель Город']} 
                                                noWhiteBox="true"
                                            />
                                        }
                                        {client['Тип Строки'] === 'ТК' &&
                                            <JobInfoInline
                                                label="Контакт"
                                                value={client['Получатель Контакт']} 
                                                noWhiteBox="true"
                                            />
                                        
                                        }
                                        {client['Тип Строки'] === 'ТК' &&
                                            <JobInfoInline
                                                label="Скорость отправки"
                                                value={client['Скорость']} 
                                                noWhiteBox="true"
                                            />
                                        }
                                        {client['Тип Строки'] === 'ТК' &&
                                            <JobInfoInline
                                                label="Отправитель"
                                                value={client['Отправитель']} 
                                                noWhiteBox="true"
                                            />
                                        }
                                        {client['Тип Строки'] === 'ТК' &&
                                            <JobInfoInline
                                                label="Плательщик"
                                                value={client['Плательщик']} 
                                                spaceAfter="true"
                                                noWhiteBox="true"
                                            />
                                        }
                                        {client['Тип Строки'] === 'Клиент' && (client['Статус разгрузки'] === 'WaitUnLoad' || client['Статус разгрузки'] === 'BeginUnLoad') &&
                                            <EmployeeList employee={client['Представитель']} passport={client['Паспорт']} disabled={client['Представитель подтвержден'] === 0} />
                                        }
                                        {client['Статус разгрузки'] === 'WaitUnLoad' && client['Представитель подтвержден'] === 1 &&
                                            <div>
                                                <button className="btn btn-link lgtrn-btn" onClick={() => {this.employeeWindowShow(client)}} disabled={imqStatus !== null} >
                                                    <img src="./img/plus.svg" alt="add" />
                                                    <span>Изменить представителя</span>
                                                </button>
                                            </div>
                                        }
                                        {((client['Статус разгрузки'] === 'WaitUnLoad' && client['Тип Строки'] === 'ТК') || client['Статус разгрузки'] === 'BeginUnLoad' || client['Статус разгрузки'] === 'Delivered' || client['Статус разгрузки'] === 'DeliveredPartly' || client['Статус разгрузки'] === 'DeliveredReturn' || client['Статус разгрузки'] === 'DeliveredAct') &&
                                            <CargoList data={client['ext']} />
                                        }
                                        {(client['Статус разгрузки'] === 'WaitUnLoad' || client['Статус разгрузки'] === 'BeginUnLoad') && (client['Представитель подтвержден'] === 1 || client['Тип Строки'] === 'ТК') &&
                                            <div className="row mt-4 mb-3"><div className="col">
                                                <button className="btn lgtrn-btn-success btn-block text-uppercase lgtrn-btn" onClick={() => {this.confirmEmployee(client)}} disabled={imqStatus !== null}>Сдать груз</button>
                                            </div></div>
                                        }
                                        {(client['Статус разгрузки'] === 'WaitUnLoad' || client['Статус разгрузки'] === 'BeginUnLoad') && (client['Тип Строки'] === 'Возврат') &&
                                            <div className="row mt-4 mb-3"><div className="col">
                                                <button className="btn lgtrn-btn-success btn-block text-uppercase lgtrn-btn" onClick={() => {this.confirmEmployee(client)}} disabled={imqStatus !== null}>Принять груз с возвратом</button>
                                            </div></div>
                                        }
                                        {(client['Статус разгрузки'] === 'Delivered' || client['Статус разгрузки'] === 'DeliveredPartly' || client['Статус разгрузки'] === 'DeliveredReturn' || client['Статус разгрузки'] === 'DeliveredAct'
                                         || client['Статус разгрузки'] === 'Received' || client['Статус разгрузки'] === 'ReceivedPartly' || client['Статус разгрузки'] === 'NotReceived' || client['Статус разгрузки'] === 'ReceivedAct') &&
                                            <div className="row mt-4 mb-3"><div className="col">
                                                <div className="lgtrn-button-like lgtrn-button-like-success text-uppercase">
                                                    <img src="./img/check-green.svg" alt="check" className="img-fluid" />
                                                    <span className="pl-3">{client['Text1']}</span>
                                                </div>
                                            </div></div>
                                        }
                                        {client['Представитель подтвержден'] === 0 &&
                                            <>
                                                <div className="row mb-3"><div className="col text-center">
                                                    <img src="./img/stage_wait.svg" alt="location" className="img-fluid" />
                                                </div></div>
                                                <div className="row mb-3"><div className="col">
                                                    <div className="lgtrn-text-success text-center lgtrn-message">{statusData['Text1']}</div>
                                                </div></div>
                                                <div className="row mt-4 mb-3"><div className="col">
                                                    <button className="btn lgtrn-btn-secondary btn-block text-uppercase lgtrn-btn" disabled>Сдать груз</button>
                                                </div></div>
                                            </>
                                        }
                                    </Collapsible>
                                    <WhiteBreakBottom />
                                </Fragment>
                            })
                        }

                    </div>
                    <WhiteBreakTop />
                    <CallPhone oDContart={oDContart} />
                </div>
            </div>
        )
    }
}
