import React from "react";
import StackTrace from 'stacktrace-js';
import { getBrowser } from '../utils/BrowserInfo';
import * as CommonUtils from '../utils/CommonUtils'

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            error: null,
            errorInfo: null,
        };
    }

    componentDidCatch(error, errorInfo) {
        this.setState({
          error: error,
          errorInfo: errorInfo,
        })
        StackTrace.fromError(error)
        .then(stackFrames => {
            const reportLines = stackFrames.map(stackFrame => {
                const { lineNumber, functionName, fileName } = stackFrame
                return `${fileName}:${lineNumber} ${functionName}`
            });
            const stackReport = reportLines.join('\n')

            let storage = window.sessionStorage
            let actual_guid = storage.getItem('actual_guid') || '-'
            let actual_jobNo = storage.getItem('actual_jobNo') || '-'
            let actual_response = storage.getItem('actual_response') || '-'

            CommonUtils.callSource('api/util/error', {
                error: error.toString(),
                info: stackReport,
                browser: getBrowser(),
                guid: actual_guid,
                jobNo: actual_jobNo,
                serverResponse: actual_response,
            })
        })
        .catch(err => console.log(err.message))

    }
    
    render() {
        if (this.state.errorInfo) {
          alert('Возникла непредвиденная ошибка, с которой мы уже начали разбираться.')
          return null
        }
    
        return this.props.children;
      }
}