import React from "react";

export default class WhiteBox extends React.Component {
    
    render() {
        return (
            <div className="container"><div className="row"><div className="col-12 offset-0 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4 bg-white">
                {this.props.children}
            </div></div></div>
        )
    }
}
