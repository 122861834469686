import React from "react";

import WhiteSpace from '../design/WhiteSpace'
import WhiteFill from '../design/WhiteFill'
import WhiteBox from '../design/WhiteBox'
import JobHeader from '../elements/JobHeader'
import CallPhone from '../elements/CallPhone'
import JobInfo from '../elements/JobInfo'
import JobAddress from '../elements/JobAddress'
import Attention from "../elements/Attention";

export default class Stage3 extends React.Component {

    render() {
        let statusData = this.props.statusData
        let supportMail = this.props.supportMail
        let oDContart = this.props.oDContart
        let statusTitle = ''
        let attentionTitle = ''
        if (this.props.jobStatus === 'WaitStartLoad') {
            statusTitle = 'Прибытие на погрузку, факт'
            attentionTitle = 'Пройдите к диспетчеру за погрузочными документами по рейсу'
        }
        if (this.props.jobStatus === 'Loading') {
            statusTitle = 'Начало погрузки, факт'
            attentionTitle = 'Отметьте окончание погрузки у диспетчера'
        }
        if (this.props.jobStatus === 'WaitForwarder') {
            statusTitle = 'Окончание погрузки, факт'
            attentionTitle = 'Управление рейсом будет передано экспедитору после отметки диспетчера'
        }
        return <div>  
            {this.props.hover}
            <div className="d-flex flex-column lgtrn-vh-100">
                <div className="flex-grow-1 d-flex flex-column">
                    
                    <JobHeader title={statusData['Номер Рейса']} supportMail={supportMail} />
                    <WhiteSpace />
                    <JobInfo 
                        title={statusTitle} 
                        label1="Дата"
                        label2="Время"
                        value1={statusData['Дата Загрузки']} 
                        value2={statusData['Время Загрузки']} 
                        spaceAfter="true"
                    />
                    <JobAddress title="Адрес" address={statusData['Адрес Загрузки']} />
                    <div className="container"><div className="row"><div className="col-12 offset-0 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4 bg-white pb-6-px">

                    </div></div></div>
                    <Attention>
                        <span>
                            {attentionTitle}
                        </span>
                    </Attention>
                    <WhiteFill />
                </div>
                <WhiteBox>
                    <div className="row mb-3"><div className="col text-center">
                        <img src="./img/stage_wait.svg" alt="location" className="img-fluid" />
                    </div></div>
                    <div className="row mb-3"><div className="col">
                        <div className="lgtrn-text-success text-center lgtrn-message">{statusData['Text1']}</div>
                    </div></div>
                </WhiteBox>
                <CallPhone oDContart={oDContart} />
            </div>
        </div>
    }
}
