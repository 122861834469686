import React from "react";

import WhiteBox from '../design/WhiteBox'
import * as JobStub from '../../utils/JobStub'
import { clearPhone } from "../../utils/format-phone";

export default class CallPhone extends React.Component {
    
    getTip = () => {
        return JobStub.isStubJob() ? 'Недоступно для демоверсии' : null
    } 

    onCallClick = (e) => {
        if (JobStub.isStubJob()) {
            e.preventDefault()
        }
    }

    render() {
        let phone = clearPhone(this.props.oDContart)

        return (
            <>
                <WhiteBox>
                    <div className={'row mb-' + (this.props.space ? '10' : '4')}>
                        <div className="col">
                            <div className="d-flex flex-row">
                                <div className="lgtrn-call-info flex-grow-1">
                                    Связь с диспетчером при возникновении вопросов, сложностей при выполнении рейса
                                </div>
                                
                                <div className="lgtrn-call-icon">
                                    <a href={"tel:" + phone} data-tip={this.getTip()} onClick={this.onCallClick}>
                                        <img src="./img/phone.svg" alt="phone" className="img-fluid" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </WhiteBox>
            </>
        )
    }
}

