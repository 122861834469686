export function numPad(num, size, pad) {
    pad = pad || '0'
    num = num.toString();
    while (num.length < size) num = pad + num;
    return num;
}

export function formatDate(datetime) {
    datetime = datetime || new Date()
    return numPad(datetime.getDate(), 2) + '.' + numPad(datetime.getMonth() + 1, 2) + '.' + numPad(datetime.getFullYear(), 4)
}

export function formatDateYMD(datetime) {
    datetime = datetime || new Date()
    return numPad(datetime.getFullYear(), 4) + '-' + numPad(datetime.getMonth() + 1, 2) + '-' + numPad(datetime.getDate(), 2)
}

export function formatTime(datetime) {
    datetime = datetime || new Date()
    return numPad(datetime.getHours(), 2) + ":" + numPad(datetime.getMinutes(), 2) + ":" + numPad(datetime.getSeconds(), 2)
}

export function getMoment(datetime) {
    datetime = datetime || new Date()
    return {
        date: formatDate(datetime),
        time: formatTime(datetime),
    }
}

export function getMomentYMD(datetime) {
    datetime = datetime || new Date()
    return {
        date: formatDateYMD(datetime),
        time: formatTime(datetime),
    }
}