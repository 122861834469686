import React from "react";
import JobInfo from './JobInfo'

export default class CargoList extends React.Component {

    render() {
        return (
            <div>
                <table className="table lgtrn-table-cargo table-bordered">
                    <thead>
                        <tr>
                            <th scope="col" className="pl-0 pb-0">Заказ Но.</th>
                            <th scope="col" className="pl-0 pb-0">Кол-во мест</th>
                            <th scope="col" className="pl-0 pb-0">Всего мест</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.props.data.map((order, index) => {
                                let colorClass = ''
                                if (this.props.colored) {
                                    colorClass = ' lgtrn-color-success'
                                }
                                if (this.props.colored && (order['Статус разгрузки'] === 'DeliveredPartly' || order['Статус разгрузки'] === 'ReceivedPartly')) {
                                    colorClass = ' lgtrn-color-warning'
                                }
                                if (this.props.colored && (order['Статус разгрузки'] === 'DeliveredAct' || order['Статус разгрузки'] === 'ReceivedAct')) {
                                    colorClass = ' lgtrn-color-warning'
                                }
                                if (this.props.colored && (order['Статус разгрузки'] === 'DeliveredReturn' || order['Статус разгрузки'] === 'Received')) {
                                    colorClass = ' lgtrn-color-danger'
                                }
                                return <tr key={index}>
                                    <td className={'font-weight-bold' + colorClass}>
                                        {order['Заказ']}
                                        {this.props.colored && (order['Статус разгрузки'] === 'DeliveredReturn' || order['Статус разгрузки'] === 'Received') &&
                                            <img src="./img/return.svg" alt="return" className="img-fluid ml-2" />
                                        }
                                    </td>
                                    <td className="number">{order['Кол-во мест']}</td>
                                    {index === 0 && <td className="number" rowSpan={this.props.data.length}>{order['Всего Кол-во мест']}</td>}
                                </tr>
                            })
                        }
                        
                    </tbody>
                </table>
                <JobInfo
                    label1="Объём, м3"
                    label1Width="5"
                    label2="Вес, кг"
                    label2Width="5"
                    value1={this.props.data[0] ? this.props.data[0]['Всего Объем'] : ''} 
                    value2={this.props.data[0] ? this.props.data[0]['Всего Вес'] : ''}
                    noWhiteBox="true"
                />
            </div>
        )
    }
}
