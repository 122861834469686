import React from "react";

import WhiteSpace from '../design/WhiteSpace'
import WhiteFill from '../design/WhiteFill'
import WhiteBox from '../design/WhiteBox'
import DemoActionDrawyer from '../drawyers/DemoActionDrawyer'
import JobHeader from '../elements/JobHeader'
import * as JobStubActions from '../../utils/JobStubActions'

export default class DemoFinish extends React.Component {

    onAcceptClick = () => {
        JobStubActions.acceptDemoFinish(this.props.home)
    }

    render() {
        let statusData = this.props.statusData
        let supportMail = this.props.supportMail
        return (
            <div>  
                {this.props.hover}
                <div className="d-flex flex-column lgtrn-vh-100">
                    <div className="flex-grow-1 d-flex flex-column">
                        <JobHeader title={statusData['Номер Рейса']} supportMail={supportMail} />
                        <WhiteSpace />
                        <WhiteFill />
                    </div>
                    <WhiteBox>
                        <div className="mb-4">
                            <DemoActionDrawyer hintText={
                                <>
                                    <div className="mb-3"><img src="./img/handshake.svg" alt="handshake"/></div>
                                    <div>Спасибо за просмотр</div>
                                </>}
                                btnText="Начать заново" btnClick={this.onAcceptClick}>
                            </DemoActionDrawyer>
                        </div>
                    </WhiteBox>
                </div>
            </div>
        )
    }
}
