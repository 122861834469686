export function hintDrawyerToggle(home, text, timeout, onDrawyerHide) {
    timeout = timeout || 0
    onDrawyerHide = onDrawyerHide || null
    let hideHints = window.sessionStorage.getItem('stub_hide_hints')
    if (!hideHints && text) {
        home.setState({
            hintDrawyerText: text,
            onHintDrawyerHide: onDrawyerHide
        })
        if (timeout > 0) {
            let hintDrawyerTimeout = setTimeout(() => {
                hintDrawyerToggle(home)
            }, timeout)
            home.setState({
                hintDrawyerTimeout: hintDrawyerTimeout
            })
        }
    } else {
        clearTimeout(home.state.hintDrawyerTimeout)
        home.setState({
            hintDrawyerText: '',
            hintDrawyerTimeout: null,
        }, () => {
            let nextAction = onDrawyerHide || home.state.onHintDrawyerHide;
            if (nextAction) {
                setTimeout(() => {
                    nextAction(home)
                    home.setState({
                        onHintDrawyerHide: null
                    })
                }, process.env.REACT_APP_DEMO_DESPATCHER_DELAY)
            }
        })
    }
}