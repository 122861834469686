import React from "react";

import WhiteSpace from '../design/WhiteSpace'
import WhiteFill from '../design/WhiteFill'
import WhiteBox from '../design/WhiteBox'
import DemoActionDrawyer from '../drawyers/DemoActionDrawyer'
import JobHeader from '../elements/JobHeader'
import * as JobStubActions from '../../utils/JobStubActions'

export default class DemoStart extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            hideHints: false
        }
    }

    onHideHintsChange = (e) => {
        this.setState({
            hideHints: e.target.checked
        })
    }

    onAcceptClick = () => {
        JobStubActions.acceptDemoStart(this.props.home, this.state.hideHints)
    }

    render() {
        let statusData = this.props.statusData
        let supportMail = this.props.supportMail
        return (
            <div>  
                {this.props.hover}
                <div className="d-flex flex-column lgtrn-vh-100">
                    <div className="flex-grow-1 d-flex flex-column">
                        <JobHeader title={statusData['Номер Рейса']} supportMail={supportMail} />
                        <WhiteSpace />
                        <WhiteBox>
                            <h1 className="lgtrn-stage-title m-sm text-center">Демоверсия</h1>
                            <p className="lgtrn-stage-description text-center">В данном разделе вы можете на  примере демонстрационного рейса:</p>
                            <ul className="font-size-14">
                                <li>видеть маршрут, заказы и плановое время доставки грузов;</li>
                                <li>получать инструкции по рейсу;</li>
                                <li>отмечать статусы выполнения рейса;</li>
                            </ul>
                            <p className="lgtrn-stage-description text-center">На экранных формах ДЕМО-интерфейса  выводятся подсказки для водителя/экспедитора.<br/>Подсказка исчезает через некоторое время либо по нажатию на нее.</p>
                        </WhiteBox>
                        <WhiteFill />

                    </div>
                    <WhiteBox>
                        <div className="mb-4">
                            <DemoActionDrawyer hintText={
                                <>
                                    <div className="mb-3">Нажмите, чтобы начать просмотр</div>
                                    <div className="form-check">

                                        <label className="checkbox-container">
                                            <input type="checkbox" value="" id="hideHints" checked={this.state.hideHints} onChange={this.onHideHintsChange} />
                                            <span className="checkmark white"></span>
                                            <div>не показывать подсказки</div>
                                        </label>
                                    </div>
                                </>}
                                btnText="Начать просмотр" btnClick={this.onAcceptClick}>
                            </DemoActionDrawyer>
                        </div>
                    </WhiteBox>
                </div>
            </div>
        )
    }
}
