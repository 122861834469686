import React from "react";
import './Home.css';

import HomePage from './stages/HomePage'
import Stage1 from './stages/Stage1'
import Stage2 from './stages/Stage2'
import Stage3 from './stages/Stage3'
import Stage4 from './stages/Stage4'
import Stage5 from './stages/Stage5'
import Stage6 from './stages/Stage6'
import Stage7 from './stages/Stage7'
import Stage8 from './stages/Stage8'
import Stage9 from './stages/Stage9'
import DemoStart from './stages/DemoStart'
import DemoFinish from './stages/DemoFinish'
import MessageDrawyer from './drawyers/MessageDrawyer'
import HintDrawyer from './drawyers/HintDrawyer'
import * as JobStub from '../utils/JobStub'
import * as CommonUtils from '../utils/CommonUtils'
import * as ServerMessages from '../utils/ServerMessages'
import * as WebSocketConnection from '../utils/WebSocketConnection'
import * as StatusFetchingTimer from '../utils/StatusFetchingTimer'
import * as StatusFetching from '../utils/StatusFetching'

export default class Home extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            homePage: false,
            error: null,
            isLoaded: false,
            timer: null,
            socketError: null,

            jobNo: '',
            jobStatus: '',
            imqStatus: null,
            statusData: {},
            statusDataError: false,
            planningFinished: 1,
            oDContart: '+74956685017,78',
            supportMail: 'support@logitern.ru',

            messageDrawerText: null,
            messageDrawerMessageID: null,

            hintDrawyerText: '',
            onHintDrawyerHide: null,
            hintDrawyerTimeout: null
        }
    }

    translate = (message) => {
        if (message === 'Failed to fetch') {
            return 'Сервер временно недоступен'
        }
        if (message === 'Not Found') {
            return 'Ресурс не найден'
        }
        if (message === 'Unprocessable Entity') {
            return 'Неверные параметры запроса к серверу'
        }
        return message
    }

    componentDidMount = () => {
        let job_guid = CommonUtils.getJobGUID()
        if (job_guid === '') {
            this.setState({
                homePage: true,
                isLoaded: true
            })
        } else if (JobStub.isStubJob(job_guid)) {
            StatusFetching.fetchStubStatus(this)
        } else {
            StatusFetching.fetchStatus(this)
            ServerMessages.fetchMessage(this)
            WebSocketConnection.connectWebSocket(this, job_guid)
            if (process.env.REACT_APP_STATUS_FETCHING_INTERVAL) {
                console.log('Status update interval is set to ' + process.env.REACT_APP_STATUS_FETCHING_INTERVAL + ' ms');
                StatusFetchingTimer.timerStart(this);
            }
        }
    }

    getStage = () => {

        const { 
            error, 
            isLoaded, 
            jobStatus, 
            statusData, 
            statusDataError,
            imqStatus, 
            planningFinished,
            socketError, 
            homePage, jobNo, 
            oDContart, 
            supportMail 
        } = this.state
        const jobGUID = CommonUtils.getJobGUID()

        if (error) {
            return <HomePage hover={''} message={this.translate(error.message)} supportMail={supportMail} oDContart={oDContart} />
        }

        if (statusDataError) {
            return <HomePage hover={''} message="Ошибка в данных рейса. Данные об ошибке отправлены в службу поддержки. Попробуйте обновить страницу через несколько минут" supportMail={supportMail} oDContart={oDContart} />
        }

        let hover = ''
        if (!isLoaded) {
            hover = <div className="lgtrn-hover">Загрузка...</div>
        }
        if (socketError) {
            hover = <div className="lgtrn-hover lgtrn-color-danger">{socketError}</div>
        }

        if (homePage) {
            return <HomePage hover={hover} supportMail={supportMail} oDContart={oDContart} messageFormated={
                <>
                    <p className="mt-3 mb-0">
                        Для продолжения работы необходимо перейти по ссылке из SMS.
                    </p>
                    <p className="mt-3 mb-0">
                        Если вы попали сюда по ссылке, то она более не действительна, свяжитесь с диспетчером.
                    </p>
                </>
            } />
        }
        if (jobStatus === 'DemoStart') {
            return (
                <DemoStart
                    hover={hover}
                    statusData={statusData}
                    imqStatus={imqStatus}
                    supportMail={supportMail}
                    oDContart={oDContart}
                    home={this}
                />
            )
        }
        if (jobStatus === 'DemoFinish') {
            return (
                <DemoFinish
                    hover={hover}
                    statusData={statusData}
                    imqStatus={imqStatus}
                    supportMail={supportMail}
                    oDContart={oDContart}
                    home={this}
                />
            )
        }
        if (jobStatus === 'JobNotFound') {
            return <HomePage
                hover={hover}
                supportMail={supportMail}
                oDContart={oDContart}
                message="Ссылка более не действительна, свяжитесь с диспетчером."
            />
        }
        if (jobStatus === 'JobNeedsConfirm') {
            return (
                <Stage1
                    hover={hover}
                    statusData={statusData}
                    imqStatus={imqStatus}
                    supportMail={supportMail}
                    oDContart={oDContart}
                    jobNo={jobNo}
                    jobGUID={jobGUID}
                    home={this}
                />
            )
        }
        if (jobStatus === 'Wait4Arrival') {
            return (
                <Stage2
                    hover={hover}
                    statusData={statusData}
                    imqStatus={imqStatus}
                    supportMail={supportMail}
                    oDContart={oDContart}
                    jobNo={jobNo}
                    jobGUID={jobGUID}
                    home={this}
                />
            )
        }
        if (jobStatus === 'WaitStartLoad' || jobStatus === 'Loading' || jobStatus === 'WaitForwarder') {
            return (
                <Stage3
                    hover={hover}
                    statusData={statusData}
                    imqStatus={imqStatus}
                    supportMail={supportMail}
                    oDContart={oDContart}
                    jobStatus={jobStatus}
                />
            )
        }
        if (jobStatus === 'WaitDeparture') {
            return (
                <Stage4
                    hover={hover}
                    statusData={statusData}
                    imqStatus={imqStatus}
                    supportMail={supportMail}
                    oDContart={oDContart}
                    jobNo={jobNo}
                    jobGUID={jobGUID}
                    home={this}
                />
            )
        }
        if (jobStatus === 'OnTheWay') {
            return (
                <Stage5
                    hover={hover}
                    statusData={statusData}
                    imqStatus={imqStatus}
                    supportMail={supportMail}
                    oDContart={oDContart}
                    planningFinished={planningFinished}
                    jobNo={jobNo}
                    jobGUID={jobGUID}
                    home={this}
                />
            )
        }
        if (jobStatus === 'UnLoading' || jobStatus === 'ConfirmReceive') {
            return (
                <Stage6
                    hover={hover}
                    jobStatus={jobStatus}
                    statusData={statusData}
                    imqStatus={imqStatus}
                    supportMail={supportMail}
                    oDContart={oDContart}
                    planningFinished={planningFinished}
                    jobNo={jobNo}
                    jobGUID={jobGUID}
                    home={this}
                />
            )
        }
        if (jobStatus === 'Delivered') {
            return (
                <Stage7
                    hover={hover}
                    statusData={statusData}
                    imqStatus={imqStatus}
                    supportMail={supportMail}
                    oDContart={oDContart}
                    jobNo={jobNo}
                    jobGUID={jobGUID}
                    home={this}
                />
            )
        }
        if (jobStatus === 'CargoReturn' || jobStatus === 'ConfirmReturn') {
            return (
                <Stage8
                    hover={hover}
                    statusData={statusData}
                    imqStatus={imqStatus}
                    supportMail={supportMail}
                    oDContart={oDContart}
                    confirm={jobStatus === 'ConfirmReturn'}
                    jobNo={jobNo}
                    jobGUID={jobGUID}
                    home={this}
                />
            )
        }
        if (jobStatus === 'Completed') {
            return (
                <Stage9
                    hover={hover}
                    statusData={statusData}
                    imqStatus={imqStatus}
                    supportMail={supportMail}
                    oDContart={oDContart}
                    home={this}
                />
            )
        }
        return <HomePage hover={hover} messageInfo="Получаем информацию по рейсу" supportMail={supportMail} oDContart={oDContart} />
    }

    render() {
        return (
            <>
                {this.getStage()}
                <MessageDrawyer
                    text={this.state.messageDrawerText}
                    home={this}
                />
                <HintDrawyer home={this} />
            </>
        )
    }
}
