import React from "react";

import WhiteSpace from '../design/WhiteSpace'
import WhiteBox from '../design/WhiteBox'
import WhiteBreak from '../design/WhiteBreak'
import WhiteFill from '../design/WhiteFill'
import DemoActionDrawyer from '../drawyers/DemoActionDrawyer';
import JobHeader from '../elements/JobHeader'
import Attention from '../elements/Attention'
import JobInfo from '../elements/JobInfo'
import CallPhone from '../elements/CallPhone'
import * as JobStub from '../../utils/JobStub'
import * as StatusFetching from '../../utils/StatusFetching'

export default class Stage9 extends React.Component 
{
    acceptStage = () => {
        if (JobStub.isStubJob()) {
            JobStub.callStubSource('/stage9/accept')
            .then(() => {
                StatusFetching.fetchStubStatus(this.props.home)
            })
        }
    }

    hasStatus = (data, status) => {
        for (let el of data) {
            if (el['Статус разгрузки'] === status) {
                return true
            }
        }
        return false
    }

    render() {
        let statusData = this.props.statusData
        let supportMail = this.props.supportMail
        let oDContart = this.props.oDContart
        return (
            <div>  
                {this.props.hover}
                <div className="d-flex flex-column lgtrn-vh-100">
                    <div className="flex-grow-1 d-flex flex-column">
                        
                        <JobHeader title={statusData['Номер Рейса']} supportMail={supportMail} />
                        <WhiteSpace />
                        <JobInfo
                            title="Начало рейса"
                            label1="Дата"
                            label2="Время"
                            value1={statusData['Дата Загрузки']} 
                            value2={statusData['Время Загрузки']} 
                            smallSpaceTitle={true}
                            spaceAfter={true}
                            smallTitle={true}
                        />
                        <JobInfo
                            title="Окончание рейса"
                            label1="Дата"
                            label2="Время"
                            value1={statusData['Дата Выполнения']} 
                            value2={statusData['Время Выполнения']} 
                            smallSpaceTitle={true}
                            spaceAfter={true}
                            smallTitle={true}
                        />
                        <WhiteBreak />
                        {this.hasStatus(statusData.ext, 'Delivered') &&
                            <>
                                <WhiteBox>
                                    <div className="block-title mb-3">Сдан</div>
                                    <div className="row">
                                    {
                                        statusData.ext.map((order, index) => {
                                            if (order['Статус разгрузки'] !== 'Delivered') {
                                                return ''
                                            }
                                            return <div key={index} className="col-4 col-sm-3 font-weight-bold font-size-13 lgtrn-color-success">{order['Заказ']}</div>
                                        })
                                    }
                                    </div>
                                </WhiteBox>
                                <WhiteSpace />
                                <WhiteSpace />
                            </>
                        }
                        {this.hasStatus(statusData.ext, 'DeliveredReturn') &&
                            <>
                                <WhiteBox>
                                    <div className="block-title mb-3">Сдан возврат</div>
                                    <div className="row">
                                    {
                                        statusData.ext.map((order, index) => {
                                            if (order['Статус разгрузки'] !== 'DeliveredReturn') {
                                                return ''
                                            }
                                            return <div key={index} className="col-4 col-sm-3 font-weight-bold font-size-13 lgtrn-color-danger">{order['Заказ']}</div>
                                        })
                                    }
                                    </div>
                                </WhiteBox>
                                <WhiteSpace />
                                <WhiteSpace />
                            </>
                        }
                        
                        {this.hasStatus(statusData.ext, 'DeliveredPartly') &&
                            <>
                                <WhiteBox>
                                    <div className="block-title mb-3">Сдан частично</div>
                                    <div className="row">
                                    {
                                        statusData.ext.map((order, index) => {
                                            if (order['Статус разгрузки'] !== 'DeliveredPartly') {
                                                return ''
                                            }
                                            return <div key={index} className="col-4 col-sm-3 font-weight-bold font-size-13 lgtrn-color-warning">{order['Заказ']}</div>
                                        })
                                    }
                                    </div>
                                </WhiteBox>
                                <WhiteSpace />
                                <WhiteSpace />
                            </>
                        }
                        
                        {this.hasStatus(statusData.ext, 'DeliveredAct') &&
                            <>
                                <WhiteBox>
                                    <div className="block-title mb-3">Сдан, составлен Акт</div>
                                    <div className="row">
                                    {
                                        statusData.ext.map((order, index) => {
                                            if (order['Статус разгрузки'] !== 'DeliveredAct') {
                                                return ''
                                            }
                                            return <div key={index} className="col-4 col-sm-3 font-weight-bold font-size-13 lgtrn-color-warning">{order['Заказ']}</div>
                                        })
                                    }
                                    </div>
                                </WhiteBox>
                                <WhiteSpace />
                                <WhiteSpace />
                            </>
                        }
                        
                        {this.hasStatus(statusData.ext, 'Received') &&
                            <>
                                <WhiteBox>
                                    <div className="block-title mb-3">Принято к возврату</div>
                                    <div className="row">
                                    {
                                        statusData.ext.map((order, index) => {
                                            if (order['Статус разгрузки'] !== 'Received') {
                                                return ''
                                            }
                                            return <div key={index} className="col-4 col-sm-3 font-weight-bold font-size-13 lgtrn-color-danger">{order['Заказ']}</div>
                                        })
                                    }
                                    </div>
                                </WhiteBox>
                                <WhiteSpace />
                                <WhiteSpace />
                            </>
                        }
                        
                        {this.hasStatus(statusData.ext, 'ReceivedPartly') &&
                            <>
                                <WhiteBox>
                                    <div className="block-title mb-3">Принято к возврату частично</div>
                                    <div className="row">
                                    {
                                        statusData.ext.map((order, index) => {
                                            if (order['Статус разгрузки'] !== 'ReceivedPartly') {
                                                return ''
                                            }
                                            return <div key={index} className="col-4 col-sm-3 font-weight-bold font-size-13 lgtrn-color-warning">{order['Заказ']}</div>
                                        })
                                    }
                                    </div>
                                </WhiteBox>
                                <WhiteSpace />
                                <WhiteSpace />
                            </>
                        }
                        
                        {this.hasStatus(statusData.ext, 'ReceivedAct') &&
                            <>
                                <WhiteBox>
                                    <div className="block-title mb-3">Принято к возврату, составлен акт</div>
                                    <div className="row">
                                    {
                                        statusData.ext.map((order, index) => {
                                            if (order['Статус разгрузки'] !== 'ReceivedAct') {
                                                return ''
                                            }
                                            return <div key={index} className="col-4 col-sm-3 font-weight-bold font-size-13 lgtrn-color-warning">{order['Заказ']}</div>
                                        })
                                    }
                                    </div>
                                </WhiteBox>
                                <WhiteSpace />
                                <WhiteSpace />
                            </>
                        }

                        <Attention>
                            <span>
                                {statusData['Предупреждение']} 
                            </span>
                        </Attention>
                        {typeof statusData['Предупреждение2'] !== 'undefined' && <Attention>
                            <span>
                                {statusData['Предупреждение2']}
                            </span>
                        </Attention>}
                        <WhiteFill />
                    </div>
                    <WhiteBox>
                        {JobStub.isStubJob() &&
                            <DemoActionDrawyer hintText="Нажмите, чтобы завершить просмотр" btnText={
                                <>
                                    <span className="mr-2">Завершить просмотр</span>
                                    <img src="./img/arrow-right.svg" alt="arrow-right"/>
                                </>}
                                btnClick={this.acceptStage}>
                            </DemoActionDrawyer>
                        }
                        <div className="row mt-3 mb-3"><div className="col">
                            <div className="lgtrn-button-like lgtrn-button-like-success text-uppercase">
                                <img src="./img/check-green.svg" alt="check" className="img-fluid" />
                                <span className="pl-3">Рейс выполнен</span>
                            </div>
                        </div></div>
                    </WhiteBox>
                    <CallPhone oDContart={oDContart} />
                </div>
            </div>
        )
    }
}
