import * as CommonUtils from '../utils/CommonUtils'
import * as StatusFetching from './StatusFetching'
import * as StatusFetchingTimer from './StatusFetchingTimer'
import * as ServerMessages from './ServerMessages'

export function connectWebSocket (home, job_guid, update, timeout) {
    update = update || false
    timeout = timeout || 0
    
    let ws_url = (window.location.protocol === 'https:' ? 'wss:' : 'ws:') + '//' + window.location.host
    if (window.location.host === 'localhost:3000') {
        ws_url = 'ws://localhost:8080'
    }
    ws_url += '/api/status';
    let ws = new WebSocket(ws_url)
    ws.onopen = () => {
        home.setState({
            socketError: null
        })
        ws.send(JSON.stringify({
            guid: job_guid
        }))
        if (update) {
            StatusFetching.fetchStatus(home)
            ServerMessages.fetchMessage(home)
        }
        timeout = 0
    }

    ws.onclose = () => {
        // Если долго нет соединения по WebSocket, активируем обновление по таймеру
        if (timeout === 10000) {
            home.setState({
                socketError: null
            })
            StatusFetching.fetchStatus(home)
            ServerMessages.fetchMessage(home)
            StatusFetchingTimer.timerToggle(home)
        } else {
            home.setState({
                socketError: 'Обновление статуса...'
            })
            setTimeout(() => {
                connectWebSocket(home, CommonUtils.getJobGUID(), true, timeout)
            }, timeout)
            timeout = timeout + 1000
        }
    }

    ws.onerror = () => {
        ws.close();
    }

    ws.onmessage = (e) => {
        let message = JSON.parse(e.data)
        if (message.command === 'update') {
            StatusFetching.fetchStatus(home)
        } else if (message.command === 'message') {
            ServerMessages.messageDrawerToggle(home, message.text, message.message_id)
        }
    }

}