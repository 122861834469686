import * as CommonUtils from './CommonUtils'

export function fetchMessage(home) {
    CommonUtils.callSource('api/message', {
        job_guid: CommonUtils.getJobGUID()
    })
    .then((result) => {
        if (result && result['Текст сообщения']) {
            messageDrawerToggle(home, result['Текст сообщения'], result['ID Сообщения'])
        }
    })
}

export function messageDrawerToggle(home, text, message_id) {
    text = text || null
    message_id = message_id || null
    home.setState({
        messageDrawerText: text,
        messageDrawerMessageID: message_id
    })
    if (text && message_id) {
        CommonUtils.callSource('api/message/receive', {
            job_guid: CommonUtils.getJobGUID(),
            job_no: home.state.jobNo,
            message_id: message_id
        }, home)
        .then(() => {
            new Audio("/media/ding-sound-effect.mp3").play()
        })
    }
}

export function messageDrawerAccept(home) {
    if (home.state.messageDrawerMessageID && home.state.jobNo) {
        CommonUtils.callSource('api/message/accept', {
            job_guid: CommonUtils.getJobGUID(),
            job_no: home.state.jobNo,
            message_id: home.state.messageDrawerMessageID
        }, home, false)
        .then(() => {
            messageDrawerToggle(home)
        })
    } else {
        messageDrawerToggle(home)
    }
}