import React from "react";

export default class DemoActionDrawyer extends React.Component {
    render() {
        return (
            <div>
                <div className="pb-2"><div className="row"><div className="col-10 offset-1">
                    <div className="arrow-down lgtrn-hint-purple">
                        <div className="arrow down"></div>
                        <div className="row">
                            <div className="col text-center">
                                <div className="py-4 px-2">
                                    <div>{this.props.hintText}</div>
                                    <div className="row mt-3 mb-3"><div className="col">
                                        <button className="btn lgtrn-btn-outline-purple btn-block text-uppercase lgtrn-btn" onClick={this.props.btnClick}>
                                            <span>{this.props.btnText}</span>
                                        </button>
                                    </div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div></div></div>
            </div>
        )
    }
}