import * as format from './format'

export function getJobGUID() {
    let ret = window.location.pathname.substring(1)
    if (ret.substring(0, 4) === 'demo' && ret !== 'demo') {
        ret = ret.substring(5)
    }
    return ret
}

function fetchSource(url, request, home) {
    return new Promise(resolve => {
        fetch(url, request)
        .then(res => {
            if (!res.ok) {
                return res.json()
                .then(json => {
                    let msg = res.status >= 500
                        ? 'Возникла ошибка на сервере. Данные об ошибке отправлены в службу поддержки. Попробуйте обновить страницу через несколько минут'
                        : json.message || res.statusText;
                    throw new Error(msg)
                })
            }
            if (home) {
                home.setState({
                    isLoaded: true,
                }, resolve(res.json()))
            } else {
                resolve(res.json())
            }
        })
        .catch(error => {
            if (home) {
                home.setState({
                    isLoaded: true,
                    error
                })
            } else {
                console.error(error)
            } 
        })
    })
}

export function callSource(entry, data, home, blockInput) {
    blockInput = blockInput !== false;
    if (home) {
        home.setState({
            isLoaded: false,
            error: null,
            imqStatus: blockInput ? 'active' : null
        })
    }
    let source_server_url = (window.location.host === 'localhost:3000')
        ? 'http://localhost:8080/'
        : window.location.protocol + '//' + window.location.host + '/'
    let url = source_server_url + entry

    let moment = format.getMoment()
    if (data instanceof FormData) {
        data.append('event_date', moment.date)
        data.append('event_time', moment.time)
    } else if (data) {
        data.event_date = moment.date
        data.event_time = moment.time
    }
    let request = {
        method: "POST",
        body: (data instanceof FormData) ? data : JSON.stringify(data)
    }
    if (!(data instanceof FormData)) {
        request.headers = {'Content-Type': 'application/json;charset=utf-8'}
    }
    
    return fetchSource(url, request, home)
}