import React, { Fragment } from "react";

import WhiteSpace from '../design/WhiteSpace'
import WhiteBox from '../design/WhiteBox'
import WhiteBreak from '../design/WhiteBreak'
import WhiteFill from '../design/WhiteFill'
import JobHeader from '../elements/JobHeader'
import JobAddress from '../elements/JobAddress'
import CallPhone from '../elements/CallPhone'
import ReturnList from '../elements/ReturnList'
import Attention from '../elements/Attention'
import * as JobStub from '../../utils/JobStub'
import * as CommonUtils from '../../utils/CommonUtils'
import * as StatusFetching from '../../utils/StatusFetching'
import * as JobStubActions from '../../utils/JobStubActions'

export default class Stage8 extends React.Component {

    acceptStage = () => {
        if (JobStub.isStubJob()) {
            JobStub.callStubSource('/stage8/accept')
            .then(() => {
                StatusFetching.fetchStubStatus(this.props.home, JobStubActions.acceptStage8_2)
            })
            return
        }

        CommonUtils.callSource('api/stage/stage8/accept', {
            job_guid: this.props.jobGUID,
            job_no: this.props.jobNo,
        }, this.props.home)
        .then(() => {
            StatusFetching.fetchStatus(this.props.home)
        })
    }

    render() {
        let statusData = this.props.statusData
        let imqStatus = this.props.imqStatus
        let supportMail = this.props.supportMail
        let oDContart = this.props.oDContart
        return (
            <div>  
                {this.props.hover}
                <div className="d-flex flex-column lgtrn-vh-100">
                    <div className="flex-grow-1 d-flex flex-column">
                        
                        <JobHeader title={statusData['Номер Рейса']} supportMail={supportMail} />
                        <WhiteSpace />
                        <WhiteBox>
                            <div className="row mb-3"><div className="col">
                                <span className="lgtrn-status">Возврат груза на склад погрузки</span>
                            </div></div>
                            <JobAddress title="Адрес" address={statusData['Адрес Загрузки']} noWhiteBox="true" />
                        </WhiteBox>
                        {this.props.confirm &&
                            <>
                                <WhiteBox>
                                    <div className="row mb-3"><div className="col text-center">
                                        <img src="./img/stage_wait.svg" alt="location" className="img-fluid" />
                                    </div></div>
                                    <div className="row mb-3"><div className="col">
                                        <div className="lgtrn-text-success text-center lgtrn-message">Ожидаем подтверждения возврата</div>
                                    </div></div>
                                </WhiteBox>
                                <WhiteSpace />
                                <Attention>
                                    <span>
                                        {statusData['Предупреждение']} 
                                    </span>
                                </Attention>
                                {typeof statusData['Предупреждение2'] !== 'undefined' && 
                                    <Attention>
                                        <span>
                                            {statusData['Предупреждение2']}
                                        </span>
                                    </Attention>
                                }
                            </>
                        }
                        <WhiteBreak />
                        {
                            Object.keys(statusData.clients).map((clientCode, index) => {
                                let client = statusData.clients[clientCode]
                                let title = client['Получатель']
                                if(client['Тип'] === 'Возврат') {
                                    title = ['Догруз с возвратом ', title]
                                }
                                else {
                                    title = 'Получатель ' + title
                                }
                                return <Fragment key={index}>
                                    <WhiteBox>
                                        <div className="block-title">{title}</div>
                                        <ReturnList data={client['ext']} colored={true} />
                                        <div className="row mb-3"><div className="col"></div></div>
                                    </WhiteBox>
                                </Fragment>
                            })
                        }
                        <WhiteFill />
                    </div>
                    {!this.props.confirm &&
                        <WhiteBox>
                            <div className="row mb-3"><div className="col">
                                <button className="btn lgtrn-btn-success btn-block text-uppercase lgtrn-btn" onClick={this.acceptStage} disabled={imqStatus !== null}>Груз возвращен на склад</button>
                            </div></div>
                        </WhiteBox>
                    }
                    <WhiteBreak />
                    <CallPhone oDContart={oDContart} />
                    
                </div>
            </div>
        )
    }
}
