import React from "react";
import WhiteBox from './WhiteBox'

export default class WhiteSpace extends React.Component {
    
    render() {
        return (
            <WhiteBox>
                <div className="pb-3"></div>
            </WhiteBox>
        )
    }
}
