import * as CommonUtils from "./CommonUtils"
import * as HintMessages from './HintMessages'

export const hintTimeout = window.sessionStorage.getItem('stub_hint_timeout') || process.env.REACT_APP_DEMO_HINT_TIMEOUT

export function isStubJob(job_guid) {
    job_guid = job_guid || CommonUtils.getJobGUID()
    return job_guid === '4EDD1209-BD04-4EF8-AF49-E3AEC4E63706' || job_guid === 'demo'
}

function setStage(stage) {
    let storage = window.sessionStorage
    if (stage) {
        storage.setItem('stub_stage', stage)
    } else {
        storage.removeItem('stub_stage')
    }
}

export function getStage() {
    let storage = window.sessionStorage
    return storage.getItem('stub_stage')
}

function demoStartData() {
    return {
        "data": {
            "jobNo": null,
            "jobStatus": "DemoStart",
            "imqStatus": null,
            "planningFinished": 0,
            "webpack_hash": "19de75ec597eecb52be3",
            "statusData": {
                "Номер Рейса": "C329490",
            },
        }
    }
}

function demoFinishData() {
    return {
        "data": {
            "jobNo": null,
            "jobStatus": "DemoFinish",
            "imqStatus": null,
            "planningFinished": 0,
            "webpack_hash": "19de75ec597eecb52be3",
            "statusData": {
                "Номер Рейса": "C329490",
            },
        }
    }
}

function stage0Data() {
    return {
        "data": {
            "jobNo": null,
            "jobStatus": "JobNotFound",
            "imqStatus": null,
            "planningFinished": 0,
            "webpack_hash": "19de75ec597eecb52be3"
        }
    }
}

function stage1Data() {
    return {
        "data": {
            "jobNo": "C329490",
            "jobStatus": "JobNeedsConfirm",
            "imqStatus": null,
            "planningFinished": 1,
            "statusData": {
                "Дата Загрузки": "12.04.2022",
                "Время Загрузки": "07:30",
                "Номер Рейса": "C329490",
                "Адрес Загрузки": "МО,Раменский р-н, п.Быково,ул.Аэропортовская,д14, корп.E"
            },
            "webpack_hash": "2d105507dcca47c72967"
        }
    }
}

function stage2Data() {
    return {
        "data": {
            "jobNo": "C329490",
            "jobStatus": "Wait4Arrival",
            "imqStatus": null,
            "planningFinished": 1,
            "statusData": {
                "Дата Загрузки": "12.04.2022",
                "Время Загрузки": "07:30",
                "Номер Рейса": "C329490",
                "Адрес Загрузки": "МО,Раменский р-н, п.Быково,ул.Аэропортовская,д14, корп.E"
            },
            "webpack_hash": "bceeb67957a1ef820ab0"
        }
    }
}

function stage3_1Data() {
    return {
        "data": {
            "jobNo": "C329490",
            "jobStatus": "WaitStartLoad",
            "imqStatus": null,
            "planningFinished": 1,
            "statusData": {
                "Дата Загрузки": "12.04.2022",
                "Время Загрузки": "13:42",
                "Номер Рейса": "C329490",
                "Text1": "Ожидаем начала погрузки",
                "Адрес Загрузки": "МО,Раменский р-н, п.Быково,ул.Аэропортовская,д14, корп.E",
                "Предупреждение": "Пройдите к диспетчеру за погрузочными документами по рейсу"
            },
            "webpack_hash": "cc234b20ff8583d4584e"
        }
    }
}

function stage3_2Data() {
    return {
        "data": {
            "jobNo": "C329490",
            "jobStatus": "Loading",
            "imqStatus": null,
            "planningFinished": 1,
            "statusData": {
                "Дата Загрузки": "12.04.2022",
                "Время Загрузки": "13:53",
                "Номер Рейса": "C329490",
                "Text1": "Ожидаем окончания погрузки",
                "Адрес Загрузки": "МО,Раменский р-н, п.Быково,ул.Аэропортовская,д14, корп.E",
                "Предупреждение": "Отметьте окончание погрузки у диспетчера"
            },
            "webpack_hash": "f6043e6d68145a656f27"
        }
    }
}

function stage3_3Data() {
    return {
        "data": {
            "jobNo": "C329490",
            "jobStatus": "WaitForwarder",
            "imqStatus": null,
            "planningFinished": 1,
            "statusData": {
                "Дата Загрузки": "12.04.2022",
                "Время Загрузки": "13:55",
                "Номер Рейса": "C329490",
                "Text1": "Ожидаем прибытия экспедитора",
                "Адрес Загрузки": "МО,Раменский р-н, п.Быково,ул.Аэропортовская,д14, корп.E",
                "Предупреждение": "Управление рейсом будет передано экспедитору после отметки диспетчера"
            },
            "webpack_hash": "f6043e6d68145a656f27"
        }
    }
}

function stage4Data() {
    return {
        "data": {
            "jobNo": "C329490",
            "jobStatus": "WaitDeparture",
            "imqStatus": null,
            "planningFinished": 1,
            "statusData": {
                "Дата Загрузки": "12.04.2022",
                "Время Загрузки": "13:55",
                "Номер Рейса": "C329490",
                "Text1": "Рейс готов к выезду",
                "Адрес": "Парфеново с., ул. Московская, д.1"
            },
            "webpack_hash": "810bf0232ed8c4d2ac6b"
        }
    }
}

function stage5_1Data() {
    return {
        "data": {
            "jobNo": "C329490",
            "jobStatus": "OnTheWay",
            "imqStatus": null,
            "planningFinished": 1,
            "statusData": {
                "Номер Рейса": "C329490",
                "Text1": "Рейс в пути",
                "Пункт погрузки": 1,
                "Номер по сдаче": 1,
                "Последняя точка": 0,
                "Тип": "Клиент",
                "Доставить с": "10:00",
                "Доставить по": "21:00",
                "Дата план прибытия": "14.04.2022",
                "Время план прибытия": "15:56",
                "Дата план разгрузки": "14.04.2022",
                "Время план разгрузки": "16:26",
                "Адрес": "Парфеново с., ул. Московская, д.1",
                "Контакт": "Иванов Валерий Денисович",
                "Телефон": "8(910)111-22-33 доб. 222, 8(911)222-44-44 доб. 333",
                "Точка Выгрузки": "Парфеново"
            },
            "webpack_hash": "e913ca927dffed911eee"
        }
    }
}

function stage6_1_1Data() {
    let name = window.sessionStorage.getItem('stub_employee_name')
    return {
        "data": {
            "jobNo": "C329490",
            "jobStatus": "UnLoading",
            "imqStatus": null,
            "planningFinished": 1,
            "statusData": [{
                    "Номер Рейса": "C329490",
                    "Text1": "Ожидает разгрузки",
                    "Статус разгрузки": "WaitUnLoad",
                    "Пункт погрузки": 1,
                    "Номер по сдаче": 1,
                    "Последняя точка": 0,
                    "Тип": "Клиент",
                    "Тип Строки": "Клиент",
                    "Доставить с": "10:00",
                    "Доставить по": "21:00",
                    "Дата план прибытия": "14.04.2022",
                    "Время план прибытия": "14:00",
                    "Дата план разгрузки": "12.04.2022",
                    "Время план разгрузки": "14:28",
                    "Адрес": "Парфеново с., ул. Московская, д.1",
                    "Контакт": "Иванов Валерий Денисович",
                    "Телефон": "8(910)111-22-33 доб. 222, 8(911)222-44-44 доб. 333",
                    "Точка Выгрузки": "Парфеново",
                    "Предупреждение": "Груз передать только указанному представителю. Проверьте паспорт на соответствие указанных данных и его срок действия.",
                    "Получатель Код": "128836",
                    "Получатель": "ООО \"НЕТВОРК\"",
                    "Представитель": name ? name : "Сокол Виталий Юрьевич",
                    "Паспорт": name ? '' : "4040 121212",
                    "Представитель подтвержден": 1,
                    "Сдача_Прием Подтверждено": 0
                }
            ],
            "extData": [{
                    "Получатель Код": "128836",
                    "Получатель": "ООО \"НЕТВОРК\"",
                    "Представитель": "Сокол Виталий Юрьевич",
                    "Паспорт": "4040 121212",
                    "В рейсе": 1
                }
            ],
            "webpack_hash": "d2d35755ce92b868c5d4"
        }
    }    
}

function stage6_1_2Data() {
    let name = window.sessionStorage.getItem('stub_employee_name')
    return {
        "data": {
            "jobNo": "C329490",
            "jobStatus": "UnLoading",
            "imqStatus": null,
            "planningFinished": 1,
            "statusData": [{
                    "Номер Рейса": "C329490",
                    "Text1": "Ожидает подтверждения",
                    "Статус разгрузки": "WaitUnLoad",
                    "Пункт погрузки": 1,
                    "Номер по сдаче": 1,
                    "Последняя точка": 0,
                    "Тип": "Клиент",
                    "Тип Строки": "Клиент",
                    "Доставить с": "10:00",
                    "Доставить по": "21:00",
                    "Дата план прибытия": "14.04.2022",
                    "Время план прибытия": "15:56",
                    "Дата план разгрузки": "12.04.2022",
                    "Время план разгрузки": "14:28",
                    "Адрес": "Парфеново с., ул. Московская, д.1",
                    "Контакт": "Иванов Валерий Денисович",
                    "Телефон": "8(910)111-22-33 доб. 222, 8(911)222-44-44 доб. 333",
                    "Точка Выгрузки": "Парфеново",
                    "Предупреждение": "Груз передать только указанному представителю. Проверьте паспорт на соответствие указанных данных и его срок действия.",
                    "Получатель Код": "128836",
                    "Получатель": "ООО \"НЕТВОРК\"",
                    "Представитель": name ? name : '',
                    "Паспорт": "",
                    "Представитель подтвержден": 0,
                    "Сдача_Прием Подтверждено": 0
                }
            ],
            "extData": [{
                    "Получатель Код": "128836",
                    "Получатель": "ООО \"НЕТВОРК\"",
                    "Представитель": name ? name : '',
                    "Паспорт": "",
                    "В рейсе": 1
                }, {
                    "Получатель Код": "128836",
                    "Получатель": "ООО \"НЕТВОРК\"",
                    "Представитель": "Сокол Виталий Юрьевич",
                    "Паспорт": "4040 121212",
                    "В рейсе": 2
                }
            ],
            "webpack_hash": "e0195d22cccb66e2f625"
        }
    } 
}

function stage6_1_3Data() {
    let name = window.sessionStorage.getItem('stub_employee_name')
    return {
        "data": {
            "jobNo": "C329490",
            "jobStatus": "UnLoading",
            "imqStatus": null,
            "planningFinished": 1,
            "statusData": [{
                    "Номер Рейса": "C329490",
                    "Text1": "Сдача груза",
                    "Статус разгрузки": "BeginUnLoad",
                    "Пункт погрузки": 1,
                    "Номер по сдаче": 1,
                    "Последняя точка": 0,
                    "Тип": "Клиент",
                    "Тип Строки": "Клиент",
                    "Доставить с": "10:00",
                    "Доставить по": "21:00",
                    "Дата план прибытия": "14.04.2022",
                    "Время план прибытия": "15:56",
                    "Дата план разгрузки": "12.04.2022",
                    "Время план разгрузки": "14:28",
                    "Адрес": "Парфеново с., ул. Московская, д.1",
                    "Контакт": "Иванов Валерий Денисович",
                    "Телефон": "8(910)111-22-33 доб. 222, 8(911)222-44-44 доб. 333",
                    "Точка Выгрузки": "Парфеново",
                    "Предупреждение": "При наличии расхождений в заказах заполните поля ниже. Оформите Акт расхождений в заказе.",
                    "Получатель Код": "128836",
                    "Получатель": "ООО \"НЕТВОРК\"",
                    "Представитель": name ? name : "Сокол Виталий Юрьевич",
                    "Паспорт": name ? '' : "4040 121212",
                    "Представитель подтвержден": 1,
                    "Сдача_Прием Подтверждено": 0
                }
            ],
            "extData": [{
                    "Тип Строки": "Клиент",
                    "Получатель Код": "128836",
                    "Получатель": "ООО \"НЕТВОРК\"",
                    "Статус разгрузки": "BeginUnLoad",
                    "Заказ": "B68227",
                    "Кол-во мест": 92,
                    "Объем": 6.51,
                    "Вес": 1037,
                    "Всего Кол-во мест": 92,
                    "Всего Объем": 6.51,
                    "Всего Вес": 1037
                }
            ],
            "webpack_hash": "1b556d822ab564999fa7"
        }
    }
}

function stage6_1_4Data() {
    let orders = JSON.parse(window.sessionStorage.getItem('stub_orders'))
    return {
        "data": {
            "jobNo": "C329490",
            "jobStatus": "ConfirmReceive",
            "imqStatus": null,
            "planningFinished": 1,
            "statusData": [{
                    "Номер Рейса": "C329490",
                    "Text1": "Ожидаем подтверждения сдачи/приемки",
                    "Статус разгрузки": orders['B68227'],
                    "Пункт погрузки": 1,
                    "Номер по сдаче": 1,
                    "Последняя точка": 0,
                    "Тип": "Клиент",
                    "Тип Строки": "Клиент",
                    "Доставить с": "10:00",
                    "Доставить по": "21:00",
                    "Дата план прибытия": "14.04.2022",
                    "Время план прибытия": "15:56",
                    "Дата план разгрузки": "12.04.2022",
                    "Время план разгрузки": "14:28",
                    "Адрес": "Парфеново с., ул. Московская, д.1",
                    "Контакт": "Иванов Валерий Денисович",
                    "Телефон": "8(910)111-22-33 доб. 222, 8(911)222-44-44 доб. 333",
                    "Точка Выгрузки": "Парфеново",
                    "Предупреждение": "",
                    "Получатель Код": "128836",
                    "Получатель": "ООО \"НЕТВОРК\"",
                    "Представитель": "Сокол Виталий Юрьевич",
                    "Паспорт": "4040 121212",
                    "Представитель подтвержден": 1,
                    "Сдача_Прием Подтверждено": 0
                }
            ],
            "extData": [{
                    "Тип Строки": "Клиент",
                    "Получатель Код": "128836",
                    "Получатель": "ООО \"НЕТВОРК\"",
                    "Статус разгрузки": orders['B68227'],
                    "Заказ": "B68227",
                    "Кол-во мест": 92,
                    "Объем": 6.51,
                    "Вес": 1037,
                    "Всего Кол-во мест": 92,
                    "Всего Объем": 6.51,
                    "Всего Вес": 1037
                }
            ],
            "webpack_hash": "1d84642e3ad554457bf1"
        }
    }
    
}

function stage6_1_5Data() {
    let orders = JSON.parse(window.sessionStorage.getItem('stub_orders'))
    return {
        "data": {
            "jobNo": "C329490",
            "jobStatus": "UnLoading",
            "imqStatus": null,
            "planningFinished": 1,
            "statusData": [{
                    "Номер Рейса": "C329490",
                    "Text1": "Груз сдан с расхождениями",
                    "Статус разгрузки": orders['B68227'],
                    "Пункт погрузки": 1,
                    "Номер по сдаче": 1,
                    "Последняя точка": 0,
                    "Тип": "Клиент",
                    "Тип Строки": "Клиент",
                    "Доставить с": "10:00",
                    "Доставить по": "21:00",
                    "Дата план прибытия": "14.04.2022",
                    "Время план прибытия": "15:56",
                    "Дата план разгрузки": "12.04.2022",
                    "Время план разгрузки": "14:28",
                    "Адрес": "Парфеново с., ул. Московская, д.1",
                    "Контакт": "Иванов Валерий Денисович",
                    "Телефон": "8(910)111-22-33 доб. 222, 8(911)222-44-44 доб. 333",
                    "Точка Выгрузки": "Парфеново",
                    "Предупреждение": "",
                    "Получатель Код": "128836",
                    "Получатель": "ООО \"НЕТВОРК\"",
                    "Представитель": "Сокол Виталий Юрьевич",
                    "Паспорт": "4040 121212",
                    "Представитель подтвержден": 1,
                    "Сдача_Прием Подтверждено": 1
                }
            ],
            "extData": [{
                    "Тип Строки": "Клиент",
                    "Получатель Код": "128836",
                    "Получатель": "ООО \"НЕТВОРК\"",
                    "Статус разгрузки": orders['B68227'],
                    "Заказ": "B68227",
                    "Кол-во мест": 92,
                    "Объем": 6.51,
                    "Вес": 1037,
                    "Всего Кол-во мест": 92,
                    "Всего Объем": 6.51,
                    "Всего Вес": 1037
                }
            ],
            "webpack_hash": "2204a7917a861341d317"
        }
    }    
}

function stage7_1Data() {
    let orders = JSON.parse(window.sessionStorage.getItem('stub_orders'))
    return {
        "data": {
            "jobNo": "C329490",
            "jobStatus": "Delivered",
            "imqStatus": null,
            "planningFinished": 1,
            "statusData": [{
                    "Номер Рейса": "C329490",
                    "Text1": orders['B68227'] === 'Delivered' ? "Груз сдан без расхождений" : "Груз сдан с расхождениями",
                    "Статус разгрузки": orders['B68227'],
                    "Пункт погрузки": 1,
                    "Номер по сдаче": 1,
                    "Последняя точка": 0,
                    "Тип": "Клиент",
                    "Тип Строки": "Клиент",
                    "Доставить с": "10:00",
                    "Доставить по": "21:00",
                    "Дата план прибытия": "14.04.2022",
                    "Время план прибытия": "14:00",
                    "Дата план разгрузки": "12.04.2022",
                    "Время план разгрузки": "14:28",
                    "Адрес": "Парфеново с., ул. Московская, д.1",
                    "Контакт": "Иванов Валерий Денисович",
                    "Телефон": "8(910)111-22-33 доб. 222, 8(911)222-44-44 доб. 333",
                    "Точка Выгрузки": "Парфеново",
                    "Предупреждение": "",
                    "Получатель Код": "128836",
                    "Получатель": "ООО \"НЕТВОРК\"",
                    "Представитель": "Сокол Виталий Юрьевич",
                    "Паспорт": "4040 121212",
                    "Представитель подтвержден": 1,
                    "Сдача_Прием Подтверждено": 1
                }
            ],
            "extData": [{
                    "Тип Строки": "Клиент",
                    "Получатель Код": "128836",
                    "Получатель": "ООО \"НЕТВОРК\"",
                    "Статус разгрузки": orders['B68227'],
                    "Заказ": "B68227",
                    "Кол-во мест": 92,
                    "Объем": 6.51,
                    "Вес": 1037,
                    "Всего Кол-во мест": 92,
                    "Всего Объем": 6.51,
                    "Всего Вес": 1037
                }
            ],
            "webpack_hash": "546b48d7a81440b005d5"
        }
    }    
}

function stage5_2Data() {
    return {
        "data": {
            "jobNo": "C329490",
            "jobStatus": "OnTheWay",
            "imqStatus": null,
            "planningFinished": 1,
            "statusData": {
                "Номер Рейса": "C329490",
                "Text1": "Рейс в пути",
                "Пункт погрузки": 1,
                "Номер по сдаче": 2,
                "Последняя точка": 0,
                "Тип": "ТК",
                "Доставить с": "10:00",
                "Доставить по": "21:00",
                "Дата план прибытия": "12.04.2022",
                "Время план прибытия": "18:22",
                "Дата план разгрузки": "12.04.2022",
                "Время план разгрузки": "19:22",
                "Адрес": "Варшавское ш., д.12",
                "Контакт": "",
                "Телефон": "8(495)555-33-22, 8(495)123-33-22",
                "Точка Выгрузки": "ТК ПЭК"
            },
            "webpack_hash": "658b6c1f47895715c01a"
        }
    }
}

function stage6_2_1Data() {
    let finished = window.sessionStorage.getItem('stub_finished')
    let orders = JSON.parse(window.sessionStorage.getItem('stub_orders'))
    if (finished === '413') {
        return {
            "data": {
                "jobNo": "C329490",
                "jobStatus": "UnLoading",
                "imqStatus": null,
                "planningFinished": 1,
                "statusData": [{
                        "Номер Рейса": "C329490",
                        "Text1": orders['BR0091557'] === 'Delivered' ? "Груз сдан без расхождений" : "Груз сдан с расхождениями",
                        "Статус разгрузки": orders['BR0091557'],
                        "Пункт погрузки": 1,
                        "Номер по сдаче": 2,
                        "Последняя точка": 0,
                        "Тип": "ТК",
                        "Тип Строки": "ТК",
                        "Доставить с": "10:00",
                        "Доставить по": "21:00",
                        "Дата план прибытия": "12.04.2022",
                        "Время план прибытия": "19:22",
                        "Дата план разгрузки": "12.04.2022",
                        "Время план разгрузки": "20:22",
                        "Адрес": "Варшавское ш., д.12",
                        "Контакт": "",
                        "Телефон": "8(495)555-33-22, 8(495)123-33-22",
                        "Точка Выгрузки": "ТК ПЭК",
                        "Предупреждение": "",
                        "Получатель Код": "413",
                        "Получатель": "ООО \"ЦЕНТР КРЕДИТ\"",
                        "Точка Выгрузки Название": "ТК ПЭК_DTD АВТО ДТД",
                        "Тип ТК": "ТК ДтД",
                        "Получатель Город": "Красноярский край, Красноярск",
                        "Получатель Контакт": "Фонарева Ольга Сергеевна, т.78883332211",
                        "Скорость": "АВТО",
                        "Плательщик": "ООО \"ИТЕРН\", заказать доставку до двери клиента",
                        "Отправитель": "ООО \"Итерн\"",
                        "Сдача_Прием Подтверждено": 2
                    }, {
                        "Номер Рейса": "C329490",
                        "Text1": "Ожидает разгрузки",
                        "Статус разгрузки": "WaitUnLoad",
                        "Пункт погрузки": 1,
                        "Номер по сдаче": 2,
                        "Последняя точка": 0,
                        "Тип": "ТК",
                        "Тип Строки": "ТК",
                        "Доставить с": "10:00",
                        "Доставить по": "21:00",
                        "Дата план прибытия": "12.04.2022",
                        "Время план прибытия": "19:22",
                        "Дата план разгрузки": "12.04.2022",
                        "Время план разгрузки": "20:22",
                        "Адрес": "Варшавское ш., д.12",
                        "Контакт": "",
                        "Телефон": "8(495)555-33-22, 8(495)123-33-22",
                        "Точка Выгрузки": "ТК ПЭК",
                        "Предупреждение": "Груз передать на основании экспедиторской расписки от ООО \"ПЭК\" ИНН 7721823853",
                        "Получатель Код": "476",
                        "Получатель": "ООО \"ЦЕНТР КРЕДИТ\"",
                        "Точка Выгрузки Название": "ТК ПЭК_DTD АВТО ДТД",
                        "Тип ТК": "ТК ДтД",
                        "Получатель Город": "Республика Бурятия, Улан-Удэ",
                        "Получатель Контакт": "Ткаченко Вера, т.79991112233",
                        "Скорость": "АВТО",
                        "Плательщик": "ООО \"ИТЕРН\", заказать доставку до двери клиента",
                        "Отправитель": "ООО \"Итерн\"",
                        "Сдача_Прием Подтверждено": 0
                    }
                ],
                "extData": [{
                        "Тип Строки": "ТК",
                        "Получатель Код": "413",
                        "Получатель": "ООО \"ЦЕНТР КРЕДИТ\"",
                        "Статус разгрузки": orders['BR0091557'],
                        "Заказ": "BR0091557",
                        "Кол-во мест": 5,
                        "Объем": 0.12,
                        "Вес": 78,
                        "Всего Кол-во мест": 5,
                        "Всего Объем": 0.12,
                        "Всего Вес": 78
                    }, {
                        "Тип Строки": "ТК",
                        "Получатель Код": "476",
                        "Получатель": "ООО \"ЦЕНТР КРЕДИТ\"",
                        "Статус разгрузки": "WaitUnLoad",
                        "Заказ": "BR0091556",
                        "Кол-во мест": 6,
                        "Объем": 0.05,
                        "Вес": 29,
                        "Всего Кол-во мест": 6,
                        "Всего Объем": 0.05,
                        "Всего Вес": 29
                    }
                ],
                "webpack_hash": "2fd4fed848360ecd571e"
            }
        }        
    }
    if (finished === '476') {
        return {
            "data": {
                "jobNo": "C329490",
                "jobStatus": "UnLoading",
                "imqStatus": null,
                "planningFinished": 1,
                "statusData": [{
                        "Номер Рейса": "C329490",
                        "Text1": "Ожидает разгрузки",
                        "Статус разгрузки": "WaitUnLoad",
                        "Пункт погрузки": 1,
                        "Номер по сдаче": 2,
                        "Последняя точка": 0,
                        "Тип": "ТК",
                        "Тип Строки": "ТК",
                        "Доставить с": "10:00",
                        "Доставить по": "21:00",
                        "Дата план прибытия": "12.04.2022",
                        "Время план прибытия": "19:22",
                        "Дата план разгрузки": "12.04.2022",
                        "Время план разгрузки": "20:22",
                        "Адрес": "Варшавское ш., д.12",
                        "Контакт": "",
                        "Телефон": "8(495)555-33-22, 8(495)123-33-22",
                        "Точка Выгрузки": "ТК ПЭК",
                        "Предупреждение": "Груз передать на основании экспедиторской расписки от ООО \"ПЭК\" ИНН 7721823853",
                        "Получатель Код": "413",
                        "Получатель": "ООО \"ЦЕНТР КРЕДИТ\"",
                        "Точка Выгрузки Название": "ТК ПЭК_DTD АВТО ДТД",
                        "Тип ТК": "ТК ДтД",
                        "Получатель Город": "Красноярский край, Красноярск",
                        "Получатель Контакт": "Фонарева Ольга Сергеевна, т.78883332211",
                        "Скорость": "АВТО",
                        "Плательщик": "ООО \"ИТЕРН\", заказать доставку до двери клиента",
                        "Отправитель": "ООО \"Итерн\"",
                        "Сдача_Прием Подтверждено": 0
                    }, {
                        "Номер Рейса": "C329490",
                        "Text1": orders['BR0091556'] === 'Delivered' ? "Груз сдан без расхождений" : "Груз сдан с расхождениями",
                        "Статус разгрузки": orders['BR0091556'],
                        "Пункт погрузки": 1,
                        "Номер по сдаче": 2,
                        "Последняя точка": 0,
                        "Тип": "ТК",
                        "Тип Строки": "ТК",
                        "Доставить с": "10:00",
                        "Доставить по": "21:00",
                        "Дата план прибытия": "12.04.2022",
                        "Время план прибытия": "19:22",
                        "Дата план разгрузки": "12.04.2022",
                        "Время план разгрузки": "20:22",
                        "Адрес": "Варшавское ш., д.12",
                        "Контакт": "",
                        "Телефон": "8(495)555-33-22, 8(495)123-33-22",
                        "Точка Выгрузки": "ТК ПЭК",
                        "Предупреждение": "",
                        "Получатель Код": "476",
                        "Получатель": "ООО \"ЦЕНТР КРЕДИТ\"",
                        "Точка Выгрузки Название": "ТК ПЭК_DTD АВТО ДТД",
                        "Тип ТК": "ТК ДтД",
                        "Получатель Город": "Республика Бурятия, Улан-Удэ",
                        "Получатель Контакт": "Ткаченко Вера, т.79991112233",
                        "Скорость": "АВТО",
                        "Плательщик": "ООО \"ИТЕРН\", заказать доставку до двери клиента",
                        "Отправитель": "ООО \"Итерн\"",
                        "Сдача_Прием Подтверждено": 2
                    }
                ],
                "extData": [{
                        "Тип Строки": "ТК",
                        "Получатель Код": "413",
                        "Получатель": "ООО \"ЦЕНТР КРЕДИТ\"",
                        "Статус разгрузки": "WaitUnLoad",
                        "Заказ": "BR0091557",
                        "Кол-во мест": 5,
                        "Объем": 0.12,
                        "Вес": 78,
                        "Всего Кол-во мест": 5,
                        "Всего Объем": 0.12,
                        "Всего Вес": 78
                    }, {
                        "Тип Строки": "ТК",
                        "Получатель Код": "476",
                        "Получатель": "ООО \"ЦЕНТР КРЕДИТ\"",
                        "Статус разгрузки": orders['BR0091556'],
                        "Заказ": "BR0091556",
                        "Кол-во мест": 6,
                        "Объем": 0.05,
                        "Вес": 29,
                        "Всего Кол-во мест": 6,
                        "Всего Объем": 0.05,
                        "Всего Вес": 29
                    }
                ],
                "webpack_hash": "bf60aa68d32ed95eb08d"
            }
        }
    }
    return {
        "data": {
            "jobNo": "C329490",
            "jobStatus": "UnLoading",
            "imqStatus": null,
            "planningFinished": 1,
            "statusData": [{
                    "Номер Рейса": "C329490",
                    "Text1": "Ожидает разгрузки",
                    "Статус разгрузки": "WaitUnLoad",
                    "Пункт погрузки": 1,
                    "Номер по сдаче": 2,
                    "Последняя точка": 0,
                    "Тип": "ТК",
                    "Тип Строки": "ТК",
                    "Доставить с": "10:00",
                    "Доставить по": "21:00",
                    "Дата план прибытия": "12.04.2022",
                    "Время план прибытия": "19:22",
                    "Дата план разгрузки": "12.04.2022",
                    "Время план разгрузки": "20:22",
                    "Адрес": "Варшавское ш., д.12",
                    "Контакт": "",
                    "Телефон": "8(495)555-33-22, 8(495)123-33-22",
                    "Точка Выгрузки": "ТК ПЭК",
                    "Предупреждение": "Груз передать на основании экспедиторской расписки от ООО \"ПЭК\" ИНН 7721823853",
                    "Получатель Код": "413",
                    "Получатель": "ООО \"ЦЕНТР КРЕДИТ\"",
                    "Точка Выгрузки Название": "ТК ПЭК_DTD АВТО ДТД",
                    "Тип ТК": "ТК ДтД",
                    "Получатель Город": "Красноярский край, Красноярск",
                    "Получатель Контакт": "Фонарева Ольга Сергеевна, т.78883332211",
                    "Скорость": "АВТО",
                    "Плательщик": "ООО \"ИТЕРН\", заказать доставку до двери клиента",
                    "Отправитель": "ООО \"Итерн\"",
                    "Сдача_Прием Подтверждено": 0
                }, {
                    "Номер Рейса": "C329490",
                    "Text1": "Ожидает разгрузки",
                    "Статус разгрузки": "WaitUnLoad",
                    "Пункт погрузки": 1,
                    "Номер по сдаче": 2,
                    "Последняя точка": 0,
                    "Тип": "ТК",
                    "Тип Строки": "ТК",
                    "Доставить с": "10:00",
                    "Доставить по": "21:00",
                    "Дата план прибытия": "12.04.2022",
                    "Время план прибытия": "19:22",
                    "Дата план разгрузки": "12.04.2022",
                    "Время план разгрузки": "18:55",
                    "Адрес": "Варшавское ш., д.12",
                    "Контакт": "",
                    "Телефон": "8(495)555-33-22, 8(495)123-33-22",
                    "Точка Выгрузки": "ТК ПЭК",
                    "Предупреждение": "Груз передать на основании экспедиторской расписки от ООО \"ПЭК\" ИНН 7721823853",
                    "Получатель Код": "476",
                    "Получатель": "ООО \"ЦЕНТР КРЕДИТ\"",
                    "Точка Выгрузки Название": "ТК ПЭК_DTD АВТО ДТД",
                    "Тип ТК": "ТК ДтД",
                    "Получатель Город": "Республика Бурятия, Улан-Удэ",
                    "Получатель Контакт": "Ткаченко Вера, т.79991112233",
                    "Скорость": "АВТО",
                    "Плательщик": "ООО \"ИТЕРН\", заказать доставку до двери клиента",
                    "Отправитель": "ООО \"Итерн\"",
                    "Сдача_Прием Подтверждено": 0
                }
            ],
            "extData": [{
                    "Тип Строки": "ТК",
                    "Получатель Код": "413",
                    "Получатель": "ООО \"ЦЕНТР КРЕДИТ\"",
                    "Статус разгрузки": "WaitUnLoad",
                    "Заказ": "BR0091557",
                    "Кол-во мест": 5,
                    "Объем": 0.12,
                    "Вес": 78,
                    "Всего Кол-во мест": 5,
                    "Всего Объем": 0.12,
                    "Всего Вес": 78
                }, {
                    "Тип Строки": "ТК",
                    "Получатель Код": "476",
                    "Получатель": "ООО \"ЦЕНТР КРЕДИТ\"",
                    "Статус разгрузки": "WaitUnLoad",
                    "Заказ": "BR0091556",
                    "Кол-во мест": 6,
                    "Объем": 0.05,
                    "Вес": 29,
                    "Всего Кол-во мест": 6,
                    "Всего Объем": 0.05,
                    "Всего Вес": 29
                }
            ],
            "webpack_hash": "d16e6ef540a033709917"
        }
    }
}

function stage6_2_3_413Data() {
    return {
        "data": {
            "jobNo": "C329490",
            "jobStatus": "UnLoading",
            "imqStatus": null,
            "planningFinished": 1,
            "statusData": [{
                    "Номер Рейса": "C329490",
                    "Text1": "Сдача груза",
                    "Статус разгрузки": "BeginUnLoad",
                    "Пункт погрузки": 1,
                    "Номер по сдаче": 2,
                    "Последняя точка": 0,
                    "Тип": "ТК",
                    "Тип Строки": "ТК",
                    "Доставить с": "10:00",
                    "Доставить по": "21:00",
                    "Дата план прибытия": "12.04.2022",
                    "Время план прибытия": "19:22",
                    "Дата план разгрузки": "12.04.2022",
                    "Время план разгрузки": "18:55",
                    "Адрес": "Варшавское ш., д.12",
                    "Контакт": "",
                    "Телефон": "8(495)555-33-22, 8(495)123-33-22",
                    "Точка Выгрузки": "ТК ПЭК",
                    "Предупреждение": "При наличии расхождений в заказах заполните поля ниже. Оформите Акт расхождений в заказе.",
                    "Получатель Код": "413",
                    "Получатель": "ООО \"ЦЕНТР КРЕДИТ\"",
                    "Точка Выгрузки Название": "ТК ПЭК_DTD АВТО ДТД",
                    "Тип ТК": "ТК ДтД",
                    "Получатель Город": "Красноярский край, Красноярск",
                    "Получатель Контакт": "Фонарева Ольга Сергеевна, т.78883332211",
                    "Скорость": "АВТО",
                    "Плательщик": "ООО \"ИТЕРН\", заказать доставку до двери клиента",
                    "Отправитель": "ООО \"Итерн\"",
                    "Сдача_Прием Подтверждено": 0
                }
            ],
            "extData": [{
                    "Тип Строки": "ТК",
                    "Получатель Код": "413",
                    "Получатель": "ООО \"ЦЕНТР КРЕДИТ\"",
                    "Статус разгрузки": "BeginUnLoad",
                    "Заказ": "BR0091557",
                    "Кол-во мест": 5,
                    "Объем": 0.12,
                    "Вес": 78,
                    "Всего Кол-во мест": 5,
                    "Всего Объем": 0.12,
                    "Всего Вес": 78
                }
            ],
            "webpack_hash": "68f7835b9810b0e12560"
        }
    }
}

function stage6_2_3_476Data() {
    return {
        "data": {
            "jobNo": "C329490",
            "jobStatus": "UnLoading",
            "imqStatus": null,
            "planningFinished": 1,
            "statusData": [{
                    "Номер Рейса": "C329490",
                    "Text1": "Сдача груза",
                    "Статус разгрузки": "BeginUnLoad",
                    "Пункт погрузки": 1,
                    "Номер по сдаче": 2,
                    "Последняя точка": 0,
                    "Тип": "ТК",
                    "Тип Строки": "ТК",
                    "Доставить с": "10:00",
                    "Доставить по": "21:00",
                    "Дата план прибытия": "12.04.2022",
                    "Время план прибытия": "19:22",
                    "Дата план разгрузки": "12.04.2022",
                    "Время план разгрузки": "18:55",
                    "Адрес": "Варшавское ш., д.12",
                    "Контакт": "",
                    "Телефон": "8(495)555-33-22, 8(495)123-33-22",
                    "Точка Выгрузки": "ТК ПЭК",
                    "Предупреждение": "При наличии расхождений в заказах заполните поля ниже. Оформите Акт расхождений в заказе.",
                    "Получатель Код": "476",
                    "Получатель": "ООО \"ЦЕНТР КРЕДИТ\"",
                    "Точка Выгрузки Название": "ТК ПЭК_DTD АВТО ДТД",
                    "Тип ТК": "ТК ДтД",
                    "Получатель Город": "Республика Бурятия, Улан-Удэ",
                    "Получатель Контакт": "Ткаченко Вера, т.79991112233",
                    "Скорость": "АВТО",
                    "Плательщик": "ООО \"ИТЕРН\", заказать доставку до двери клиента",
                    "Отправитель": "ООО \"Итерн\"",
                    "Сдача_Прием Подтверждено": 0
                }
            ],
            "extData": [{
                    "Тип Строки": "ТК",
                    "Получатель Код": "476",
                    "Получатель": "ООО \"ЦЕНТР КРЕДИТ\"",
                    "Статус разгрузки": "BeginUnLoad",
                    "Заказ": "BR0091556",
                    "Кол-во мест": 6,
                    "Объем": 0.05,
                    "Вес": 29,
                    "Всего Кол-во мест": 6,
                    "Всего Объем": 0.05,
                    "Всего Вес": 29
                }
            ],
            "webpack_hash": "9d29bac142c19e36dbaf"
        }
    }
}

function stage6_2_4_413Data() {
    let orders = JSON.parse(window.sessionStorage.getItem('stub_orders'))
    return {
        "data": {
            "jobNo": "C329490",
            "jobStatus": "ConfirmReceive",
            "imqStatus": null,
            "planningFinished": 1,
            "statusData": [{
                    "Номер Рейса": "C329490",
                    "Text1": "Ожидаем подтверждения сдачи/приемки",
                    "Статус разгрузки": orders['BR0091557'],
                    "Пункт погрузки": 1,
                    "Номер по сдаче": 2,
                    "Последняя точка": 0,
                    "Тип": "ТК",
                    "Тип Строки": "ТК",
                    "Доставить с": "10:00",
                    "Доставить по": "21:00",
                    "Дата план прибытия": "12.04.2022",
                    "Время план прибытия": "19:22",
                    "Дата план разгрузки": "12.04.2022",
                    "Время план разгрузки": "18:55",
                    "Адрес": "Варшавское ш., д.12",
                    "Контакт": "",
                    "Телефон": "8(495)555-33-22, 8(495)123-33-22",
                    "Точка Выгрузки": "ТК ПЭК",
                    "Предупреждение": "",
                    "Получатель Код": "413",
                    "Получатель": "ООО \"ЦЕНТР КРЕДИТ\"",
                    "Точка Выгрузки Название": "ТК ПЭК_DTD АВТО ДТД",
                    "Тип ТК": "ТК ДтД",
                    "Получатель Город": "Красноярский край, Красноярск",
                    "Получатель Контакт": "Фонарева Ольга Сергеевна, т.78883332211",
                    "Скорость": "АВТО",
                    "Плательщик": "ООО \"ИТЕРН\", заказать доставку до двери клиента",
                    "Отправитель": "ООО \"Итерн\"",
                    "Сдача_Прием Подтверждено": 0
                }
            ],
            "extData": [{
                    "Тип Строки": "ТК",
                    "Получатель Код": "413",
                    "Получатель": "ООО \"ЦЕНТР КРЕДИТ\"",
                    "Статус разгрузки": orders['BR0091557'],
                    "Заказ": "BR0091557",
                    "Кол-во мест": 0,
                    "Объем": 0.12,
                    "Вес": 78,
                    "Всего Кол-во мест": 0,
                    "Всего Объем": 0.12,
                    "Всего Вес": 78
                }
            ],
            "webpack_hash": "d6fba71d272a01d78811"
        }
    }
    
}

function stage6_2_4_476Data() {
    let orders = JSON.parse(window.sessionStorage.getItem('stub_orders'))
    return {
        "data": {
            "jobNo": "C329490",
            "jobStatus": "ConfirmReceive",
            "imqStatus": null,
            "planningFinished": 1,
            "statusData": [{
                    "Номер Рейса": "C329490",
                    "Text1": "Ожидаем подтверждения сдачи/приемки",
                    "Статус разгрузки": orders['BR0091556'],
                    "Пункт погрузки": 1,
                    "Номер по сдаче": 2,
                    "Последняя точка": 0,
                    "Тип": "ТК",
                    "Тип Строки": "ТК",
                    "Доставить с": "10:00",
                    "Доставить по": "21:00",
                    "Дата план прибытия": "12.04.2022",
                    "Время план прибытия": "19:22",
                    "Дата план разгрузки": "12.04.2022",
                    "Время план разгрузки": "18:55",
                    "Адрес": "Варшавское ш., д.12",
                    "Контакт": "",
                    "Телефон": "8(495)555-33-22, 8(495)123-33-22",
                    "Точка Выгрузки": "ТК ПЭК",
                    "Предупреждение": "",
                    "Получатель Код": "476",
                    "Получатель": "ООО \"ЦЕНТР КРЕДИТ\"",
                    "Точка Выгрузки Название": "ТК ПЭК_DTD АВТО ДТД",
                    "Тип ТК": "ТК ДтД",
                    "Получатель Город": "Республика Бурятия, Улан-Удэ",
                    "Получатель Контакт": "Ткаченко Вера, т.79991112233",
                    "Скорость": "АВТО",
                    "Плательщик": "ООО \"ИТЕРН\", заказать доставку до двери клиента",
                    "Отправитель": "ООО \"Итерн\"",
                    "Сдача_Прием Подтверждено": 0
                }
            ],
            "extData": [{
                    "Тип Строки": "ТК",
                    "Получатель Код": "476",
                    "Получатель": "ООО \"ЦЕНТР КРЕДИТ\"",
                    "Статус разгрузки": orders['BR0091556'],
                    "Заказ": "BR0091556",
                    "Кол-во мест": 0,
                    "Объем": 0.05,
                    "Вес": 29,
                    "Всего Кол-во мест": 0,
                    "Всего Объем": 0.05,
                    "Всего Вес": 29
                }
            ],
            "webpack_hash": "ac7c6761a553676ad97a"
        }
    }
}

function stage6_2_5_413Data() {
    let orders = JSON.parse(window.sessionStorage.getItem('stub_orders'))
    return {
        "data": {
            "jobNo": "C329490",
            "jobStatus": "UnLoading",
            "imqStatus": null,
            "planningFinished": 1,
            "statusData": [{
                    "Номер Рейса": "C329490",
                    "Text1": "Груз сдан с расхождениями",
                    "Статус разгрузки": orders['BR0091557'],
                    "Пункт погрузки": 1,
                    "Номер по сдаче": 2,
                    "Последняя точка": 0,
                    "Тип": "ТК",
                    "Тип Строки": "ТК",
                    "Доставить с": "10:00",
                    "Доставить по": "21:00",
                    "Дата план прибытия": "12.04.2022",
                    "Время план прибытия": "19:22",
                    "Дата план разгрузки": "12.04.2022",
                    "Время план разгрузки": "18:55",
                    "Адрес": "Варшавское ш., д.12",
                    "Контакт": "",
                    "Телефон": "8(495)555-33-22, 8(495)123-33-22",
                    "Точка Выгрузки": "ТК ПЭК",
                    "Предупреждение": "",
                    "Получатель Код": "413",
                    "Получатель": "ООО \"ЦЕНТР КРЕДИТ\"",
                    "Точка Выгрузки Название": "ТК ПЭК_DTD АВТО ДТД",
                    "Тип ТК": "ТК ДтД",
                    "Получатель Город": "Красноярский край, Красноярск",
                    "Получатель Контакт": "Фонарева Ольга Сергеевна, т.78883332211",
                    "Скорость": "АВТО",
                    "Плательщик": "ООО \"ИТЕРН\", заказать доставку до двери клиента",
                    "Отправитель": "ООО \"Итерн\"",
                    "Сдача_Прием Подтверждено": 1
                }
            ],
            "extData": [{
                    "Тип Строки": "ТК",
                    "Получатель Код": "413",
                    "Получатель": "ООО \"ЦЕНТР КРЕДИТ\"",
                    "Статус разгрузки": orders['BR0091557'],
                    "Заказ": "BR0091557",
                    "Кол-во мест": 0,
                    "Объем": 0.12,
                    "Вес": 78,
                    "Всего Кол-во мест": 0,
                    "Всего Объем": 0.12,
                    "Всего Вес": 78
                }
            ],
            "webpack_hash": "d6fba71d272a01d78811"
        }
    }    
}

function stage6_2_5_476Data() {
    let orders = JSON.parse(window.sessionStorage.getItem('stub_orders'))
    return {
        "data": {
            "jobNo": "C329490",
            "jobStatus": "UnLoading",
            "imqStatus": null,
            "planningFinished": 1,
            "statusData": [{
                    "Номер Рейса": "C329490",
                    "Text1": "Груз сдан с расхождениями",
                    "Статус разгрузки": orders['BR0091556'],
                    "Пункт погрузки": 1,
                    "Номер по сдаче": 2,
                    "Последняя точка": 0,
                    "Тип": "ТК",
                    "Тип Строки": "ТК",
                    "Доставить с": "10:00",
                    "Доставить по": "21:00",
                    "Дата план прибытия": "12.04.2022",
                    "Время план прибытия": "19:22",
                    "Дата план разгрузки": "12.04.2022",
                    "Время план разгрузки": "18:55",
                    "Адрес": "Варшавское ш., д.12",
                    "Контакт": "",
                    "Телефон": "8(495)555-33-22, 8(495)123-33-22",
                    "Точка Выгрузки": "ТК ПЭК",
                    "Предупреждение": "",
                    "Получатель Код": "476",
                    "Получатель": "ООО \"ЦЕНТР КРЕДИТ\"",
                    "Точка Выгрузки Название": "ТК ПЭК_DTD АВТО ДТД",
                    "Тип ТК": "ТК ДтД",
                    "Получатель Город": "Республика Бурятия, Улан-Удэ",
                    "Получатель Контакт": "Ткаченко Вера, т.79991112233",
                    "Скорость": "АВТО",
                    "Плательщик": "ООО \"ИТЕРН\", заказать доставку до двери клиента",
                    "Отправитель": "ООО \"Итерн\"",
                    "Сдача_Прием Подтверждено": 1
                }
            ],
            "extData": [{
                    "Тип Строки": "ТК",
                    "Получатель Код": "476",
                    "Получатель": "ООО \"ЦЕНТР КРЕДИТ\"",
                    "Статус разгрузки": orders['BR0091556'],
                    "Заказ": "BR0091556",
                    "Кол-во мест": 0,
                    "Объем": 0.05,
                    "Вес": 29,
                    "Всего Кол-во мест": 0,
                    "Всего Объем": 0.05,
                    "Всего Вес": 29
                }
            ],
            "webpack_hash": "c11ea63391093e03b087"
        }
    }
}

function stage7_2Data() {
    let orders = JSON.parse(window.sessionStorage.getItem('stub_orders'))
    return {
        "data": {
            "jobNo": "C329490",
            "jobStatus": "Delivered",
            "imqStatus": null,
            "planningFinished": 1,
            "statusData": [{
                    "Номер Рейса": "C329490",
                    "Text1": orders['BR0091557'] === 'Delivered' ? "Груз сдан без расхождений" : "Груз сдан с расхождениями",
                    "Статус разгрузки": orders['BR0091557'],
                    "Пункт погрузки": 1,
                    "Номер по сдаче": 2,
                    "Последняя точка": 0,
                    "Тип": "ТК",
                    "Тип Строки": "ТК",
                    "Доставить с": "10:00",
                    "Доставить по": "21:00",
                    "Дата план прибытия": "12.04.2022",
                    "Время план прибытия": "19:22",
                    "Дата план разгрузки": "12.04.2022",
                    "Время план разгрузки": "20:21",
                    "Адрес": "Варшавское ш., д.12",
                    "Контакт": "",
                    "Телефон": "8(495)555-33-22, 8(495)123-33-22",
                    "Точка Выгрузки": "ТК ПЭК",
                    "Предупреждение": "",
                    "Получатель Код": "413",
                    "Получатель": "ООО \"ЦЕНТР КРЕДИТ\"",
                    "Точка Выгрузки Название": "ТК ПЭК_DTD АВТО ДТД",
                    "Тип ТК": "ТК ДтД",
                    "Получатель Город": "Красноярский край, Красноярск",
                    "Получатель Контакт": "Фонарева Ольга Сергеевна, т.78883332211",
                    "Скорость": "АВТО",
                    "Плательщик": "ООО \"ИТЕРН\", заказать доставку до двери клиента",
                    "Отправитель": "ООО \"Итерн\"",
                    "Сдача_Прием Подтверждено": 1
                }, {
                    "Номер Рейса": "C329490",
                    "Text1": orders['BR0091556'] === 'Delivered' ? "Груз сдан без расхождений" : "Груз сдан с расхождениями",
                    "Статус разгрузки": orders['BR0091556'],
                    "Пункт погрузки": 1,
                    "Номер по сдаче": 2,
                    "Последняя точка": 0,
                    "Тип": "ТК",
                    "Тип Строки": "ТК",
                    "Доставить с": "10:00",
                    "Доставить по": "21:00",
                    "Дата план прибытия": "12.04.2022",
                    "Время план прибытия": "19:22",
                    "Дата план разгрузки": "12.04.2022",
                    "Время план разгрузки": "20:21",
                    "Адрес": "Варшавское ш., д.12",
                    "Контакт": "",
                    "Телефон": "8(495)555-33-22, 8(495)123-33-22",
                    "Точка Выгрузки": "ТК ПЭК",
                    "Предупреждение": "",
                    "Получатель Код": "476",
                    "Получатель": "ООО \"ЦЕНТР КРЕДИТ\"",
                    "Точка Выгрузки Название": "ТК ПЭК_DTD АВТО ДТД",
                    "Тип ТК": "ТК ДтД",
                    "Получатель Город": "Республика Бурятия, Улан-Удэ",
                    "Получатель Контакт": "Ткаченко Вера, т.79991112233",
                    "Скорость": "АВТО",
                    "Плательщик": "ООО \"ИТЕРН\", заказать доставку до двери клиента",
                    "Отправитель": "ООО \"Итерн\"",
                    "Сдача_Прием Подтверждено": 1
                }
            ],
            "extData": [{
                    "Тип Строки": "ТК",
                    "Получатель Код": "413",
                    "Получатель": "ООО \"ЦЕНТР КРЕДИТ\"",
                    "Статус разгрузки": orders['BR0091557'],
                    "Заказ": "BR0091557",
                    "Кол-во мест": 5,
                    "Объем": 0.12,
                    "Вес": 78,
                    "Всего Кол-во мест": 5,
                    "Всего Объем": 0.12,
                    "Всего Вес": 78
                }, {
                    "Тип Строки": "ТК",
                    "Получатель Код": "476",
                    "Получатель": "ООО \"ЦЕНТР КРЕДИТ\"",
                    "Статус разгрузки": orders['BR0091556'],
                    "Заказ": "BR0091556",
                    "Кол-во мест": 6,
                    "Объем": 0.05,
                    "Вес": 29,
                    "Всего Кол-во мест": 6,
                    "Всего Объем": 0.05,
                    "Всего Вес": 29
                }
            ],
            "webpack_hash": "137e329c540b9d8b1966"
        }
    }
}

function stage5_3Data() {
    return {
        "data": {
            "jobNo": "C329490",
            "jobStatus": "OnTheWay",
            "imqStatus": null,
            "planningFinished": 1,
            "statusData": {
                "Номер Рейса": "C329490",
                "Text1": "Рейс в пути",
                "Пункт погрузки": 1,
                "Номер по сдаче": 3,
                "Последняя точка": 1,
                "Тип": "Возврат",
                "Доставить с": "10:00",
                "Доставить по": "21:00",
                "Дата план прибытия": "13.04.2022",
                "Время план прибытия": "20:16",
                "Дата план разгрузки": "13.04.2022",
                "Время план разгрузки": "20:46",
                "Адрес": "Зеленый пр-кт, д. 33",
                "Контакт": "Гончаров Максим Владимирович",
                "Телефон": "8(926)888-08-08, 8(925)555-05-05",
                "Точка Выгрузки": "Склад Ситилинк"
            },
            "webpack_hash": "fdeac5526ac5502d545d"
        }
    }
}

function stage6_3_1Data() {
    return {
        "data": {
            "jobNo": "C329490",
            "jobStatus": "UnLoading",
            "imqStatus": null,
            "planningFinished": 1,
            "statusData": [{
                    "Номер Рейса": "C329490",
                    "Text1": "Ожидает загрузки",
                    "Статус разгрузки": "WaitUnLoad",
                    "Пункт погрузки": 1,
                    "Номер по сдаче": 3,
                    "Последняя точка": 1,
                    "Тип": "Возврат",
                    "Тип Строки": "Возврат",
                    "Доставить с": "10:00",
                    "Доставить по": "21:00",
                    "Дата план прибытия": "13.04.2022",
                    "Время план прибытия": "20:16",
                    "Дата план разгрузки": "12.04.2022",
                    "Время план разгрузки": "20:46",
                    "Адрес": "Зеленый пр-кт, д. 33",
                    "Контакт": "Гончаров Максим Владимирович",
                    "Телефон": "8(926)888-08-08, 8(925)555-05-05",
                    "Точка Выгрузки": "Склад Ситилинк",
                    "Предупреждение": "Забираем только неповреждённые грузовые места. Мешки, а также грузовые места, имеющие замятия, разрывы, порезы, заклеенные многослойным скотчем, забираем по согласованию с диспетчером ОД",
                    "Получатель Код": "150518",
                    "Получатель": "ООО \"Ситилинк\"",
                    "Сдача_Прием Подтверждено": 0
                }
            ],
            "extData": [{
                    "Тип Строки": "Возврат",
                    "Получатель Код": "150518",
                    "Получатель": "ООО \"Ситилинк\"",
                    "Статус разгрузки": "WaitUnLoad",
                    "Заказ": "DPV22222",
                    "Кол-во мест": 7,
                    "Объем": 0.24,
                    "Вес": 53,
                    "Всего Кол-во мест": 7,
                    "Всего Объем": 3.4,
                    "Всего Вес": 596
                }, {
                    "Тип Строки": "Возврат",
                    "Получатель Код": "150518",
                    "Получатель": "ООО \"Ситилинк\"",
                    "Статус разгрузки": "WaitUnLoad",
                    "Заказ": "DPV22224",
                    "Кол-во мест": 3,
                    "Объем": 3.16,
                    "Вес": 543,
                    "Всего Кол-во мест": 3,
                    "Всего Объем": 3.4,
                    "Всего Вес": 596
                }
            ],
            "webpack_hash": "2a4cc16e5728a1ab5460"
        }
    }
}

function stage6_3_3Data() {
    return {
        "data": {
            "jobNo": "C329490",
            "jobStatus": "UnLoading",
            "imqStatus": null,
            "planningFinished": 1,
            "statusData": [{
                    "Номер Рейса": "C329490",
                    "Text1": "Получение груза",
                    "Статус разгрузки": "BeginUnLoad",
                    "Пункт погрузки": 1,
                    "Номер по сдаче": 3,
                    "Последняя точка": 1,
                    "Тип": "Возврат",
                    "Тип Строки": "Возврат",
                    "Доставить с": "10:00",
                    "Доставить по": "21:00",
                    "Дата план прибытия": "13.04.2022",
                    "Время план прибытия": "20:16",
                    "Дата план разгрузки": "12.04.2022",
                    "Время план разгрузки": "20:46",
                    "Адрес": "Зеленый пр-кт, д. 33",
                    "Контакт": "Гончаров Максим Владимирович",
                    "Телефон": "8(926)888-08-08, 8(925)555-05-05",
                    "Точка Выгрузки": "Склад Ситилинк",
                    "Предупреждение": "Забираем только неповреждённые грузовые места. Мешки, а также грузовые места, имеющие замятия, разрывы, порезы, заклеенные многослойным скотчем, забираем по согласованию с диспетчером ОД",
                    "Получатель Код": "150518",
                    "Получатель": "ООО \"Ситилинк\"",
                    "Сдача_Прием Подтверждено": 0
                }
            ],
            "extData": [{
                    "Тип Строки": "Возврат",
                    "Получатель Код": "150518",
                    "Получатель": "ООО \"Ситилинк\"",
                    "Статус разгрузки": "BeginUnLoad",
                    "Заказ": "DPV22222",
                    "Кол-во мест": 7,
                    "Объем": 0.24,
                    "Вес": 53,
                    "Всего Кол-во мест": 10,
                    "Всего Объем": 3.4,
                    "Всего Вес": 596
                }, {
                    "Тип Строки": "Возврат",
                    "Получатель Код": "150518",
                    "Получатель": "ООО \"Ситилинк\"",
                    "Статус разгрузки": "BeginUnLoad",
                    "Заказ": "DPV22224",
                    "Кол-во мест": 3,
                    "Объем": 3.16,
                    "Вес": 543,
                    "Всего Кол-во мест": 10,
                    "Всего Объем": 3.4,
                    "Всего Вес": 596
                }
            ],
            "webpack_hash": "4dfc5a7175e6e2d94166"
        }
    }
}

function stage6_3_4Data() {
    let orders = JSON.parse(window.sessionStorage.getItem('stub_orders'))
    return {
        "data": {
            "jobNo": "C329490",
            "jobStatus": "ConfirmReceive",
            "imqStatus": null,
            "planningFinished": 1,
            "statusData": [{
                    "Номер Рейса": "C329490",
                    "Text1": "Ожидаем подтверждения сдачи/приемки",
                    "Статус разгрузки": "ReceivedPartly",
                    "Пункт погрузки": 1,
                    "Номер по сдаче": 3,
                    "Последняя точка": 1,
                    "Тип": "Возврат",
                    "Тип Строки": "Возврат",
                    "Доставить с": "10:00",
                    "Доставить по": "21:00",
                    "Дата план прибытия": "13.04.2022",
                    "Время план прибытия": "20:16",
                    "Дата план разгрузки": "12.04.2022",
                    "Время план разгрузки": "20:46",
                    "Адрес": "Зеленый пр-кт, д. 33",
                    "Контакт": "Гончаров Максим Владимирович",
                    "Телефон": "8(926)888-08-08, 8(925)555-05-05",
                    "Точка Выгрузки": "Склад Ситилинк",
                    "Предупреждение": "Забираем только неповреждённые грузовые места. Мешки, а также грузовые места, имеющие замятия, разрывы, порезы, заклеенные многослойным скотчем, забираем по согласованию с диспетчером ОД",
                    "Получатель Код": "150518",
                    "Получатель": "ООО \"Ситилинк\"",
                    "Сдача_Прием Подтверждено": 0
                }
            ],
            "extData": [{
                    "Тип Строки": "Возврат",
                    "Получатель Код": "150518",
                    "Получатель": "ООО \"Ситилинк\"",
                    "Статус разгрузки": orders['DPV22222'],
                    "Заказ": "DPV22222",
                    "Кол-во мест": 0,
                    "Объем": 0.24,
                    "Вес": 53,
                    "Всего Кол-во мест": 0,
                    "Всего Объем": 3.4,
                    "Всего Вес": 596
                }, {
                    "Тип Строки": "Возврат",
                    "Получатель Код": "150518",
                    "Получатель": "ООО \"Ситилинк\"",
                    "Статус разгрузки": orders['DPV22224'],
                    "Заказ": "DPV22224",
                    "Кол-во мест": 0,
                    "Объем": 3.16,
                    "Вес": 543,
                    "Всего Кол-во мест": 0,
                    "Всего Объем": 3.4,
                    "Всего Вес": 596
                }
            ],
            "webpack_hash": "8e0b32ffb93e345e9fcf"
        }
    }
}

function stage6_3_5Data() {
    let orders = JSON.parse(window.sessionStorage.getItem('stub_orders'))
    return {
        "data": {
            "jobNo": "C329490",
            "jobStatus": "UnLoading",
            "imqStatus": null,
            "planningFinished": 1,
            "statusData": [{
                    "Номер Рейса": "C329490",
                    "Text1": "Груз принят частично",
                    "Статус разгрузки": "ReceivedPartly",
                    "Пункт погрузки": 1,
                    "Номер по сдаче": 3,
                    "Последняя точка": 1,
                    "Тип": "Возврат",
                    "Тип Строки": "Возврат",
                    "Доставить с": "10:00",
                    "Доставить по": "21:00",
                    "Дата план прибытия": "13.04.2022",
                    "Время план прибытия": "20:16",
                    "Дата план разгрузки": "12.04.2022",
                    "Время план разгрузки": "20:46",
                    "Адрес": "Зеленый пр-кт, д. 33",
                    "Контакт": "Гончаров Максим Владимирович",
                    "Телефон": "8(926)888-08-08, 8(925)555-05-05",
                    "Точка Выгрузки": "Склад Ситилинк",
                    "Предупреждение": "Забираем только неповреждённые грузовые места. Мешки, а также грузовые места, имеющие замятия, разрывы, порезы, заклеенные многослойным скотчем, забираем по согласованию с диспетчером ОД",
                    "Получатель Код": "150518",
                    "Получатель": "ООО \"Ситилинк\"",
                    "Сдача_Прием Подтверждено": 1
                }
            ],
            "extData": [{
                    "Тип Строки": "Возврат",
                    "Получатель Код": "150518",
                    "Получатель": "ООО \"Ситилинк\"",
                    "Статус разгрузки": orders['DPV22222'],
                    "Заказ": "DPV22222",
                    "Кол-во мест": 0,
                    "Объем": 0.24,
                    "Вес": 53,
                    "Всего Кол-во мест": 0,
                    "Всего Объем": 3.4,
                    "Всего Вес": 596
                }, {
                    "Тип Строки": "Возврат",
                    "Получатель Код": "150518",
                    "Получатель": "ООО \"Ситилинк\"",
                    "Статус разгрузки": orders['DPV22224'],
                    "Заказ": "DPV22224",
                    "Кол-во мест": 0,
                    "Объем": 3.16,
                    "Вес": 543,
                    "Всего Кол-во мест": 0,
                    "Всего Объем": 3.4,
                    "Всего Вес": 596
                }
            ],
            "webpack_hash": "d281d9ad79c29b4a0a4b"
        }
    }
}

function stage7_3Data() {
    let orders = JSON.parse(window.sessionStorage.getItem('stub_orders'))
    return {
        "data": {
            "jobNo": "C329490",
            "jobStatus": "Delivered",
            "imqStatus": null,
            "planningFinished": 1,
            "statusData": [{
                    "Номер Рейса": "C329490",
                    "Text1": (orders['DPV22222'] === 'Received' && orders['DPV22224'] === 'Received') ? "Груз принят" : ((orders['DPV22222'] === 'NotReceived' && orders['DPV22224'] === 'NotReceived') ? "Груз не принят" : "Груз принят частично"),
                    "Статус разгрузки": (orders['DPV22222'] === 'Received' && orders['DPV22224'] === 'Received') ? 'Received' : ((orders['DPV22222'] === 'NotReceived' && orders['DPV22224'] === 'NotReceived') ? 'NotReceived' : 'ReceivedPartly'),
                    "Пункт погрузки": 1,
                    "Номер по сдаче": 3,
                    "Последняя точка": 1,
                    "Тип": "Возврат",
                    "Тип Строки": "Возврат",
                    "Доставить с": "10:00",
                    "Доставить по": "21:00",
                    "Дата план прибытия": "13.04.2022",
                    "Время план прибытия": "20:16",
                    "Дата план разгрузки": "12.04.2022",
                    "Время план разгрузки": "20:46",
                    "Адрес": "Зеленый пр-кт, д. 33",
                    "Контакт": "Гончаров Максим Владимирович",
                    "Телефон": "8(926)888-08-08, 8(925)555-05-05",
                    "Точка Выгрузки": "Склад Ситилинк",
                    "Предупреждение": "Забираем только неповреждённые грузовые места. Мешки, а также грузовые места, имеющие замятия, разрывы, порезы, заклеенные многослойным скотчем, забираем по согласованию с диспетчером ОД",
                    "Получатель Код": "150518",
                    "Получатель": "ООО \"Ситилинк\"",
                    "Сдача_Прием Подтверждено": 1
                }
            ],
            "extData": [{
                    "Тип Строки": "Возврат",
                    "Получатель Код": "150518",
                    "Получатель": "ООО \"Ситилинк\"",
                    "Статус разгрузки": orders['DPV22222'],
                    "Заказ": "DPV22222",
                    "Кол-во мест": 7,
                    "Объем": 0.24,
                    "Вес": 53,
                    "Всего Кол-во мест": 10,
                    "Всего Объем": 3.4,
                    "Всего Вес": 596
                }, {
                    "Тип Строки": "Возврат",
                    "Получатель Код": "150518",
                    "Получатель": "ООО \"Ситилинк\"",
                    "Статус разгрузки": orders['DPV22224'],
                    "Заказ": "DPV22224",
                    "Кол-во мест": 3,
                    "Объем": 3.16,
                    "Вес": 543,
                    "Всего Кол-во мест": 10,
                    "Всего Объем": 3.4,
                    "Всего Вес": 596
                }
            ],
            "webpack_hash": "df30a64576e97343fad9"
        }
    }
}

function stage8_1Data() {
    let orders_total = JSON.parse(window.sessionStorage.getItem('stub_orders_total'))
    let extData = []
    if (orders_total['B68227'] !== 'Delivered') {
        extData.push({
            "Тип Строки": "Клиент",
            "Получатель Код": "128836",
            "Получатель": "ООО \"НЕТВОРК\"",
            "Статус разгрузки":  orders_total['B68227'],
            "Заказ": "B68227"
        })
    }
    if (orders_total['BR0091556'] !== 'Delivered') {
        extData.push({
            "Тип Строки": "ТК",
            "Получатель Код": "476",
            "Получатель": "ООО \"ЦЕНТР КРЕДИТ\"",
            "Статус разгрузки": orders_total['BR0091556'],
            "Заказ": "BR0091556"
        })
    }
    if (orders_total['BR0091557'] !== 'Delivered') {
        extData.push({
            "Тип Строки": "ТК",
            "Получатель Код": "413",
            "Получатель": "ООО \"ЦЕНТР КРЕДИТ\"",
            "Статус разгрузки": orders_total['BR0091557'],
            "Заказ": "BR0091557"
        })
    }
    if (orders_total['DPV22222'] !== 'NotReceived') {
        extData.push({
            "Тип Строки": "Возврат",
            "Получатель Код": "150518",
            "Получатель": "ООО \"Ситилинк\"",
            "Статус разгрузки": orders_total['DPV22222'],
            "Заказ": "DPV22222"
        })
    }
    if (orders_total['DPV22224'] !== 'NotReceived') {
        extData.push({
            "Тип Строки": "Возврат",
            "Получатель Код": "150518",
            "Получатель": "ООО \"Ситилинк\"",
            "Статус разгрузки": orders_total['DPV22224'],
            "Заказ": "DPV22224"
        })
    }
    let data = {
        "data": {
            "jobNo": "C329490",
            "jobStatus": "CargoReturn",
            "imqStatus": null,
            "planningFinished": 1,
            "statusData": {
                "Номер Рейса": "C329490",
                "Адрес Загрузки": "МО,Раменский р-н, п.Быково,ул.Аэропортовская,д14, корп.E",
                "Предупреждение": "Акт возврата груза сдать оператору"
            },
            "extData": extData,
            "webpack_hash": "19de75ec597eecb52be3"
        }
    }

    return data
}

function stage8_2Data() {
    let orders_total = JSON.parse(window.sessionStorage.getItem('stub_orders_total'))
    let extData = []
    if (orders_total['B68227'] !== 'Delivered') {
        extData.push({
            "Тип Строки": "Клиент",
            "Получатель Код": "128836",
            "Получатель": "ООО \"НЕТВОРК\"",
            "Статус разгрузки":  orders_total['B68227'],
            "Заказ": "B68227"
        })
    }
    if (orders_total['BR0091556'] !== 'Delivered') {
        extData.push({
            "Тип Строки": "ТК",
            "Получатель Код": "476",
            "Получатель": "ООО \"ЦЕНТР КРЕДИТ\"",
            "Статус разгрузки": orders_total['BR0091556'],
            "Заказ": "BR0091556"
        })
    }
    if (orders_total['BR0091557'] !== 'Delivered') {
        extData.push({
            "Тип Строки": "ТК",
            "Получатель Код": "413",
            "Получатель": "ООО \"ЦЕНТР КРЕДИТ\"",
            "Статус разгрузки": orders_total['BR0091557'],
            "Заказ": "BR0091557"
        })
    }
    if (orders_total['DPV22222'] !== 'NotReceived') {
        extData.push({
            "Тип Строки": "Возврат",
            "Получатель Код": "150518",
            "Получатель": "ООО \"Ситилинк\"",
            "Статус разгрузки": orders_total['DPV22222'],
            "Заказ": "DPV22222"
        })
    }
    if (orders_total['DPV22224'] !== 'NotReceived') {
        extData.push({
            "Тип Строки": "Возврат",
            "Получатель Код": "150518",
            "Получатель": "ООО \"Ситилинк\"",
            "Статус разгрузки": orders_total['DPV22224'],
            "Заказ": "DPV22224"
        })
    }
    let data = {
        "data": {
            "jobNo": "C329490",
            "jobStatus": "ConfirmReturn",
            "imqStatus": null,
            "planningFinished": 1,
            "statusData": {
                "Номер Рейса": "C329490",
                "Адрес Загрузки": "МО,Раменский р-н, п.Быково,ул.Аэропортовская,д14, корп.E",
                "Предупреждение": "Акт возврата груза сдать оператору"
            },
            "extData": extData,
            "webpack_hash": "19de75ec597eecb52be3"
        }
    }

    return data
}

function stage9Data() {
    let orders_total = JSON.parse(window.sessionStorage.getItem('stub_orders_total'))
    let extData = []
    extData.push({
        "Статус разгрузки":  orders_total['B68227'],
        "Заказ": "B68227"
    })
    extData.push({
        "Статус разгрузки": orders_total['BR0091556'],
        "Заказ": "BR0091556"
    })
    extData.push({
        "Статус разгрузки": orders_total['BR0091557'],
        "Заказ": "BR0091557"
    })
    extData.push({
        "Статус разгрузки": orders_total['DPV22222'],
        "Заказ": "DPV22222"
    })
    extData.push({
        "Статус разгрузки": orders_total['DPV22224'],
        "Заказ": "DPV22224"
    })
    let data = {
        "data": {
            "jobNo": "C329490",
            "jobStatus": "Completed",
            "imqStatus": null,
            "planningFinished": 1,
            "statusData": {
                "Номер Рейса": "C329490",
                "Дата Загрузки": "12.04.2022",
                "Время Загрузки": "13:51",
                "Дата Выполнения": "12.04.2022",
                "Время Выполнения": "19:28",
                "Предупреждение": "Не забудьте сдать документы по рейсу оператору"
            },
            "extData": extData,
            "webpack_hash": "19de75ec597eecb52be3"
        }
    }

    return data
}

function hasReturn() {
    let hasReturn = false
    let orders_total = JSON.parse(window.sessionStorage.getItem('stub_orders_total')) || {}
    Object.keys(orders_total).map(function (key) {
        if (orders_total[key] === 'DeliveredPartly' || orders_total[key] === 'DeliveredReturn' || orders_total[key] === 'ReceivedPartly' || orders_total[key] === 'Received' || orders_total[key] === 'DeliveredAct' || orders_total[key] === 'ReceivedAct') {
            hasReturn = true
        }
        return true
    });
    return hasReturn
}

export function getHints(stage) {
    if (stage === 'Stage1') {
        return <span>Подтвердите прибытие на погрузку или откажитесь от рейса, указав причину. <b>Внимание!</b> При нажатии <b>«Отклонить»</b> ссылка из смс становится недействительной</span>
    }
    if (stage === 'Stage2') {
        return <span>Нажмите <b>«Прибыл»</b> после въезда на территорию погрузки</span>
    }
    if (stage === 'Stage3_1') {
        return <span>Пройдите за погрузочными документами, после отметки диспетчера приступайте к погрузке</span>
    }
    if (stage === 'Stage3_2') {
        return <span>Отметьте окончание погрузки у диспетчера. Если на рейсе есть экспедитор, дальше данные вносит он на своем устройстве</span>
    }
    if (stage === 'Stage3_3') {
        return ''
    }
    if (stage === 'Stage4') {
        return <span>Нажмите <b>«Начать рейс»</b>, когда готовы к выезду на первую точку, адрес которой отображается на экране</span>
    }
    if (stage === 'Stage5_1') {
        return <span>Нажмите <b>«Прибыл на точку»</b> после прибытия на склад Клиента для погрузки / выгрузки</span>
    }
    if (stage === 'Stage6_1_1') {
        return <span>Разверните верхнюю часть формы для детализации. Нажмите <b>«Сдать груз»</b>, если уполномоченный представитель на месте, и приступайте к разгрузке. Либо измените представителя</span>
    }
    if (stage === 'EmployeeWindow') {
        return <span>Выберите другого представителя из списка уполномоченных  или добавьте нового</span>
    }
    if (stage === 'EmployeeAddWindow') {
        return <span>Введите ФИО нового представителя, нажмите <b>«Добавить»</b> и отправьте на подтверждение диспетчеру. <b>Внимание!</b> Поле может содержать только русские буквы</span>
    }
    if (stage === 'Stage6_1_2') {
        return <span>Дождитесь подтверждения диспетчера и нажмите кнопку <b>«Сдать груз»</b></span>
    }
    if (stage === 'Stage6_1_3') {
        return <span>Нажмите <b>«Груз сдан без расхождений»</b>, если все заказы сданы получателю в полном объеме. В противном случае отметьте расхождения</span>
    }
    if (stage === 'DivergenceWindow') {
        return <span>Отметьте номера заказов, сданных с расхождениями, указав причину. Для заказов, сданных частично, введите количество мест или единиц к возврату на склад</span>
    }
    if (stage === 'Stage6_1_4') {
        return <span>Дождитесь подтверждения диспетчером и нажмите <b>«Сдать груз»</b></span>
    }
    if (stage === 'Stage6_1_5') {
        return ''
    }
    if (stage === 'Stage7_1') {
        return <span>Разверните часть формы для детализации.<br/>После сдачи последнего груза нажмите <b>«Закрыть точку выгрузки»</b></span>
    }
    if (stage === 'Stage5_2') {
        return <span>Нажмите <b>«Прибыл на точку»</b> после прибытия в транспортную компанию для погрузки / выгрузки</span>
    }
    if (stage === 'Stage6_2_1') {
        return <span>Нажмите <b>«Сдать груз»</b> и приступайте к разгрузке. Сверьте информацию в экспедиторской расписке с данными на экране. Заполните данные по каждому получателю</span>
    }
    if (stage === 'Stage6_2_3_413') { 
        return <span>Нажмите <b>«Груз сдан без расхождений»</b>, если все заказы сданы получателю в полном объеме. В противном случае отметьте расхождения</span>
    }
    if (stage === 'Stage6_2_3_476') {
        return <span>Нажмите <b>«Груз сдан без расхождений»</b>, если все заказы сданы получателю в полном объеме. В противном случае отметьте расхождения</span>
    }
    if (stage === 'Stage6_2_4_413') {
        return <span>Дождитесь подтверждения диспетчером и нажмите <b>«Сдать груз»</b></span>
    }
    if (stage === 'Stage6_2_4_476') {
        return <span>Дождитесь подтверждения диспетчером и нажмите <b>«Сдать груз»</b></span>
    }
    if (stage === 'Stage6_2_5_413') {
        return ''
    }
    if (stage === 'Stage6_2_5_476') {
        return ''
    }
    if (stage === 'Stage7_2') {
        return <span>Разверните часть формы для детализации.<br/>После сдачи последнего груза нажмите <b>«Закрыть точку выгрузки»</b></span>
    }
    if (stage === 'Stage5_3') {
        return <span>Нажмите <b>«Прибыл на точку»</b> после прибытия на склад Клиента для погрузки / выгрузки</span>
    }
    if (stage === 'Stage6_3_1') {
        return <span>Разверните верхнюю часть формы для детализации. Нажмите <b>«Принять груз с возвратом»</b> для получения возврата по кредит-ноте</span>
    }
    if (stage === 'Stage6_3_3') {
        return <span>Нажмите <b>«Груз принят без расхождений»</b>, если все заказы приняты к возврату в полном объеме. В противном случае отметьте расхождения</span>
    }
    if (stage === 'DivergenceWindow3') {
        return <span>Отметьте номера заказов, принятых c расхождениями</span>
    }
    if (stage === 'Stage6_3_4') {
        return <span>Дождитесь подтверждения диспетчером и нажмите <b>«Принять груз»</b></span>
    }
    if (stage === 'Stage6_3_5') {
        return ''
    }
    if (stage === 'Stage7_3') {
        return <span>Разверните часть формы для детализации.<br/>После сдачи последнего груза нажмите <b>«Закрыть точку выгрузки»</b></span>
    }
    if (stage === 'Stage8_1') {
        return <span>Верните на склад все грузы и акты к ним согласно списка на экране. После разгрузки нажмите кнопку <b>«Груз возвращен на склад»</b></span>
    }
    if (stage === 'Stage8_2') {
        return <span>После подтверждения возврата оператором рейс закрывается. На экране отображается сводная статистика по заказам</span>
    }
    if (stage === 'Stage9') {
        if (hasReturn()) {
            return ''
        } else {
            return <span>После закрытия последней точки выгрузки рейс переходит в статус Выполнен. На экране отображается сводная статистика по заказам</span>
        }
    }
    
}

function getStageData(stage) {
    if (stage === 'DemoStart') { return demoStartData() }
    if (stage === 'DemoFinish') { return demoFinishData() }

    if (stage === 'Stage0') { return stage0Data() }
    if (stage === 'Stage1') { return stage1Data() }
    if (stage === 'Stage2') { return stage2Data() }
    if (stage === 'Stage3_1') { return stage3_1Data() }
    if (stage === 'Stage3_2') { return stage3_2Data() }
    if (stage === 'Stage3_3') { return stage3_3Data() }
    if (stage === 'Stage4') { return stage4Data() }

    if (stage === 'Stage5_1') { return stage5_1Data() }
    if (stage === 'Stage6_1_1') { return stage6_1_1Data() }
    if (stage === 'Stage6_1_2') { return stage6_1_2Data() }
    if (stage === 'Stage6_1_3') { return stage6_1_3Data() }
    if (stage === 'Stage6_1_4') { return stage6_1_4Data() }
    if (stage === 'Stage6_1_5') { return stage6_1_5Data() }
    if (stage === 'Stage7_1') { return stage7_1Data() }

    if (stage === 'Stage5_2') { return stage5_2Data() }
    if (stage === 'Stage6_2_1') { return stage6_2_1Data() }
    if (stage === 'Stage6_2_3_413') { return stage6_2_3_413Data() }
    if (stage === 'Stage6_2_3_476') { return stage6_2_3_476Data() }
    if (stage === 'Stage6_2_4_413') { return stage6_2_4_413Data() }
    if (stage === 'Stage6_2_4_476') { return stage6_2_4_476Data() }
    if (stage === 'Stage6_2_5_413') { return stage6_2_5_413Data() }
    if (stage === 'Stage6_2_5_476') { return stage6_2_5_476Data() }
    if (stage === 'Stage7_2') { return stage7_2Data() }

    if (stage === 'Stage5_3') { return stage5_3Data() }
    if (stage === 'Stage6_3_1') { return stage6_3_1Data() }
    if (stage === 'Stage6_3_3') { return stage6_3_3Data() }
    if (stage === 'Stage6_3_4') { return stage6_3_4Data() }
    if (stage === 'Stage6_3_5') { return stage6_3_5Data() }
    if (stage === 'Stage7_3') { return stage7_3Data() }

    if (stage === 'Stage8_1') { return stage8_1Data() }
    if (stage === 'Stage8_2') { return stage8_2Data() }
    if (stage === 'Stage9') { return stage9Data() }
}

export function fetchStatus(home, onHintDrawyerHide) {
    let stage = getStage() || 'DemoStart'
    let hint = getHints(stage)
    if (hint || onHintDrawyerHide) {
        HintMessages.hintDrawyerToggle(home, hint, hintTimeout, onHintDrawyerHide)
    }
    return getStageData(stage)
}

export function fetchReturnReasons() {
    return [
        {
            "Код": "АВТОМОБИЛЬ",
            "Описание": "Поломка а/м",
            "Возврат": "Полный"
        }, {
            "Код": "АДРЕС",
            "Описание": "Неверный адрес доставки",
            "Возврат": "Полный"
        }, {
            "Код": "КЛИЕНТ",
            "Описание": "Отказ клиента",
            "Возврат": "Полный"
        }, {
            "Код": "КО",
            "Описание": "Ошибка клиентского отдела",
            "Возврат": "Полный"
        }, {
            "Код": "ОПОЗДАЛ",
            "Описание": "Нарушение времени доставки",
            "Возврат": "Полный"
        }, {
            "Код": "ПРОСТОЙ",
            "Описание": "Простой у предыдущего клиента",
            "Возврат": "Полный"
        }, {
            "Код": "СКЛАД",
            "Описание": "Пересорт / недогруз",
            "Возврат": "Полный"
        }, {
            "Код": "ЦУП",
            "Описание": "Ошибка отдела планирования",
            "Возврат": "Полный"
        }, {
            "Код": "ЛИШНЕЕ",
            "Описание": "Отгрузка лишнего",
            "Возврат": "Частичный"
        }, {
            "Код": "НЕ ЗАКАЗВЛ",
            "Описание": "Ошибка клиента",
            "Возврат": "Частичный"
        }, {
            "Код": "П/У",
            "Описание": "Плохая упаковка",
            "Возврат": "Частичный"
        }, {
            "Код": "ПЕРЕСОРТ",
            "Описание": "Пересорт",
            "Возврат": "Частичный"
        }, {
            "Код": "ЭКПЕДИТОР",
            "Описание": "Ошибка экспедитора",
            "Возврат": "Частичный"
        }
    ]

}

export function callStubSource(method, data) {
    
    return new Promise((resolve) => {
        let currentStage = getStage()

        if (method === '/demo-start/accept') {
            if (data) {
                window.sessionStorage.setItem('stub_hide_hints', '1')
            } else {
                window.sessionStorage.removeItem('stub_hide_hints')
            }
            setStage('Stage1')
            return resolve(null)
        }
        if (method === '/demo-finish/accept') {
            setStage(null)
            return resolve(null)
        }
        if (method === '/stage0/accept') {
            setStage(null)
            return resolve(null)
        }
        if (method === '/stage1/accept') {
            setStage('Stage2')
            return resolve(null)
        }
        if (method === '/stage1/decline') {
            setStage('Stage0')
            return resolve(null)
        }
        if (method === '/stage2/accept') {
            setStage('Stage3_1')
            return resolve(null)
        }
        if (method === '/stage3/accept1') {
            setStage('Stage3_2')
            return resolve(null)
        }
        if (method === '/stage3/accept2') {
            setStage('Stage3_3')
            return resolve(null)
        }
        if (method === '/stage3/accept3') {
            setStage('Stage4')
            return resolve(null)
        }
        if (method === '/stage4/accept') {
            window.sessionStorage.removeItem('stub_orders_total')
            Object.keys(window.sessionStorage).map(function(key, index) {
                if (key.substring(0, 7) === 'C329490') {
                    window.sessionStorage.removeItem(key)
                }
                return true
            })
            setStage('Stage5_1')
            return resolve(null)
        }
        if (method === '/stage5/accept') {
            if (currentStage === 'Stage5_1') {
                setStage('Stage6_1_1')
            }
            if (currentStage === 'Stage5_2') {
                setStage('Stage6_2_1')
            }
            if (currentStage === 'Stage5_3') {
                setStage('Stage6_3_1')
            }
            return resolve(null)
        }
        if (method === '/stage6/select-employee') {
            setStage('Stage6_1_1')
            return resolve(null)
        }
        if (method === '/stage6/add-employee') {
            window.sessionStorage.setItem('stub_employee_name', data)
            setStage('Stage6_1_2')
            return resolve(null)
        }
        if (method === '/stage6_2/accept') {
            setStage('Stage6_1_1')
            return resolve(null)
        }
        if (method === '/stage6/confirm') {
            if (currentStage === 'Stage6_1_1') {
                setStage('Stage6_1_3')
            }
            if (currentStage === 'Stage6_2_1') {
                if (data === '413') {
                    setStage('Stage6_2_3_413')
                } else {
                    setStage('Stage6_2_3_476')
                }
            }
            if (currentStage === 'Stage6_3_1') {
                setStage('Stage6_3_3')
            }
            return resolve(null)
        }
        if (method === '/stage6/reject') {
            if (currentStage === 'Stage6_1_3') {
                setStage('Stage6_1_1')
            }
            if (currentStage === 'Stage6_2_3_413' || currentStage === 'Stage6_2_3_476') {
                setStage('Stage6_2_1')
            }
            if (currentStage === 'Stage6_3_3') {
                setStage('Stage6_3_1')
            }
            return resolve(null)
        }
        if (method === '/stage6/reject-receive') {
            if (currentStage === 'Stage6_1_4') {
                setStage('Stage6_1_3')
            }
            if (currentStage === 'Stage6_1_4_413') {
                setStage('Stage6_2_3_413')
            }
            if (currentStage === 'Stage6_3_4') {
                setStage('Stage6_3_3')
            }
            return resolve(null)
        }
        if (method === '/stage6/delivered-full') {
            if (currentStage === 'Stage6_1_3') {
                let orders = JSON.parse(window.sessionStorage.getItem('stub_orders')) || {}
                orders['B68227'] = 'Delivered'
                window.sessionStorage.setItem('stub_orders', JSON.stringify(orders))
                setStage('Stage7_1')
            }
            if (currentStage === 'Stage6_2_3_413') {
                let orders = JSON.parse(window.sessionStorage.getItem('stub_orders')) || {}
                orders['BR0091557'] = 'Delivered'
                window.sessionStorage.setItem('stub_orders', JSON.stringify(orders))
                let finished = window.sessionStorage.getItem('stub_finished')
                if (finished) {
                    setStage('Stage7_2')
                } else {
                    window.sessionStorage.setItem('stub_finished', '413')
                    setStage('Stage6_2_1')
                }
            }
            if (currentStage === 'Stage6_2_3_476') {
                let orders = JSON.parse(window.sessionStorage.getItem('stub_orders')) || {}
                orders['BR0091556'] = 'Delivered'
                window.sessionStorage.setItem('stub_orders', JSON.stringify(orders))
                let finished = window.sessionStorage.getItem('stub_finished')
                if (finished) {
                    setStage('Stage7_2')
                } else {
                    window.sessionStorage.setItem('stub_finished', '476')
                    setStage('Stage6_2_1')
                }
            }
            if (currentStage === 'Stage6_3_3') {
                let orders = JSON.parse(window.sessionStorage.getItem('stub_orders')) || {}
                orders['DPV22222'] = 'Received'
                orders['DPV22224'] = 'Received'
                window.sessionStorage.setItem('stub_orders', JSON.stringify(orders))
                setStage('Stage7_3')
            }
            return resolve(null)
        }
        if (method === '/stage6/delivered-part') {
            if (currentStage === 'Stage6_1_3' || currentStage === 'Stage6_2_3_413' || currentStage === 'Stage6_2_3_476') {
                let orders = JSON.parse(window.sessionStorage.getItem('stub_orders')) || {}
                data.full.forEach (element => orders[element] = 'Delivered')
                data.partly.forEach (element => orders[element] = 'DeliveredPartly')
                data.return.forEach (element => orders[element] = 'DeliveredReturn')
                data.act.forEach (element => orders[element] = 'DeliveredAct')
                window.sessionStorage.setItem('stub_orders', JSON.stringify(orders))
            }
            if (currentStage === 'Stage6_3_3') {
                let orders = JSON.parse(window.sessionStorage.getItem('stub_orders')) || {}
                data.full.forEach (element => orders[element] = 'Received')
                data.partly.forEach (element => orders[element] = 'ReceivedPartly')
                data.return.forEach (element => orders[element] = 'NotReceived')
                data.act.forEach (element => orders[element] = 'ReceivedAct')
                window.sessionStorage.setItem('stub_orders', JSON.stringify(orders))
            }
            if (currentStage === 'Stage6_1_3') {
                setStage('Stage6_1_4')
            }
            if (currentStage === 'Stage6_1_5') {
                setStage('Stage7_1')
            }
            if (currentStage === 'Stage6_2_3_413') {
                setStage('Stage6_2_4_413')
            }
            if (currentStage === 'Stage6_2_5_413') {
                let finished = window.sessionStorage.getItem('stub_finished')
                if (finished) {
                    setStage('Stage7_2')
                } else {
                    window.sessionStorage.setItem('stub_finished', '413')
                    setStage('Stage6_2_1')
                }
            }
            if (currentStage === 'Stage6_2_3_476') {
                setStage('Stage6_2_4_476')
            }
            if (currentStage === 'Stage6_2_5_476') {
                let finished = window.sessionStorage.getItem('stub_finished')
                if (finished) {
                    setStage('Stage7_2')
                } else {
                    window.sessionStorage.setItem('stub_finished', '476')
                    setStage('Stage6_2_1')
                }
            }
            if (currentStage === 'Stage6_3_3') {
                setStage('Stage6_3_4')
            }
            if (currentStage === 'Stage6_3_5') {
                setStage('Stage7_3')
            }
            return resolve(null)
        }
        if (method === '/stage6_4/accept') {
            if (currentStage === 'Stage6_1_4') {
                setStage('Stage6_1_5')
            }
            if (currentStage === 'Stage6_2_4_413') {
                setStage('Stage6_2_5_413')
            }
            if (currentStage === 'Stage6_2_4_476') {
                setStage('Stage6_2_5_476')
            }
            if (currentStage === 'Stage6_3_4') {
                setStage('Stage6_3_5')
            }
            return resolve(null)
        }
        if (method === '/stage7/accept') {
            let orders = JSON.parse(window.sessionStorage.getItem('stub_orders')) || {}
            let orders_total = JSON.parse(window.sessionStorage.getItem('stub_orders_total')) || {}
            Object.keys(orders).map(function(key, index) {
                orders_total[key] = orders[key]
                return true
            });
            window.sessionStorage.setItem('stub_orders_total', JSON.stringify(orders_total))

            window.sessionStorage.removeItem('stub_employee_name')
            window.sessionStorage.removeItem('stub_orders')
            window.sessionStorage.removeItem('stub_finished')
            if (currentStage === 'Stage7_1') {
                setStage('Stage5_2')
            }
            if (currentStage === 'Stage7_2') {
                setStage('Stage5_3')
            }
            if (currentStage === 'Stage7_3') {
                if (hasReturn()) {
                    setStage('Stage8_1')
                } else {
                    setStage('Stage9')
                }
            }
            return resolve(null)
        }
        if (method === '/stage8/accept') {
            setStage('Stage8_2')
            return resolve(null)
        }
        if (method === '/stage8_2/accept') {
            setStage('Stage9')
            return resolve(null)
        }
        if (method === '/stage9/accept') {
            setStage('DemoFinish')
            return resolve(null)
        }
    })
}