import React from "react";

import WhiteBox from '../design/WhiteBox'
import * as JobStub from '../../utils/JobStub'
import { formatPhonesString } from "../../utils/format-phone";

export default class JobInfoInline extends React.Component {
    
    getTip = () => {
        return JobStub.isStubJob() ? 'Недоступно для демоверсии' : null
    } 

    onHrefClick = (e) => {
        if (JobStub.isStubJob()) {
            e.preventDefault()
        }
    }

    renderContent = () => {
        let labelWidth = this.props.labelWidth || 3;
        let spaceAfter = this.props.spaceAfter ? ' mb-4' : ' mb-2';
        let fontBold = this.props.fontBold ? ' font-weight-bold' : '';
        let isNumber = this.props.format === 'number';

        return (
            <div className={'row'+spaceAfter}>
                <div className={'lgtrn-info-label-2 col-' + labelWidth + fontBold}>
                    {this.props.label}
                </div>

                <div className={'lgtrn-info-text-2 col-' + (12-labelWidth) + fontBold}>
                    {isNumber ? this.renderPhone() : this.props.value}
                </div>
            </div>
        );
    }

    renderPhone = () => {
        const phoneTelPairs = formatPhonesString(this.props.value);

        return phoneTelPairs.map(([phone, basePhone, baseTel, addPhone], index, arr) => {
            const addPhoneValid = /^\d+$/gi.test(addPhone);
            const tel = 'tel:' + baseTel + (addPhone && addPhoneValid ? (',' + addPhone) : '');
            const showPhone = addPhone && addPhoneValid ? phone : basePhone;

            return (
                <span key={index}>
                    <a href={tel} data-tip={this.getTip()} onClick={this.onHrefClick}>{showPhone}</a>
                    {addPhone && !addPhoneValid && <span> Доб. {addPhone}</span>}
                    {index < arr.length - 1 ? ',' : ''}
                    <br></br>
                </span>
            );
        })
    }

    renderWhiteBox = () => {
        return (
            <WhiteBox>
                {this.renderContent()}
            </WhiteBox>
        )
    }

    render() {
        return (
            <>
                {(this.props.noWhiteBox) ? this.renderContent() : this.renderWhiteBox()}
            </>
        )
    }
}
