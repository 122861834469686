import React from "react";

import * as ServerMessages from '../../utils/ServerMessages'

export default class MessageDrawyer extends React.Component {

    onAcceptClick = (e) => {
        e.preventDefault()
        ServerMessages.messageDrawerAccept(this.props.home)
    }

    toggle = (e) => {
        e.preventDefault()
        ServerMessages.messageDrawerToggle(this.props.home)
    }

    render() {
        let drawyerClasses = 'side-drawer container'
        if (this.props.home.state.messageDrawerText) {
            drawyerClasses = drawyerClasses + ' open'
        }
        let backdrop = ''
        if (this.props.home.state.messageDrawerText) {
            backdrop = <div className="backdrop" onClick={this.toggle} />
        }

        return (
            <div>
                {backdrop}
                <div className={drawyerClasses}><div className="row"><div className="border-radius-top bg-white col-12 offset-0 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
                    <div className="row mt-7 mb-4">
                        <div className="col text-center">
                            <img src="./img/loud.svg" alt="loud" />
                        </div>
                    </div>

                    <div className="row mb-6">
                        <div className="col text-center">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col text-center font-weight-bold font-size-14 lgtrn-color-danger">
                                        {this.props.home.state.messageDrawerText}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="mb-3">
                        <button className="btn lgtrn-btn-success btn-block text-uppercase lgtrn-btn lgtrn-btn-lsp-wide" onClick={this.onAcceptClick}>Понятно</button>
                    </div>
                </div></div></div>
            </div>
        )
    }
}