import React from "react";

export default class EmployeeDrawyer extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            name1: '',
            name2: '',
            name1Status: '',
            name2Status: '',
            name1StatusText: '',
            name2StatusText: ''
        }
    }

    onCloseClick = (e) => {
        e.preventDefault()
        this.props.toggle()
    }

    onAddClick = (e) => {
        e.preventDefault()
        this.props.toggle()
        this.props.add(
            this.state.name1,
            this.state.name2
        )
        this.clearValues()
    }

    validateName = (value, name) => {
        let nameStatus = name+'Status'
        let nameStatusText = name+'StatusText' 
        let stateObject = {}
        stateObject[nameStatus] = ''
        stateObject[nameStatusText] = ''
        if (value !== '') {
            let cyrillicValidationRegex = /^[\u0400-\u0484\u0487-\u052F\u1C80-\u1C88\u1D2B\u1D78\u2DE0-\u2DFF\uA640-\uA69F\uFE2E\uFE2F\s.-]*$/
            if (cyrillicValidationRegex.test(value)) {
                stateObject[nameStatus] = ' success'
            } else {
                stateObject[nameStatus] = ' danger'
                stateObject[nameStatusText] = 'Поле может содержать только русские буквы'
            }
        }
        this.setState(stateObject)
    }

    onName1Change = (event) => {
        this.setState({name1: event.target.value}, function () {
            this.validateName(this.state.name1, 'name1')
        })
    }

    onName2Change = (event) => {
        this.setState({name2: event.target.value}, function () {
            this.validateName(this.state.name2, 'name2')
        })
    }

    clearValues = () => {
        this.setState({
            name1: '',
            name2: '',
            name1Status: '',
            name2Status: '',
        });
    }

    addButtonDisabled = () => {
        return (this.state.name1Status !== ' success' || this.state.name2Status === ' danger')
    }

    render() {
        let drawyerClasses = 'side-drawer container'
        if (this.props.show) {
           drawyerClasses = drawyerClasses + ' open'
        }
        let backdrop = ''
        if (this.props.show) {
            backdrop = <div className="backdrop" onClick={this.props.toggle} />
        }

        return (
            <div>
                {backdrop}
                <div className={drawyerClasses}><div className="row"><div className="border-radius-top bg-white col-12 offset-0 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
                    <div className="row mb-3 mt-3">
                        <div className="col-10">
                            <h3 className="panel-header mb-0">Добавление представителя</h3>
                        </div>
                        <div className="col-2 text-right">
                            <a href="/#" className="close-panel" onClick={this.onCloseClick}>&times;</a>
                        </div>
                    </div>
                    <div className="form-group mb-0">
                        <input type="text" className={'form-control lgtrn-form-control' + this.state.name1Status} value={this.state.name1} onChange={this.onName1Change} placeholder="Фамилия, имя" />
                        <div className={'lgtrn-form-control-helper' + this.state.name1Status}>{this.state.name1StatusText}</div>
                    </div>
                    <div className="form-group mb-3">
                        <input type="text" className={'form-control lgtrn-form-control' + this.state.name2Status} value={this.state.name2} onChange={this.onName2Change} placeholder="Отчество (если есть)" />
                        <div className={'lgtrn-form-control-helper' + this.state.name2Status}>{this.state.name2StatusText}</div>
                    </div>
                    
                    <div className="row mb-3">
                        <div className="col">
                            <button className="btn lgtrn-btn-outline-danger btn-block text-uppercase lgtrn-btn" onClick={this.onCloseClick}>Отмена</button>
                        </div>
                        <div className="col">
                            <button className="btn lgtrn-btn-success btn-block text-uppercase lgtrn-btn" onClick={this.onAddClick} disabled={this.addButtonDisabled()}>Добавить</button>
                        </div>
                    </div>
                </div></div></div>
            </div>
        )
    }
}