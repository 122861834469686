import * as StatusFetching from './StatusFetching'
import * as ServerMessages from './ServerMessages'

export function timerStart(home) {
    let timer = setInterval(() => {
        StatusFetching.fetchStatus(home)
        ServerMessages.fetchMessage(home)
    }, (process.env.REACT_APP_STATUS_FETCHING_INTERVAL || 20000));
    home.setState({
        timer
    });
}

export function timerStop(home) {
    clearTimeout(home.state.timer)
    home.setState({
        timer: null
    })
}

export function timerToggle (home) {
    home.state.timer ? timerStop() : timerStart()
}