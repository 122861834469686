import React from "react";

export default class DeclineDrawyer extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            declineReason: 'Поломка а/м'
        }
    }

    declineReasons = [
        'Поломка а/м',
        'Поломка а/м по пути следования на погрузку',
        'Выгрузка у другого клиента',
        'Водитель не проинформирован о погрузке своей ТК',
        'Водитель запланирован на погрузку к другому клиенту',
        'Иное',
    ]

    onCloseClick = (e) => {
        e.preventDefault()
        this.props.toggle()
    }

    onRadioSelect = (e) => {
        this.setState({
            declineReason: e.currentTarget.value
        })
    }

    onDeclineClick = (e) => {
        e.preventDefault()
        this.props.toggle()
        this.props.decline(this.state.declineReason)
    }

    render() {
        let drawyerClasses = 'side-drawer container'
        if (this.props.show) {
           drawyerClasses = drawyerClasses + ' open'
        }
        let backdrop = ''
        if (this.props.show) {
            backdrop = <div className="backdrop" onClick={this.props.toggle} />
        }

        return <div>
            {backdrop}
            <div className={drawyerClasses}><div className="row"><div className="border-radius-top bg-white col-12 offset-0 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
                <div className="row mb-3 mt-3">
                    <div className="col-10">
                        <h3 className="panel-header mb-0">Укажите причину отклонения</h3>
                    </div>
                    <div className="col-2 text-right">
                        <a href="/#" className="close-panel" onClick={this.onCloseClick}>&times;</a>
                    </div>
                </div>
                {this.declineReasons.map((value, index) => (
                    <div className="form_radio" key={index}>
                        <input id={"radio-" + index} type="radio" name="radio" defaultValue={value} defaultChecked={ index === 0 } onChange={this.onRadioSelect} />
                        <label htmlFor={"radio-" + index}>{value}</label>
                    </div>
                ))}
                <div className="mb-3">
                    <button className="btn lgtrn-btn-danger btn-block text-uppercase lgtrn-btn lgtrn-btn-lsp-wide" onClick={this.onDeclineClick}>Отклонить</button>
                </div>
            </div></div></div>
        </div>
    }
}