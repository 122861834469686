import React from "react";

import WhiteBox from '../design/WhiteBox'
import WhiteFill from '../design/WhiteFill'
import CallPhone from '../elements/CallPhone'
import JobHeader from '../elements/JobHeader'
import * as CommonUtils from '../../utils/CommonUtils'

export default class UploadWindow extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            progress: 0,
            timer: null
        }
    }
    
    componentDidMount = () => {
        this.getProgress()
        
        let timer = setInterval(() => {
            this.getProgress()
        }, 1000)
        this.setState({
            timer
        });
    }

    getJobGUID() {
        return window.location.pathname.substr(1)
    }

    fullFileUrl = (url) => {
        let server_url = '/'
        if (window.location.host === 'localhost:3000') {
            server_url = 'http://localhost:8080/'
        }
        return server_url + url
    }

    getProgress = () => {

        CommonUtils.callSource('api/upload/progress', {
            job_guid: this.getJobGUID(),
            client_code: this.props.data['Получатель Код']
        })
        .then((result) => {
            if (result === 100) {
                clearTimeout(this.state.timer)
                this.setState({
                    progress: result, 
                    timer: null
                }, () => {
                    this.props.toggle()
                })
            } else {
                this.setState({progress: result})
            }
        })
        .catch((error) => {
            console.error(error)
        });
    }

    render() {
        let supportMail = this.props.supportMail
        let oDContart = this.props.oDContart
        return (
            <div>
                <div className="d-flex flex-column lgtrn-vh-100">
                    <div className="flex-grow-1 d-flex flex-column">
                        <JobHeader title={this.props.statusData['Номер Рейса']} supportMail={supportMail} />
                        <WhiteBox>
                            <div className="row mb-3 mt-3">
                                <div className="col">
                                    <h3 className="panel-header mb-0">Расхождения в заказе</h3>
                                </div>
                            </div>
                        </WhiteBox>

                        <WhiteBox>
                            <div className="font-weight-bold font-size-13 mb-3">Получатель {this.props.data['Получатель']}</div>
                        </WhiteBox>

                        <WhiteBox>
                            <div className="card lgtrn-card mb-3">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="font-size-11 mb-2 col-8">Загрузка файлов</div>
                                    </div>
                                    <div>
                                        <div className="d-flex uploaded-tape">
                                            {
                                                this.props.uploadedFiles.map((file, index) =>
                                                    <div className="uploaded-image" data-key={index} key={index} style={{"backgroundImage": 'url(' + this.fullFileUrl(file.url) + ')'}}>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </WhiteBox>

                        <WhiteFill />
                    </div>
                    <WhiteBox>
                        <div className="mb-3">
                            <div className="progress-title text-center">
                                {Math.round(this.state.progress)}%
                            </div>
                            <div className="progress progress-thin">
                                <div className="progress-bar bg-success" style={{'width': this.state.progress + '%'}}></div>
                            </div>
                        </div>
                        <div className="mt-3 mb-3 lgtrn-text-success text-center lgtrn-message">
                            Загрузка документов
                        </div>
                    </WhiteBox>
                    <CallPhone oDContart={oDContart} />
                </div>
            </div>
        )
    }
}