import React from "react";

import WhiteBox from '../design/WhiteBox'

export default class Collapsible extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = {
            collapsed: false,
        }
    }

    componentDidMount = () => {
        this.setState({
            collapsed: this.props.collapsed
        })
    }

    onTitleClick = (e) => {
        e.preventDefault()
        this.setState({
            collapsed: !this.state.collapsed
        })
    }

    render() {
        return (
            <WhiteBox>
                <div className={'collapsible-title' + (this.state.collapsed ? ' collapsed' : '')} onClick={this.onTitleClick}>
                    {this.props.title}
                    {this.props.subtitle &&
                        <div className="subtitle">{this.props.subtitle}</div>
                    }
                </div>
                <div className={'collapsible-body' + (this.state.collapsed ? ' d-none' : '')}>
                    {this.props.children}
                </div>
            </WhiteBox>
        )
    }
}
