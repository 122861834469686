const AndroidVersion = () => {
    if (/Android/.test(navigator.appVersion)) {
        return navigator.appVersion.match(/Android (\d+).(\d+)/)
    }
}

const iOSversion = () => {
    if (/iP(hone|od|ad)/.test(navigator.appVersion)) {
        const v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/)
        return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)]
    }
}

const WindowsPhoneVersion = () => {
    if (/Phone 8.0/.test(navigator.userAgent) === true) {
        return ' Phone 8.0'
    } else if (/Phone 10.0/.test(navigator.userAgent) === true) {
        return ' Phone 10.0'
    }
}

const getBrowserName = () => {
    let browser = ''

    // Opera 8.0+
    const isOpera = !!window.opr || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0

    // Firefox 1.0+
    const isFirefox = typeof InstallTrigger !== 'undefined'

    // Safari 3.0+ "[object HTMLElementConstructor]"
    const isSafari =
        /constructor/i.test(window.HTMLElement) ||
        (function (p) {
            return p.toString() === '[object SafariRemoteNotification]'
        })(!window['safari'] || window['safari'].pushNotification)

    // Internet Explorer 6-11
    const isIE = /* @cc_on!@ */ !!document.documentMode

    // Edge 20+
    const isEdge = !isIE && !!window.StyleMedia

    // Chrome 1+
    const isChrome = !!window.chrome && !!window.chrome.webstore

    if (isOpera === true) {
        browser = 'Opera'
    } else if (isFirefox === true) {
        browser = 'FireFox'
    } else if (isSafari === true) {
        browser = 'Safari'
    } else if (isIE === true) {
        browser = 'Internet Explorer'
    } else if (isEdge === true) {
        browser = 'Microsoft Edge'
    } else if (isChrome === true) {
        browser = 'Chrome'
    }
    return browser
}

const getDesktopOS = () => {
    let os = ''
    if (/Windows/.test(navigator.userAgent) === true) {
        os = 'Windows'
        if (/5.1;/.test(navigator.userAgent) === true) {
            os += ' XP'
        } else if (/6.0;/.test(navigator.userAgent) === true) {
            os += ' Vista'
        } else if (/6.1;/.test(navigator.userAgent) === true) {
            os += ' 7'
        } else if (/6.2/.test(navigator.userAgent) === true) {
            os += ' 8'
        } else if (/10.0;/.test(navigator.userAgent) === true) {
            os += ' 10'
        }

        if (/64/.test(navigator.userAgent) === true) {
            os += ' 64-bit'
        } else {
            os += ' 32-bit'
        }
    } else if (/Macintosh/.test(navigator.userAgent) === true) {
        os = 'Macintosh'
        if (/OS X/.test(navigator.userAgent) === true) {
            os += ' OS X'
        }
    }
    return os
}

const getMobileOS = () => {
    let os = ''
    if (/Windows/.test(navigator.userAgent) === true) {
        os = 'Windows' + WindowsPhoneVersion()
    } else if (/Android/.test(navigator.userAgent) === true) {
        const ver = AndroidVersion()
        os = ver[0]
    } else if (/iPhone;/.test(navigator.userAgent) === true || /iPad;/.test(navigator.userAgent) === true) {
        const ver = iOSversion()
        os = `iOS ${ver[0]}.${ver[1]}.${ver[2]}`
    } else if (/BBd*/.test(navigator.userAgent) === true) {
        os = 'BlackBerry'
    }
    return os
}

const getOS = (device) => {
    if (device === 'Desktop') {
        return getDesktopOS()
    } else if (device === 'Mobile') {
        return getMobileOS()
    }
    return '-'
}

export const getBrowser = () => {
    const referer = document.referer === undefined ? 'N/A' : document.referer
    const device = /Mobi/.test(navigator.userAgent) === true ? 'Mobile' : 'Desktop'
    const browser = getBrowserName()
    const os = getOS(device)
    
    return {
        browser: browser || 'Chrome',
        language: navigator.language,
        user_agent: navigator.userAgent,
        device,
        referer,
        os,
        online: navigator.onLine,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        screen_resolution: `${window.screen.width} x ${window.screen.height}`,
        cookie_enabled: navigator.cookieEnabled,
    }
}
