import React from "react";

import WhiteBox from '../design/WhiteBox'

export default class Attention extends React.Component {
    
    render() {
        let noBorder = ''
        let exclam = './img/exclamation.svg'
        let exclamAlign = ' align-items-center'
        if (this.props.noBorder) {
            noBorder = ' no-border'
            exclam = './img/exclamation-round.svg'
            exclamAlign = ' align-items-start'
        }
        return (
            <WhiteBox>
                <div className={'lgtrn-attention mb-3 d-flex' + noBorder}>
                    <div className={'d-flex  mr-3 ' + exclamAlign}>
                        <img src={exclam} alt="exclamation" />
                    </div>
                    <div>
                        {this.props.children}
                    </div>
                </div>
            </WhiteBox>
        )
    }
}
