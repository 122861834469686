import React from "react";

import messageIcon from '../../img/message.svg'
import WhiteBox from '../design/WhiteBox'
import WhiteFill from '../design/WhiteFill'
import CallPhone from '../elements/CallPhone'
import JobHeader from '../elements/JobHeader'

export default class HomePage extends React.Component {

    render() {
        let supportMail = this.props.supportMail
        let oDContart = this.props.oDContart
        return (
            <>
                {this.props.hover}
                <div className="d-flex flex-column lgtrn-vh-100">
                    <div className="flex-grow-1 d-flex flex-column">
                        <JobHeader title="Водительский портал" isNotStage={true} supportMail={supportMail} />
                        <WhiteBox>
                            <p className="mt-4 font-size-14"><strong>Приложение позволяет водителю или экспедитору:</strong></p>
                            <ul className="font-size-12">
                                <li>видеть маршрут, заказы и плановое время доставки грузов;</li>
                                <li>получать инструкции по рейсу;</li>
                                <li>отмечать статусы выполнения рейса;</li>
                                <li>связаться с диспетчером в случае сложностей и вопросов.</li>
                            </ul>

                            {(this.props.message || this.props.messageFormated) &&
                                <div className="card text-center lgtrn-card mb-3">
                                    <div className="card-body font-size-13 font-weight-bold lgtrn-text-danger">
                                        <img src={messageIcon} alt="Внимание" className="img-fluid" />
                                        {this.props.message &&
                                            <p className="mt-3 mb-0">
                                                {this.props.message}
                                            </p>
                                        }
                                        {this.props.messageFormated && 
                                            <>
                                                {this.props.messageFormated}
                                            </>
                                        }
                                    </div>
                                </div>
                            }
                            
                            {this.props.messageInfo &&
                                <div className="card text-center lgtrn-card mb-3">
                                    <div className="card-body font-size-13 font-weight-bold lgtrn-text-success">
                                        <img src="./img/stage_wait.svg" alt="location" className="img-fluid" />
                                        <p className="mt-3 mb-0">
                                            {this.props.messageInfo}
                                        </p>
                                    </div>
                                </div>
                            }

                        </WhiteBox>
                        <WhiteFill />
                    </div>
                    <CallPhone oDContart={oDContart} />
                </div>
            </>
        )
    }
}
