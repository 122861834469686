import React from "react";

import WhiteBox from '../design/WhiteBox'
import WhiteFill from '../design/WhiteFill'
import EmployeeList from '../elements/EmployeeList'
import JobHeader from '../elements/JobHeader'
import CallPhone from '../elements/CallPhone'
import EmployeeDrawyer from '../drawyers/EmployeeDrawyer'
import * as JobStub from '../../utils/JobStub'
import * as CommonUtils from '../../utils/CommonUtils'
import * as StatusFetching from '../../utils/StatusFetching'
import * as HintMessages from '../../utils/HintMessages'
import * as JobStubActions from '../../utils/JobStubActions'

export default class EmployeeWindow extends React.Component {
    constructor(props) {
        super(props)
        let checkedIndex = 0
        this.props.data.ext.forEach((element, index) => {
            if (element['В рейсе'] === 1) {
                checkedIndex = index
            }
        });
        this.state = {
            employeeDrawerOpen: false,
            employeeName: this.props.data.ext[checkedIndex]['Представитель'],
            employeePassport: this.props.data.ext[checkedIndex]['Паспорт'],
            employeeAdded: '',
            checkedIndex: checkedIndex,
            checkedAdded: false,
        }
    }

    selectEmployeeStage = (point, order, code, name, passport, added) => {
        if (JobStub.isStubJob()) {
            let entry = (added ? '/stage6/add-employee' : '/stage6/select-employee')
            JobStub.callStubSource(entry, (added) ? name : '')
            .then(() => {
                StatusFetching.fetchStubStatus(this.props.home, added ? JobStubActions.acceptStage6_2 : null)
            })
            return
        }
        let entry = (added ? 'api/stage/stage6/add-employee' : 'api/stage/stage6/select-employee')
        let data = {
            job_guid: this.props.jobGUID,
            job_no: this.props.jobNo,
            point: point,
            order: order,
            code: code,
            name: name,
        }
        if (!added) {
            data.passport = passport
        }
        CommonUtils.callSource(entry, data, this.props.home)
        .then(() => {
            StatusFetching.fetchStatus(this.props.home)
        })
    }

    selectEmployee = (name, passport, index, added) => {
        added = added || false 
        this.setState({
            employeeName: name,
            employeePassport: passport,
            checkedIndex: index,
            checkedAdded: added,
        })
    }

    addEmployee = (name1, name2) => {
        let name = name1 + (name2 ? ' ' + name2 : '')
        this.setState({
            employeeAdded: name,
            employeeName: name,
            employeePassport: '',
            checkedIndex: this.props.data.ext.length,
            checkedAdded: true,
        })
    }

    employeeDrawerToggle = () => {
        this.setState({
            employeeDrawerOpen: !this.state.employeeDrawerOpen
        }, () => {
            if (this.state.employeeDrawerOpen && JobStub.isStubJob()) {
                HintMessages.hintDrawyerToggle(this.props.home, JobStub.getHints('EmployeeAddWindow'), JobStub.hintTimeout)
            }
        })
    }

    onCloseClick = (e) => {
        e.preventDefault()
        this.props.toggle()
    }

    onCheckClick = (e) => {
        e.preventDefault()
        this.selectEmployeeStage(
            this.props.data['Пункт погрузки'], 
            this.props.data['Номер по сдаче'], 
            this.props.data['Получатель Код'], 
            this.state.employeeName,
            this.state.employeePassport,
            this.state.checkedAdded
        )
        this.props.toggle()
    }

    render() {
        let supportMail = this.props.supportMail
        let oDContart = this.props.oDContart
        return (
            <div>
                <div className="d-flex flex-column lgtrn-vh-100">
                    <div className="flex-grow-1 d-flex flex-column">
                        <JobHeader title={this.props.statusData['Номер Рейса']} supportMail={supportMail} />
                        <WhiteBox>
                            <div className="row mt-3">
                                <div className="col">
                                    <div className="back-panel lgtrn-text-danger" onClick={this.onCloseClick}>
                                        <span>Назад</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col">
                                    <h3 className="panel-header mb-0">Новый представитель</h3>
                                </div>
                            </div>
                        </WhiteBox>

                        <WhiteBox>
                            <div className="font-weight-bold font-size-13 mb-3">Получатель {this.props.data['Получатель']}</div>
                        </WhiteBox>

                        <WhiteBox>
                            <EmployeeList
                                data={this.props.data.ext}
                                checkedIndex={this.state.checkedIndex}
                                employee={this.state.employeeAdded}
                                select={this.selectEmployee}
                            />
                            <div className="mb-20">
                                <button className="btn btn-link lgtrn-btn" onClick={this.employeeDrawerToggle} disabled={this.props.imqStatus !== null} >
                                    <img src="./img/plus.svg" alt="add" />
                                    <span>Добавить представителя</span>
                                </button>
                            </div>
                        </WhiteBox>
                        
                        <WhiteFill />
                    </div>
                </div>
                <EmployeeDrawyer
                    show={this.state.employeeDrawerOpen} 
                    toggle={this.employeeDrawerToggle} 
                    add={this.addEmployee}
                />
                <div className={'fixed-bottom' + (this.state.employeeDrawerOpen ? ' d-none' : '')}>
                    <WhiteBox>
                        <div className="mb-3">
                            <button className="btn lgtrn-btn-success btn-block text-uppercase lgtrn-btn" onClick={this.onCheckClick} disabled={this.props.imqStatus !== null} >
                                <span>Подтвердить</span>
                            </button>
                        </div>
                    </WhiteBox>
                    <CallPhone oDContart={oDContart} />
                </div>
            </div>
        )
    }
}