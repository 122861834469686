import React from "react";

import WhiteBox from '../design/WhiteBox'

export default class JobAddress extends React.Component {
    
    renderContent = () => {
        return (
            <div className="row mb-4"><div className="col">
                <div className="card text-center lgtrn-card">
                    <div className="card-body">
                        <div className="lgtrn-card-header pb-2">
                            <img src="./img/geo_map.svg" alt="location" />
                            <span>{this.props.title}</span>
                        </div>
                        <div className="lgtrn-card-info">
                            {this.props.address}
                        </div>
                    </div>
                </div>
            </div></div>
        )
    }

    renderWhiteBox = () => {
        return (
            <WhiteBox>
                {this.renderContent()}
            </WhiteBox>
        )
    }

    render() {
        return (
            <>
                {(this.props.noWhiteBox) ? this.renderContent() : this.renderWhiteBox()}
            </>
        )
    }
}
