import React from "react";

export default class PreviewWindow extends React.Component {

    onCloseClick = (e) => {
        e.preventDefault()
        this.props.toggle()
    }

    onDeleteClick = (e) => {
        e.preventDefault()
        this.props.toggle()
        this.props.delete(this.props.index)
    }

    trimFilename = (name) => {
        let parts = name.split('.')
        let ext = parts.pop()
        let new_name = parts.join('.')
        if (new_name.length > 30) {
            new_name = new_name.substring(0, 30)
        }
        return `${new_name}....${ext}`
    }

    render() {
        return (
            <div className="container pl-0 pr-0"><div className="row no-gutters"><div className="col-12 offset-0 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
                <div className="d-flex flex-column lgtrn-vh-100 fill-image" style={{'backgroundImage': 'url("'+this.props.src+'")'}}>
                    <div className="flex-grow-1 d-flex flex-column">
                        <div className="container bg-black-transparent">
                            <div className="row">
                                <div className="col-10">
                                    <h3 className="panel-header mb-0">{this.trimFilename(this.props.title)}</h3>
                                </div>
                                <div className="col-2 text-right">
                                    <a href="/#" className="close-panel" onClick={this.onCloseClick}>&times;</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container"><div className="row mb-3"><div className="col-12">
                        <button className="btn lgtrn-btn-danger btn-block lgtrn-btn" onClick={this.onDeleteClick} disabled={this.props.imqStatus !== null}>
                            <img alt="trash" src="./img/trash.svg" />
                            <span className="ml-2">Удалить</span>
                        </button>
                    </div></div></div>
                </div>
            
            {/*
            <div>
                <div className="d-flex flex-column lgtrn-vh-100 fill-image" style={{'backgroundImage': 'url("'+this.props.src+'")'}}>
                    <div className="flex-grow-1 d-flex flex-column">

                        <div className="container"><div className="row"><div className="col-12 offset-0 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4 bg-black-transparent">
                            <div className="row">
                                <div className="col-10">
                                    <h3 className="panel-header mb-0">{this.props.title}</h3>
                                </div>
                                <div className="col-2 text-right">
                                    <a href="/#" className="close-panel" onClick={this.onCloseClick}>&times;</a>
                                </div>
                            </div>
                        </div></div></div>
                        
                        <div className="container d-flex flex-collumn flex-grow-1">
                            <div className="row d-flex flex-collumn flex-grow-1">
                                <div className="col-12 offset-0 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="container"><div className="row"><div className="col-12 offset-0 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
                        <div className="mb-3">
                            <button className="btn btn-danger btn-block text-uppercase lgtrn-btn" onClick={this.onDeleteClick} disabled={this.props.imqStatus !== null}>Удалить</button>
                        </div>
                    </div></div></div>
                </div>
            </div>
            */}
            </div></div></div>
        )
    }
}