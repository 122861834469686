import React from "react";

export default class EmployeeList extends React.Component {

    renderEmployee = (employee, index, withRadio) => {
        if (!withRadio) {
            return (
                <>
                    {employee['Представитель']}
                </>
            )
        }
        return ( 
            <div className="form_radio mb-0 left">
                <input 
                    id={'radio-'+index} type="radio" name="radio" defaultValue={index} checked={index === this.props.checkedIndex} 
                    data-name={employee['Представитель']} 
                    data-passport={employee['Паспорт']} 
                    onChange={() => {this.props.select(employee['Представитель'], employee['Паспорт'], index, employee['Добавлен'])}}
                />
                <label htmlFor={'radio-'+index}>
                    {employee['Представитель']}
                </label>
            </div>
        )
    }

    render() {
        let single = true
        let employeeData = []
        if (this.props.data) {
            employeeData = this.props.data.slice()
            single = false
        }
        if (this.props.employee) {
            employeeData.push({
                'Представитель': this.props.employee,
                'Паспорт': this.props.passport,
                'Добавлен': true
            })
        }
        return (
            <>
                <p className="lgtrn-info-text">Лица, имеющие право на получение груза</p>
                <table className="table lgtrn-table">
                    <thead>
                        <tr>
                            <th scope="col" className="pl-0">ФИО</th>
                            <th scope="col pr-0" className="text-right">Паспорт</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            employeeData.map((employee, index) =>
                                <tr key={index} className={(this.props.disabled ? 'disabled' : '')}>
                                    <td className="font-weight-bold pl-0">
                                        {this.renderEmployee(employee, index, !single)}
                                    </td>
                                    <td className="text-right pr-0">{employee['Паспорт']}</td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </>
        )
    }
}
