import React from "react";

export default class ReturnReasonsDrawyer extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            places: '',
            items: '',
            placesStatus: '',
            itemsStatus: '',
            reasonCode: '',
        }
    }

    onCloseClick = (e) => {
        e.preventDefault()
        this.clearValues()
        this.props.toggle()
    }

    onAddClick = (e) => {
        e.preventDefault()
        this.props.toggle()
        this.props.add(
            this.props.orderNumber,
            this.props.orderType,
            this.state.places,
            this.state.items,
            this.state.reasonCode
        )
        this.clearValues()
    }

    validateNumber = (value, name) => {
        let nameStatus = name+'Status'
        let stateObject = {}
        stateObject[nameStatus] = ''
        if (value !== '') {
            let validationRegex = /^[0-9]*$/
            if (validationRegex.test(value)) {
                stateObject[nameStatus] = ''
            } else {
                stateObject[nameStatus] = ' danger'
            }
        }
        this.setState(stateObject)
    }

    onPlacesChange = (event) => {
        this.setState({places: event.target.value}, function () {
            this.validateNumber(this.state.places, 'places')
        })
    }

    onItemsChange = (event) => {
        this.setState({items: event.target.value}, function () {
            this.validateNumber(this.state.items, 'items')
        })
    }

    clearValues = () => {
        this.setState({
            places: '',
            items: '',
            placesStatus: '',
            itemsStatus: '',
            reasonCode: '',
        });
    }

    addButtonDisabled = () => {
        if (this.props.orderType === 'full') {
            return this.state.reasonCode === ''
        } else if (this.props.orderType === 'part_return') {
            return (this.state.placesStatus !== '' || (this.state.places === '' || 1 * this.state.places === 0))
        } else {
            return (
                this.state.placesStatus !== '' || 
                this.state.itemsStatus !== '' || 
                this.state.reasonCode === '' || 
                ((this.state.places === '' || 1 * this.state.places === 0) && (this.state.items === '' || 1 * this.state.items === 0))
            )
        }
    }

    onRadioSelect = (code) => {
        this.setState({
            reasonCode: code
        })
    }

    renderList = () => {
        if (!this.props.returnReasons[this.props.orderType]) {
            return ''
        }
        return <>
        {
            this.props.returnReasons[this.props.orderType].map((reason, index) => {
                return (
                <div className="form_radio left" key={index}>
                    <input id={'radio-' + index} type="radio" name="radio" defaultValue={reason['Код']} onChange={() => this.onRadioSelect(reason['Код'])} />
                    <label htmlFor={'radio-' + index}>{reason['Описание']}</label>
                </div>
                )
            })
        }
        </>
    }

    render() {
        let drawyerClasses = 'side-drawer container'
        if (this.props.show) {
           drawyerClasses = drawyerClasses + ' open'
        }
        let backdrop = ''
        if (this.props.show) {
            backdrop = <div className="backdrop" onClick={this.props.toggle} />
        }

        let orderTypeStr = 'Частичный'
        if (this.props.orderType === 'full') {
            orderTypeStr = 'Полный'
        }

        return (
            <div>
                {backdrop}
                <div className={drawyerClasses}><div className="row"><div className="border-radius-top bg-white col-12 offset-0 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
                    <div className="row mb-3 mt-3">
                        <div className="col-10">
                            <h3 className="panel-header mb-0">{orderTypeStr} возврат №{this.props.orderNumber}</h3>
                        </div>
                        <div className="col-2 text-right">
                            <a href="/#" className="close-panel" onClick={this.onCloseClick}>&times;</a>
                        </div>
                    </div>
                    {(this.props.orderType === 'part' || this.props.orderType === 'part_return') &&
                        <div className="row">
                            <div className="col">
                                <div className="form-group amount-input">
                                    <label htmlFor="places">Кол-во мест</label>
                                    <input type="text" className={'form-control' +  this.state.placesStatus} id="places" placeholder="0" value={this.state.places} onChange={this.onPlacesChange} />
                                </div>
                            </div>
                            {this.props.orderType === 'part' &&
                                <div className="col">
                                    <div className="form-group amount-input">
                                        <label htmlFor="items">Кол-во единиц</label>
                                        <input type="text" className={'form-control' +  this.state.itemsStatus} id="items" placeholder="0" value={this.state.items} onChange={this.onItemsChange} />
                                    </div>
                                </div>
                            }
                        </div>
                    }
                    {(this.props.orderType === 'part' || this.props.orderType === 'full') &&
                        <div className="row">
                            <div className="col">
                                <label className="font-weight-bold font-size-13">Причина возврата</label>
                                {this.renderList()}
                            </div>
                        </div>
                    }
                    <div className="row mb-3">
                        <div className="col">
                            <button className="btn lgtrn-btn-outline-danger btn-block text-uppercase lgtrn-btn" onClick={this.onCloseClick}>Отмена</button>
                        </div>
                        <div className="col">
                            <button className="btn lgtrn-btn-success btn-block text-uppercase lgtrn-btn" onClick={this.onAddClick} disabled={this.addButtonDisabled()}>Добавить</button>
                        </div>
                    </div>
                </div></div></div>
            </div>
        )
    }
}